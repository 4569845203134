/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export class AppConfiguration {
    static async configure(configurationProvider) {
        const { awsConfig, resources } = await configurationProvider.getConfiguration();
        AppConfiguration.awsConfig = awsConfig;
        AppConfiguration.resources = resources;
    }
    static getAwsConfiguration() {
        if (!AppConfiguration.awsConfig) {
            throw new Error("AWS not configured. Have you called AwsConfiguration.configure from SADE data access module?");
        }
        return AppConfiguration.awsConfig;
    }
    static getResources() {
        if (!AppConfiguration.resources) {
            throw new Error("Resources not configured. Have you called AwsConfiguration.configure from SADE data access module?");
        }
        return AppConfiguration.resources;
    }
}
