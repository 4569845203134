/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthWrapper, OtaManager, EventsRepository } from "@sade/data-access";
import EventView from "../../components/event-view";
import AnalyticsView from "../analytics-view";
import AdminView from "../../components/admin-view";
import HistoryView from "../../components/history-view";
import StatusView from "../../components/status-view";
import UserView from "../../components/user-view";
import Paths from "../Paths";
import MeasurementView from "../measurement-view";

interface Props {}

export default class ContentController extends Component<Props> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    if (await AuthWrapper.isCurrentUserAuthenticated()) {
      await EventsRepository.instance.init();
      await OtaManager.getInstance().init();
    }
  }

  public componentWillUnmount(): void {
    EventsRepository.instance.uninit();
    OtaManager.getInstance().uninit();
  }

  public render(): JSX.Element {
    return (
      <section className="body-container col-sm-12 col-xsm-12">
        <Switch>
          <Route exact={true} path={Paths.HISTORY + "/:id?"}>
            <HistoryView />
          </Route>
          <Route exact={true} path={Paths.STATUS + "/:id?"}>
            <StatusView />
          </Route>
          <Route exact={true} path={Paths.ANALYTICS + "/:id?"}>
            <AnalyticsView />
          </Route>
          <Route exact={true} path={Paths.EVENTS + "/:id?"}>
            <EventView />
          </Route>
          <Route exact={true} path={Paths.MEASUREMENTS + "/:id?"}>
            <MeasurementView />
          </Route>
          <Route exact={true} path={Paths.USER}>
            <UserView />
          </Route>
          <Route exact={true} path={Paths.ADMIN}>
            <AdminView />
          </Route>
          <Route exact={false} path={Paths.ROOT}>
            <Redirect to={Paths.STATUS} />
          </Route>
        </Switch>
      </section>
    );
  }
}
