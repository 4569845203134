/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Data, Maybe, numberToFixed } from "@sade/data-access";
import { translations } from "../generated/translationHelper";
import { convertTimestampToString, DateTimeFormatTarget } from "./TimeUtils";

export type StatusValue = string | number | boolean | JSX.Element;

export interface StatusEntry {
  title: string;
  value: StatusValue;
}

export type StatusDataRow = StatusValue[];

export interface TimestampRange {
  start: number;
  end: number;
}

export class ClientProperties {
  public static getOverlayPopupEntries(data: Data): StatusEntry[] {
    return [
      // Show formatted value with unit for each, or when devices report no value, show hyphen and unit:
      {
        title: translations.common.data.temperature(),
        value: ClientProperties.formatValue(data.temperature, "\u2103", 2) ?? "-\u2103",
      },
      {
        title: translations.common.data.humidity(),
        value: ClientProperties.formatValue(data.humidity, "%", 2) ?? "-%",
      },
      {
        title: translations.common.data.pressure(),
        value: ClientProperties.formatValue(data.pressure, " hPa", 2) ?? "- hPa",
      },
      {
        title: translations.common.data.batteryLevel(),
        value: ClientProperties.formatValue(data.batteryLevel, "%", 2) ?? "-%",
      },
      {
        title: translations.common.data.speed(),
        value: ClientProperties.formatValue(data.speed, " km/h", 2) ?? "- km/h",
      },
    ];
  }

  public static getAlarmTooltipFromEntities(entities: string[]): string {
    if (entities.length > 0) {
      const result = entities.join(",") + " alarm";
      return result.charAt(0).toUpperCase() + result.slice(1);
    } else {
      return "";
    }
  }

  public static getDefaultEventTimestampRange(days: number): TimestampRange {
    const delta: number = days * 24 * 60 * 60 * 1000;
    const startTimestamp: number = new Date().getTime() - delta;
    const endTimestamp: number = new Date().getTime();
    return {
      start: startTimestamp,
      end: endTimestamp,
    };
  }

  // TODO: This should be made dynamic based on the data received from backend (IP-471)
  public static getStatusRowEntries(data: Data): StatusEntry[] {
    return [
      {
        title: translations.common.data.timestamp(),
        value: convertTimestampToString(data.timestamp, DateTimeFormatTarget.StatusTable),
      },
      // Show formatted value with unit for each, or when devices report no value, show blank w/o unit:
      {
        title: translations.common.data.temperature(),
        value: ClientProperties.formatValue(data.temperature, "\u2103", 2) ?? " ",
      },
      { title: translations.common.data.humidity(), value: ClientProperties.formatValue(data.humidity, "%", 2) ?? " " },
      {
        title: translations.common.data.pressure(),
        value: ClientProperties.formatValue(data.pressure, " hPa", 2) ?? " ",
      },
      {
        title: translations.common.data.batteryLevel(),
        value: ClientProperties.formatValue(data.batteryLevel, "%", 2) ?? " ",
      },
      { title: translations.common.data.speed(), value: ClientProperties.formatValue(data.speed, " km/h", 2) ?? " " },
    ];
  }

  private static formatValue(
    value: number | string | boolean | undefined,
    unit: string,
    decimals?: number
  ): Maybe<string> {
    if (value === undefined) {
      return;
      // Show "0" and unit instead of blank when devices report "0" value:
    } else if (value === 0) {
      return "0" + unit;
      // Show as many decimals as stated, or if not stated, show full value including all decimals:
    } else if (typeof value === "number") {
      const num = decimals != null ? numberToFixed(value, decimals) : value;
      return `${num}${unit}`;
    } else {
      console.log("no number value reported");
      return "N/A";
    }
  }
}

export default ClientProperties;
