/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { DeviceStateProperties } from "../../data/device/DeviceStateProperties";
export class ReferenceHWStateProperties extends DeviceStateProperties {
    constructor(deviceProperties) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        super(deviceProperties);
        this.batterySoc = (_a = deviceProperties.batterySoc) !== null && _a !== void 0 ? _a : null;
        this.chargerConnected = (_b = deviceProperties.chargerConnected) !== null && _b !== void 0 ? _b : null;
        this.signalStrength = (_c = deviceProperties.signalStrength) !== null && _c !== void 0 ? _c : null;
        this.modem = (_d = deviceProperties.modem) !== null && _d !== void 0 ? _d : null;
        this.gpsFix = (_e = deviceProperties.gpsFix) !== null && _e !== void 0 ? _e : null;
        this.powerSupply = (_f = deviceProperties.powerSupply) !== null && _f !== void 0 ? _f : null;
        this.resetReason = (_g = deviceProperties.resetReason) !== null && _g !== void 0 ? _g : null;
        this.resetCount = (_h = deviceProperties.resetCount) !== null && _h !== void 0 ? _h : null;
        this.selfTestError = (_j = deviceProperties.selfTestError) !== null && _j !== void 0 ? _j : null;
        this.gpsDebugData = (_k = deviceProperties.gpsDebugData) !== null && _k !== void 0 ? _k : null;
    }
}
