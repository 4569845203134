/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Button, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { AuthWrapper, Maybe } from "@sade/data-access";
import PasswordForm from "./components/password-form";
import AttributeForm from "./components/attribute-form";
import ConfirmationDialog from "../ui/confirmation-dialog";
import { translations } from "../../generated/translationHelper";

interface Props {}

interface State {
  dialog?: Dialog;
}

interface Dialog {
  title: string;
  message: string;
  onAccept: () => void;
  onDecline?: () => void;
}

export default class UserView extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private triggerLogOutConfirmation = (): void => {
    this.setState({
      dialog: {
        title: translations.user.texts.logOut(),
        message: translations.user.texts.youAreAboutToLogOut(),
        onAccept: (): Promise<void> => this.logOut(),
        onDecline: (): void => this.resetDialog(),
      },
    });
  };

  private resetDialog(): void {
    this.setState({ dialog: undefined });
  }

  private async logOut(): Promise<void> {
    await AuthWrapper.logOut();
  }

  private renderConfirmDialog(): Maybe<JSX.Element> {
    if (this.state.dialog) {
      return (
        <ConfirmationDialog
          title={this.state.dialog.title}
          message={this.state.dialog.message}
          onConfirm={this.state.dialog.onAccept}
          onCancel={this.state.dialog.onDecline}
        />
      );
    }
  }

  private renderLogOutButton(): JSX.Element {
    return (
      <Grid container={true} justifyContent="center" spacing={2}>
        <Grid item={true}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {translations.user.texts.doYouWantToLogOut()}
          </Typography>
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={this.triggerLogOutConfirmation}
            data-testid="logout-button"
          >
            {translations.user.buttons.logOut()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} justifyContent="center">
        <Grid item={true} container={true} xs={10} sm={8} md={6} spacing={6}>
          <Grid item={true} xs={12}>
            <AttributeForm />
          </Grid>
          <Grid item={true} xs={12}>
            <PasswordForm />
          </Grid>
          <Grid item={true} xs={12}>
            {this.renderLogOutButton()}
          </Grid>
          {this.renderConfirmDialog()}
        </Grid>
      </Grid>
    );
  }
}
