/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";

interface Props {
  value: number;
  index: number;
  retainChildrenWhenInvisible?: boolean;
}

/**
 * Displays a tab panel when {@code props.value === props.index}.
 * The index is the index number of the tab.
 * The value is the currently selected tab index, assigned to <Tabs value={}>
 */
const TabPanel: FunctionComponent<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): ReactElement => {
  const { value, index, retainChildrenWhenInvisible, children, ...rest } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {(value === index || retainChildrenWhenInvisible) && children}
    </div>
  );
};

export default TabPanel;
