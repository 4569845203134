/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { DataUtil } from "./Data";
import { DevicesMeasurementsUpdateFeedDocument, } from "../../generated/gqlData";
import { AbstractReceiverSubscriptionManager } from "../subscriptions";
import { AppSyncClientFactory } from "../backend";
export class DataSubscriptionManager extends AbstractReceiverSubscriptionManager {
    constructor() {
        super(() => AppSyncClientFactory.createProvider().getTypedClient(Service.DATA), DevicesMeasurementsUpdateFeedDocument);
    }
    static get instance() {
        return this.__instance;
    }
    async handleSubscriptionResult(result, listeners) {
        if (!(result === null || result === void 0 ? void 0 : result.devicesMeasurementsUpdateFeed))
            return;
        const dataItem = DataUtil.parseDataFragment(result.devicesMeasurementsUpdateFeed.item);
        if (dataItem) {
            listeners.forEach((listener) => {
                if (listener.getId() === dataItem.deviceId) {
                    listener.onData(dataItem);
                }
            });
        }
    }
}
DataSubscriptionManager.__instance = new DataSubscriptionManager();
