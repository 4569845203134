/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Button, Dialog, Typography } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import { translations } from "../../generated/translationHelper";

interface Props {
  successMsg?: string;
  onClose: () => void;
}

export default class SuccessDialog extends Component<Props> {
  private handleOk = (): void => {
    this.props.onClose();
  };

  public render(): ReactNode {
    if (!this.props.successMsg) {
      return null;
    }
    return (
      <Dialog onClose={this.props.onClose} open={true}>
        <div className="popup-inner">
          <Typography variant="h6" style={{ display: "inline-block" }}>
            {translations.common.texts.success()}
          </Typography>
          <div className="popup-body" data-testid="success-popup">
            <p>{this.props.successMsg}</p>
            <div className="buttons-container">
              <Button variant="contained" color="primary" onClick={this.handleOk} data-testid="success-popup-button">
                {translations.common.buttons.ok()}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
