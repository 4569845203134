/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment } from "react";
import { Organization } from "@sade/data-access";
import OrganizationDetails from "./organization-details";
import OrganizationChildList from "./organization-child-list";

interface Props {
  organization: Organization;
}

export default class OrganizationManagement extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <OrganizationDetails organization={this.props.organization} />
        <OrganizationChildList parentOrganization={this.props.organization} />
      </Fragment>
    );
  }
}
