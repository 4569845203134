/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { AuthEvent, AuthListener, BackendFactory, RoleIdentifiers } from "@sade/data-access";

export default class ViewAccessMethods {
  private static adminAccess: boolean | undefined;
  private static ownerAccess: boolean | undefined;

  public static async hasAdminAccess(): Promise<boolean> {
    if (ViewAccessMethods.adminAccess != null) {
      return ViewAccessMethods.adminAccess;
    }
    const organizationBackend = BackendFactory.getOrganizationBackend();

    try {
      const user = await organizationBackend.getCurrentUser();

      if (!user) {
        return false;
      }

      const hasAdminGroup = await Promise.all(
        [RoleIdentifiers.Owner, RoleIdentifiers.SuperAdmin, RoleIdentifiers.Admin].map((id) => user.hasRole(id))
      );

      ViewAccessMethods.adminAccess = hasAdminGroup.includes(true);
    } catch (err) {
      console.error("Failed to check for admin rights", err);
      ViewAccessMethods.adminAccess = false;
    }

    ViewAccessMethods.logPermission("Admin*", ViewAccessMethods.adminAccess);
    return ViewAccessMethods.adminAccess;
  }

  public static async hasOwnerAccess(): Promise<boolean> {
    if (ViewAccessMethods.ownerAccess != null) {
      return ViewAccessMethods.ownerAccess;
    }

    const organizationBackend = BackendFactory.getOrganizationBackend();
    try {
      const user = await organizationBackend.getCurrentUser();

      if (!user) {
        return false;
      }

      ViewAccessMethods.ownerAccess = await user.hasRole(RoleIdentifiers.Owner);
    } catch (err) {
      console.error("Failed to check for owner rights", err);
      ViewAccessMethods.ownerAccess = false;
    }

    ViewAccessMethods.logPermission("Owner*", ViewAccessMethods.ownerAccess);
    return ViewAccessMethods.ownerAccess;
  }

  private static authCallback = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      ViewAccessMethods.adminAccess = undefined;
      ViewAccessMethods.ownerAccess = undefined;
    }
  };

  private static authListener = new AuthListener(ViewAccessMethods.authCallback);

  private static logPermission(name: string, access: boolean): void {
    console.log(`User ${access ? "has" : "does not have"} ${name} access`);
  }
}
