/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { Role, RoleIdentifiers } from "@sade/data-access";
import { translations } from "../../../../generated/translationHelper";

export function translateRoleName(role: Role): string {
  switch (role.identifier) {
    case RoleIdentifiers.Owner:
      return translations.admin.texts.roleOwner();
    case RoleIdentifiers.SuperAdmin:
      return translations.admin.texts.roleSuperAdmin();
    case RoleIdentifiers.Admin:
      return translations.admin.texts.roleAdmin();
    case RoleIdentifiers.User:
      return translations.admin.texts.roleUser();
    default: {
      console.error(`Unknown role ${role.name}(${role.identifier})`);
      return translations.common.texts.notAvailable();
    }
  }
}
