/*
 *  Copyright (C) 2021 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { t } from "../locales/localisator";

export default function getSensorName(sensorId: string): string {
  // using t() instead of translationHelper, since translationHelper can not support default values
  return t(`common.data.${sensorId}`, { defaultValue: sensorId });
}
