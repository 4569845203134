/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { DeviceGroup } from "./DeviceGroup";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { DeviceGroupsDeleteDocument, DeviceGroupsDevicesAddDocument, DeviceGroupsDevicesRemoveDocument, } from "../../generated/gqlDevice";
import { AuthWrapper } from "../auth/AuthWrapper";
import { throwGQLError } from "../private-utils/throwGQLError";
import { PromiseSemaphore } from "../private-utils/PromiseSemaphore";
import { AWSThing } from "./AWSThing";
const ORGANIZATION_KEY = "organization";
const IOT_MAX_CHILD_GROUPS = 100;
export class AWSThingGroup extends DeviceGroup {
    constructor(backend, params) {
        super(params);
        this.entityType = AWSThingGroup;
        this.devicesSemaphore = new PromiseSemaphore(() => this.backend.getDeviceGroupDevices(this));
        this.childGroupsSemaphore = new PromiseSemaphore(() => this.backend.getDeviceGroups({ parent: this }));
        this.backend = backend;
    }
    static instanceOf(value) {
        return value instanceof AWSThingGroup;
    }
    static getLocalisedName(groupAttributes, defaultValue, language = "label_default") {
        var _a, _b;
        return (_b = (_a = groupAttributes.find((attr) => attr.key === language)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : defaultValue;
    }
    static async getCurrentUserOrganization() {
        const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
        return claims === null || claims === void 0 ? void 0 : claims.homeOrganizationId;
    }
    getLabel() {
        return AWSThingGroup.getLocalisedName(this.getAttributes(), this.getId());
    }
    getOrganization() {
        var _a;
        return (_a = this.getAttributes().find((attribute) => attribute.key === ORGANIZATION_KEY)) === null || _a === void 0 ? void 0 : _a.value;
    }
    async getGroups() {
        await this.childGroupsSemaphore.guard();
        return this.unguardedGetGroups();
    }
    async getDevices() {
        await this.devicesSemaphore.guard();
        return this.unguardedGetDevices();
    }
    async addDevice(device) {
        const deviceId = typeof device === "string" ? device : device.getId();
        const devices = await this.getDevices();
        if (devices.find((dev) => dev.getId() === deviceId)) {
            console.log(`Attempted to add device '${deviceId}' to group '${this.getId()}' but it was already there!`);
            return;
        }
        await this.backendAddDevice(deviceId);
        const addedDevice = await this.toDevice(device, true);
        if (AWSThing.instanceOf(addedDevice)) {
            this.backend.entityRelationCache.link(this, addedDevice);
        }
        else {
            throw new Error(`Failed to add device ${deviceId} to group ${this.getId()}`);
        }
    }
    async removeDevice(device) {
        const deviceId = typeof device === "string" ? device : device.getId();
        const devices = await this.getDevices();
        if (!devices.find((dev) => dev.getId() === deviceId)) {
            console.log(`Attempted to remove device '${deviceId}' from group '${this.getId()}' but it was not there!`);
            return;
        }
        await this.backendRemoveDevice(deviceId);
        const removedDevice = await this.toDevice(device);
        if (AWSThing.instanceOf(removedDevice)) {
            this.backend.entityRelationCache.unlink(this, removedDevice);
        }
        else {
            throw new Error(`Failed to remove device ${deviceId} from group ${this.getId()}`);
        }
    }
    /// AWSThingGroup specific public methods
    async delete() {
        const organizationId = await this.getOrganizationIdForRequest();
        try {
            const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            await appSyncClient.mutate(DeviceGroupsDeleteDocument, {
                groupId: this.getId(),
                organizationId: organizationId,
            });
            await this.backend.removeLocal(this);
            await this.notifyDelete();
        }
        catch (error) {
            console.error("Failed to remove group", error);
            throw error;
        }
    }
    // Private methods
    childGroupCanBeAdded() {
        return this.childGroupsSemaphore.invoked() && this.unguardedGetGroups().length < IOT_MAX_CHILD_GROUPS;
    }
    canBeDeleted() {
        const noChildGroups = this.childGroupsSemaphore.invoked() && this.unguardedGetGroups().length === 0;
        const noDevices = this.devicesSemaphore.invoked() && this.unguardedGetDevices().length === 0;
        return noChildGroups && noDevices;
    }
    async onRelationChange(change) {
        if (change.ofType(AWSThing)) {
            await this.notifyDeviceChange();
        }
        else if (change.ofType(AWSThingGroup)) {
            await this.notifyGroupChange();
        }
    }
    async backendAddDevice(deviceId) {
        var _a;
        const organizationId = await this.getOrganizationIdForRequest();
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await appSyncClient.mutate(DeviceGroupsDevicesAddDocument, {
            deviceId,
            groupId: this.getId(),
            overrideDynamics: false,
            organizationId,
        });
        if (response.errors || !((_a = response.data) === null || _a === void 0 ? void 0 : _a.deviceGroupsDevicesAdd)) {
            throwGQLError(response, `Failed to add device ${deviceId} to group ${this.getId()}`);
        }
    }
    async backendRemoveDevice(deviceId) {
        var _a;
        const organizationId = await this.getOrganizationIdForRequest();
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await appSyncClient.mutate(DeviceGroupsDevicesRemoveDocument, {
            deviceId,
            groupId: this.getId(),
            organizationId,
        });
        if (response.errors || !((_a = response.data) === null || _a === void 0 ? void 0 : _a.deviceGroupsDevicesRemove)) {
            throwGQLError(response, `Failed to remove device ${deviceId} from group ${this.getId()}`);
        }
    }
    async toDevice(device, refreshCache) {
        return typeof device !== "string" ? device : await this.backend.getDevice(device, refreshCache);
    }
    unguardedGetGroups() {
        const groupRecords = this.backend.entityRelationCache.listEntityRecordsFor(this, AWSThingGroup);
        return groupRecords
            .filter((record) => { var _a; return ((_a = record.metadata) === null || _a === void 0 ? void 0 : _a.parentId) !== record.entity.getId(); })
            .map((record) => record.entity);
    }
    unguardedGetDevices() {
        return this.backend.entityRelationCache.listFor(this, AWSThing);
    }
    async getOrganizationIdForRequest() {
        const organizationId = this.getOrganization();
        if (organizationId) {
            return organizationId;
        }
        const userOrganization = await AWSThingGroup.getCurrentUserOrganization();
        if (!userOrganization) {
            throw new Error("Could not resolve organization!");
        }
        return userOrganization;
    }
    /// STATIC METHODS
    async notifyGroupChange() {
        const groups = await this.getGroups();
        this.notifyAction((observer) => { var _a; return (_a = observer.onGroupsChanged) === null || _a === void 0 ? void 0 : _a.call(observer, groups, this); });
    }
    async notifyDeviceChange() {
        const devices = await this.getDevices();
        this.notifyAction((observer) => { var _a; return (_a = observer.onDevicesChanged) === null || _a === void 0 ? void 0 : _a.call(observer, devices, this); });
    }
    async notifyDelete() {
        this.notifyAction((observer) => { var _a; return (_a = observer.onDelete) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
    }
}
