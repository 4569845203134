/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Device, Voidable } from "@sade/data-access";
import { translations } from "../generated/translationHelper";

export function getDisplayName(device: Voidable<Device>): string {
  if (device) {
    const deviceState = device.getState();

    if (deviceState && deviceState.displayName) {
      return deviceState.displayName;
    }
    return device.getId();
  }
  return translations.common.texts.N_A();
}
