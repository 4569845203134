/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export function throwGQLError(response, fallback = "Unexpected error") {
    if (!response.errors || response.errors.length === 0) {
        throw new Error(fallback);
    }
    else {
        const error = response.errors[0]; // for now, ignore all the other errors
        const msg = `${error.name}: ${error.message}${error.originalError ? "( " + error.originalError.message + " )" : ""}`;
        throw new Error(msg);
    }
}
