/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import i18n, { InitOptions, LanguageDetectorAsyncModule, Services, TOptionsBase } from "i18next";
import { initReactI18next } from "react-i18next";
import { getEnvironmentLanguage, isDebugModeEnabled, SupportedLanguageCode } from "./localizationUtils";
import { AuthWrapper, Maybe } from "@sade/data-access";
import en from "./en/translations.json";
import fi from "./fi/translations.json";

type ValueRecord = Record<string, string | number | boolean>;

export const DEFAULT_LANGUAGE: SupportedLanguageCode = "en";

const initialOptions: InitOptions = {
  resources: {
    en,
    fi,
  },
  initImmediate: false,
  load: "languageOnly",
  debug: isDebugModeEnabled(),
  fallbackLng: DEFAULT_LANGUAGE,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    useSuspense: true,
    nsMode: "default",
  },
};

const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: (callback: (locale?: string) => void): void => {
    const overrideLanguage = getEnvironmentLanguage();
    if (overrideLanguage) callback(overrideLanguage);
    else AuthWrapper.getLanguage().then((locale) => callback(locale));
  },
  init: (_params: Services): void => void 0,
  cacheUserLanguage: (_locale: string) => void 0,
};

/*
 * INITIALIZE i18n
 */
export function initLocalization(): void {
  i18n.use(languageDetector).use(initReactI18next).init(initialOptions).then(); // disable warning for ignored promise
}

/**
 * Gets translated string.
 * @param {string | string[]} key - string or string array which mimics referencing object value for example: foo.bar.value
 * @param {TOptionsBase & ValueRecord} options - translation options
 * @returns {string} translated string or key
 */
export function t(key: string | string[], options?: TOptionsBase & ValueRecord): string {
  if (i18n.isInitialized) return i18n.t(key, options);
  return typeof key === "string" ? key : key[0];
}

/**
 * Gets selected language.
 * @returns {Maybe<string>} language code string
 */
export function getLanguage(): Maybe<string> {
  if (i18n.isInitialized) return i18n.language;
}

/**
 * Changes language to provided language or override language if set, otherwise changes to default language {@link DEFAULT_LANGUAGE}
 * @param {Maybe<string>} languageCode language code string
 */
export async function changeLanguage(languageCode?: string): Promise<void> {
  if (i18n.isInitialized) {
    const newLanguage = getEnvironmentLanguage() ?? languageCode ?? DEFAULT_LANGUAGE;
    if (newLanguage !== getLanguage()) await i18n.changeLanguage(newLanguage);
  }
}
