/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { DataUtil } from "./Data";
import { LatestData } from "./LatestData";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { DevicesMeasurementsLatestDocument } from "../../generated/gqlData";
import { DataSubscriptionManager } from "./DataSubscriptionManager";
export class AWSLatestData extends LatestData {
    constructor(device, type) {
        super();
        this.device = device;
        this.type = type;
        this.dataListener = {
            getId: () => {
                return this.getId();
            },
            onData: (data) => {
                this.setData(data);
            },
        };
    }
    getId() {
        return this.device.getId();
    }
    getData() {
        return this.data;
    }
    async fetch() {
        try {
            if (this.device.getId() != null) {
                const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
                const latestDataResponse = await appSyncClient.query(DevicesMeasurementsLatestDocument, {
                    deviceId: this.device.getId(),
                }, 
                // mobile-app specific: Force fetch or will return old data from cache
                {
                    fetchPolicy: "network-only",
                });
                if (latestDataResponse.data.devicesMeasurementsLatest) {
                    this.data = DataUtil.parseDataFragment(latestDataResponse.data.devicesMeasurementsLatest, this.type);
                }
            }
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    setData(data) {
        const dataWithDefinedValues = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
        this.data = Object.assign(Object.assign({}, this.data), dataWithDefinedValues);
        this.notifyAction((observer) => observer.onDataUpdate(this));
    }
    /**
     * Add observer for latest data updates
     *
     * Customization:
     * Current implementation registers for all receivers device returns.
     * Modify this list if state access needs different limits.
     *
     * @param observer
     */
    async addObserver(observer) {
        if (!this.data) {
            await this.fetch();
        }
        super.addObserver(observer);
        if (this.observerCount === 1) {
            DataSubscriptionManager.instance.addListener(this.dataListener, await this.device.getReceivers());
        }
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        if (this.observerCount === 0) {
            DataSubscriptionManager.instance.removeListener(this.dataListener);
        }
    }
    clearObservers() {
        super.clearObservers();
        DataSubscriptionManager.instance.removeListener(this.dataListener);
    }
}
