/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { DevicesStatesUpdateDocument } from "../../generated/gqlDevice";
import { timestampToMillisecondPrecision } from "../../common/timeUtils";
export class DeviceState {
    constructor(id, reported, desired, timestamp, version, connectionState) {
        this.reported = reported;
        this.desired = desired;
        // Changed shadow properties need to be added to this object when setting property value
        this.changedValues = {};
        this.id = id;
        this.updateTimestamp = timestamp;
        this.shadowVersion = version;
        this.connState = connectionState;
    }
    get deviceId() {
        return this.id;
    }
    get displayName() {
        return (this.deltaExists("displayName") ? this.desired.displayName : this.reported.displayName);
    }
    set displayName(name) {
        this.desired.displayName = name;
        this.changedValues.displayName = name;
    }
    get otaId() {
        return this.reported.otaId;
    }
    set otaId(id) {
        this.desired.otaId = id;
        this.changedValues.otaId = id;
    }
    get connectionState() {
        return this.connState;
    }
    get version() {
        return this.shadowVersion;
    }
    get firmwareVersion() {
        return this.reported.deviceFwVersion;
    }
    static instanceOf(state) {
        return state instanceof DeviceState;
    }
    // Returns true if one of the properties is being taken into use in the device side
    beingApplied(key) {
        return this.deltaExists(key) && this.changedValues[key] === undefined;
    }
    deltaExists(key) {
        return this.desired[key] != null && this.desired[key] !== this.reported[key];
    }
    getStateUpdatedTimestampMillis() {
        if (this.updateTimestamp) {
            return timestampToMillisecondPrecision(this.updateTimestamp);
        }
    }
    async store() {
        console.log("Store state, payload " + JSON.stringify(this.changedValues));
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            await client.mutate(DevicesStatesUpdateDocument, {
                deviceId: this.deviceId,
                state: JSON.stringify(this.changedValues),
            });
            this.changedValues = {};
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    revert() {
        const keys = Object.keys(this.changedValues);
        keys.forEach((key) => {
            this.desired[key] = this.reported[key];
        });
        this.changedValues = {};
    }
}
