/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import ErrorDialog from "../../../ui/error-dialog";
import FormDialog from "../../../ui/form-dialog";
import { Button, FormControl, FormLabel, Grid, TextField, Typography } from "@material-ui/core";
import Loader from "../../../ui/loader";
import {
  Organization,
  User,
  EventDefinition,
  BackendFactory,
  Device,
  OtaUpdate,
  OtaManager,
  Maybe,
} from "@sade/data-access";
import UpdateIcon from "@material-ui/icons/Update";
import { translations } from "../../../../generated/translationHelper";
import DropdownSelection from "../../../ui/dropdown-selection";
import SelectOtaUpdatePopup from "./select-ota-update-popup";

interface Props {
  organization: Organization;
  users: User[];
  events: EventDefinition[];
  open: boolean;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  selectedDeviceType?: string;
  deviceTypes: string[];
  errorMessage?: string;
  searchQuery: string;
  searchResults?: number;
  selectedFirmware?: OtaUpdate;
  selectedFirmwareVersion: string;
  showSelectOtaUpdatePopup: boolean;
  selectedMaximum: string;
  selectedDailyMaximum: string;
}

export default class AddBatchUpdatePopup extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      deviceTypes: BackendFactory.getBackend().getSupportedDeviceTypes(),
      searchQuery: "",
      showSelectOtaUpdatePopup: false,
      selectedFirmwareVersion: "",
      selectedDailyMaximum: "",
      selectedMaximum: "",
    };
  }

  private getFullSearchQuery(): string {
    if (!this.state.selectedFirmware) throw new Error(translations.admin.texts.noFirmwareSelected());
    return `thingTypeName:${this.state.selectedDeviceType} NOT shadow.desired.otaId:${
      this.state.selectedFirmware.otaId
    } NOT shadow.reported.otaId:${this.state.selectedFirmware.otaId}${
      this.state.searchQuery ? " AND " + this.state.searchQuery : ""
    }`;
  }

  private searchDevices = async (): Promise<void> => {
    if (!this.state.selectedDeviceType) {
      return this.setErrorState(translations.admin.texts.noDeviceTypeSelected());
    }

    try {
      this.setState({ loading: true });
      const devicesSearch: Device[] = await BackendFactory.getBackend().searchDevices(this.getFullSearchQuery());
      this.setState({
        searchResults: devicesSearch.length,
        selectedMaximum: devicesSearch.length.toString(),
        selectedDailyMaximum: devicesSearch.length.toString(),
      });
    } catch (error) {
      this.setErrorState(error instanceof Error ? error : JSON.stringify(error));
    } finally {
      this.setState({ loading: false });
    }
  };

  private selectFirmware(): void {
    if (this.state.selectedDeviceType) {
      this.setState({ showSelectOtaUpdatePopup: true });
    }
  }

  private closeThis(success: boolean): void {
    this.setState({
      selectedFirmwareVersion: "",
      selectedDeviceType: undefined,
      selectedFirmware: undefined,
      searchQuery: "",
      searchResults: undefined,
    });
    this.props.onClose(success);
  }

  private startUpdate = async (): Promise<void> => {
    this.setState({ loading: true });

    if (!this.state.searchResults) {
      return this.setErrorState(translations.admin.texts.noSearchResults());
    }

    if (!this.state.selectedFirmware) {
      return this.setErrorState(translations.admin.texts.noFirmwareSelected());
    }

    try {
      await OtaManager.getInstance().startOtaBatchExecution(
        this.getFullSearchQuery(),
        this.state.selectedFirmware.otaId,
        this.state.selectedMaximum ? parseInt(this.state.selectedMaximum) : this.state.searchResults,
        this.state.selectedDailyMaximum ? parseInt(this.state.selectedDailyMaximum) : undefined
      );
    } catch (error) {
      this.setErrorState(error instanceof Error ? error : JSON.stringify(error));
    } finally {
      this.setState({ loading: false });
      this.closeThis(true);
    }
  };

  private setErrorState(message: string | Error): void {
    console.error("AddBatchUpdatePopup", message);
    this.setState({
      errorMessage: message.toString(),
    });
  }

  private renderError(): ReactNode {
    return (
      <ErrorDialog
        onClose={(): void => {
          this.setState({ errorMessage: undefined });
        }}
        errorMsg={this.state.errorMessage}
      />
    );
  }

  private handleClosePopup = (): void => {
    this.setState({
      showSelectOtaUpdatePopup: false,
    });
  };

  private getCurrentDeviceTypeSelectionIndex(): Maybe<number> {
    const index = this.state.deviceTypes.findIndex((deviceType) => deviceType === this.state.selectedDeviceType);
    return index !== -1 ? index : undefined;
  }

  private handleDeviceTypeSelected = (index?: number): void => {
    this.setState({
      selectedDeviceType: index !== undefined ? this.state.deviceTypes[index] : undefined,
    });
  };

  private renderSelectOtaUpdatePopup(): ReactNode {
    if (this.state.selectedDeviceType) {
      return (
        <SelectOtaUpdatePopup
          open={this.state.showSelectOtaUpdatePopup}
          type={this.state.selectedDeviceType}
          onClose={async (selection): Promise<void> => {
            this.handleClosePopup();

            if (selection) {
              console.log("selected " + selection.firmwareVersion);
              this.setState({ selectedFirmware: selection, selectedFirmwareVersion: selection.firmwareVersion });
            }
          }}
        />
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderError()}
        {this.renderSelectOtaUpdatePopup()}
        <FormDialog
          title={translations.admin.texts.newBatchUpdate()}
          acceptButtonText={translations.admin.texts.startUpdate()}
          isOpen={this.props.open}
          onAccept={this.startUpdate}
          onCancel={(): void => this.closeThis(false)}
          disableAccept={!this.state.searchResults || !this.state.selectedFirmware}
        >
          <UpdateIcon className="notification-organization-icon" />
          <Typography>{this.props.organization.getName()}</Typography>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <FormLabel component="legend">{translations.admin.texts.selectDeviceType()}</FormLabel>
            <DropdownSelection
              currentSelection={this.getCurrentDeviceTypeSelectionIndex()}
              onSelect={this.handleDeviceTypeSelected}
              selectionList={this.state.deviceTypes.map((deviceType) => ({ key: deviceType, label: deviceType }))}
              emptySelectionItem={"Device Type"}
              disabled={this.state.deviceTypes?.length === 0}
            />
          </FormControl>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <TextField
              id="query-label"
              label={translations.admin.texts.selectFirmware()}
              variant="standard"
              value={this.state.selectedFirmwareVersion}
              onClick={(): void => void this.selectFirmware()}
              disabled={!this.state.selectedDeviceType}
              InputProps={{
                readOnly: true,
                classes: {
                  underline: "input-readonly underlined",
                  focused: "input-readonly focused",
                },
              }}
            />
          </FormControl>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <TextField
              id="query-label"
              label={translations.admin.texts.query()}
              variant="standard"
              value={this.state.searchQuery ?? ""}
              onChange={(event): void =>
                this.setState({ searchQuery: event.currentTarget.value, searchResults: undefined })
              }
              disabled={!this.state.selectedFirmware}
            />
          </FormControl>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <Button
              variant="contained"
              color="primary"
              onClick={this.searchDevices}
              disabled={!this.state.selectedFirmware}
            >
              {translations.common.inputs.search()}
            </Button>
          </FormControl>
          <div style={{ height: "120px", width: "100%" }}>
            {!this.state.loading && this.state.searchResults !== undefined && (
              <Fragment>
                <Grid container={true} direction={"row"} justifyContent="space-evenly">
                  <Typography style={{ paddingTop: "30px" }}>
                    {translations.admin.texts.devicesFound({ count: this.state.searchResults })}
                  </Typography>
                </Grid>
                <Grid container={true} direction={"row"} justifyContent="space-evenly">
                  <Grid item={true}>
                    <FormControl component="fieldset" margin="normal">
                      <TextField
                        id="max-daily-label"
                        label={translations.admin.texts.dailyMaximum()}
                        variant="standard"
                        type="number"
                        value={this.state.selectedDailyMaximum}
                        onChange={(event): void => this.setState({ selectedDailyMaximum: event.currentTarget.value })}
                        disabled={!this.state.searchResults}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true}>
                    <FormControl component="fieldset" margin="normal">
                      <TextField
                        id="max-label"
                        label={translations.admin.texts.maximum()}
                        variant="standard"
                        type="number"
                        value={this.state.selectedMaximum}
                        onChange={(event): void => this.setState({ selectedMaximum: event.currentTarget.value })}
                        disabled={!this.state.searchResults}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Fragment>
            )}

            <Loader show={this.state.loading} />
          </div>
        </FormDialog>
      </Fragment>
    );
  }
}
