/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { RuuviTagHWState } from "./RuuviTagHWState";
import { RuuviTagHWStateProperties } from "./RuuviTagHWStateProperties";
import { AWSThing } from "../../data/device/AWSThing";
import { iotItem24Px } from "../../generated/SadeDataIconModel";
export class RuuviTagHW extends AWSThing {
    constructor(backend, params) {
        super(RuuviTagHW.type, backend, params);
    }
    static instanceOf(device) {
        return device instanceof RuuviTagHW;
    }
    getIcon() {
        return iotItem24Px.data;
    }
    createState(timestamp, version, reported, desired, connectionState) {
        return new RuuviTagHWState(this.getId(), new RuuviTagHWStateProperties(reported !== null && reported !== void 0 ? reported : {}), new RuuviTagHWStateProperties(desired !== null && desired !== void 0 ? desired : {}), timestamp, version, connectionState);
    }
}
RuuviTagHW.type = "RuuviTag";
