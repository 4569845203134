/*
*  Copyright (C) 2021 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { TOptionsBase } from "i18next";
import { t } from "../locales/localisator";

type ValueRecord = Record<string, string | number | boolean>;
type OptionParams = Omit<TOptionsBase, "defaultValue"> & ValueRecord;

export const translations = {
  "logIn": {
    "texts": {
      "logInTo": (options?: OptionParams): string => t("logIn.texts.logInTo", options),
      "resetYourPassword": (options?: OptionParams): string => t("logIn.texts.resetYourPassword", options),
      "enterVerificationCodeAndPassword": (options?: OptionParams): string => t("logIn.texts.enterVerificationCodeAndPassword", options),
      "emailFormatNotValid": (options?: OptionParams): string => t("logIn.texts.emailFormatNotValid", options),
      "emailNotVerified": (options?: OptionParams): string => t("logIn.texts.emailNotVerified", options),
      "temporaryPasswordExpired": (options?: OptionParams): string => t("logIn.texts.temporaryPasswordExpired", options),
      "passwordCannotBeEmpty": (options?: OptionParams): string => t("logIn.texts.passwordCannotBeEmpty", options),
      "usernameCannotBeEmpty": (options?: OptionParams): string => t("logIn.texts.usernameCannotBeEmpty", options),
      "passwordCannotBeResetCurrently": (options?: OptionParams): string => t("logIn.texts.passwordCannotBeResetCurrently", options),
      "verificationCodeSentToEmail": (options?: OptionParams): string => t("logIn.texts.verificationCodeSentToEmail", options),
      "invalidCode": (options?: OptionParams): string => t("logIn.texts.invalidCode", options),
      "codeExpired": (options?: OptionParams): string => t("logIn.texts.codeExpired", options),
      "finishAccount": (options?: OptionParams): string => t("logIn.texts.finishAccount", options),
      "newPasswordRequired": (options?: OptionParams): string => t("logIn.texts.newPasswordRequired", options),
      "incorrectCredentials": (options?: OptionParams): string => t("logIn.texts.incorrectCredentials", options),
      "userNotLoggedIn": (options?: OptionParams): string => t("logIn.texts.userNotLoggedIn", options),
      "support": (options?: OptionParams): string => t("logIn.texts.support", options),
      "privacy": (options?: OptionParams): string => t("logIn.texts.privacy", options),
      "termsOfUse": (options?: OptionParams): string => t("logIn.texts.termsOfUse", options),
      "copyright": (options?: OptionParams): string => t("logIn.texts.copyright", options),
      "passwordMustContain": (options?: OptionParams): string => t("logIn.texts.passwordMustContain", options),
      "passwordLength": (options?: OptionParams): string => t("logIn.texts.passwordLength", options),
      "passwordLowercase": (options?: OptionParams): string => t("logIn.texts.passwordLowercase", options),
      "passwordNumber": (options?: OptionParams): string => t("logIn.texts.passwordNumber", options),
      "forgotPassword": (options?: OptionParams): string => t("logIn.texts.forgotPassword", options),
      "forgotPasswordInstructions": (options?: OptionParams): string => t("logIn.texts.forgotPasswordInstructions", options)
    },
    "inputs": {
      "confirmationCode": (options?: OptionParams): string => t("logIn.inputs.confirmationCode", options),
      "password": (options?: OptionParams): string => t("logIn.inputs.password", options),
      "acceptTermsOfUse": (options?: OptionParams): string => t("logIn.inputs.acceptTermsOfUse", options)
    },
    "buttons": {
      "logIn": (options?: OptionParams): string => t("logIn.buttons.logIn", options),
      "backToLogIn": (options?: OptionParams): string => t("logIn.buttons.backToLogIn", options),
      "confirmLogIn": (options?: OptionParams): string => t("logIn.buttons.confirmLogIn", options),
      "forgotPassword": (options?: OptionParams): string => t("logIn.buttons.forgotPassword", options),
      "verificationCode": (options?: OptionParams): string => t("logIn.buttons.verificationCode", options),
      "sendCode": (options?: OptionParams): string => t("logIn.buttons.sendCode", options),
      "resendCode": (options?: OptionParams): string => t("logIn.buttons.resendCode", options)
    },
  },
  "deviceDrawer": {
    "texts": {
      "title": (options?: OptionParams): string => t("deviceDrawer.texts.title", options),
      "noDevicesOrGroupsFound": (options?: OptionParams): string => t("deviceDrawer.texts.noDevicesOrGroupsFound", options),
      "lastUpdated": (options?: OptionParams): string => t("deviceDrawer.texts.lastUpdated", options)
    },
    "alts": {
      "signal": (options?: OptionParams): string => t("deviceDrawer.alts.signal", options),
      "Battery": (options?: OptionParams): string => t("deviceDrawer.alts.Battery", options),
      "connectionState": (options?: OptionParams): string => t("deviceDrawer.alts.connectionState", options)
    },
  },
  "deviceSettings": {
    "tabs": {
      "general": (options?: OptionParams): string => t("deviceSettings.tabs.general", options),
      "attributes": (options?: OptionParams): string => t("deviceSettings.tabs.attributes", options),
      "otaUpdate": (options?: OptionParams): string => t("deviceSettings.tabs.otaUpdate", options)
    },
    "buttons": {
      "installOta": (options?: OptionParams): string => t("deviceSettings.buttons.installOta", options),
      "cancelOta": (options?: OptionParams): string => t("deviceSettings.buttons.cancelOta", options)
    },
    "texts": {
      "noAttributesDefined": (options?: OptionParams): string => t("deviceSettings.texts.noAttributesDefined", options),
      "HWID": (options?: OptionParams): string => t("deviceSettings.texts.HWID", options),
      "canSee": (options?: OptionParams): string => t("deviceSettings.texts.canSee", options),
      "organization": (options?: OptionParams): string => t("deviceSettings.texts.organization", options),
      "serialNumber": (options?: OptionParams): string => t("deviceSettings.texts.serialNumber", options),
      "disableModemSleep": (options?: OptionParams): string => t("deviceSettings.texts.disableModemSleep", options),
      "updateInterval": (options?: OptionParams): string => t("deviceSettings.texts.updateInterval", options),
      "measurementInterval": (options?: OptionParams): string => t("deviceSettings.texts.measurementInterval", options),
      "firmwareVersion": (options?: OptionParams): string => t("deviceSettings.texts.firmwareVersion", options),
      "resetCount": (options?: OptionParams): string => t("deviceSettings.texts.resetCount", options),
      "size": (options?: OptionParams): string => t("deviceSettings.texts.size", options),
      "version": (options?: OptionParams): string => t("deviceSettings.texts.version", options),
      "description": (options?: OptionParams): string => t("deviceSettings.texts.description", options),
      "otaId": (options?: OptionParams): string => t("deviceSettings.texts.otaId", options),
      "noUpdateAvailable": (options?: OptionParams): string => t("deviceSettings.texts.noUpdateAvailable", options),
      "noInstallationInProgress": (options?: OptionParams): string => t("deviceSettings.texts.noInstallationInProgress", options),
      "availableOtaPackages": (options?: OptionParams): string => t("deviceSettings.texts.availableOtaPackages", options),
      "otaUpdateProcess": (options?: OptionParams): string => t("deviceSettings.texts.otaUpdateProcess", options),
      "deviceFirmwareInformation": (options?: OptionParams): string => t("deviceSettings.texts.deviceFirmwareInformation", options),
      "ledColor": (options?: OptionParams): string => t("deviceSettings.texts.ledColor", options),
      "toggleLed": (options?: OptionParams): string => t("deviceSettings.texts.toggleLed", options),
      "ledsOn": (options?: OptionParams): string => t("deviceSettings.texts.ledsOn", options)
    },
  },
  "status": {
    "texts": {
      "activeDevices": (options?: OptionParams): string => t("status.texts.activeDevices", options),
      "locationAvailable": (options?: OptionParams): string => t("status.texts.locationAvailable", options),
      "locationUnavailable": (options?: OptionParams): string => t("status.texts.locationUnavailable", options)
    },
    "buttons": {
      "showDisplayNames": (options?: OptionParams): string => t("status.buttons.showDisplayNames", options),
      "hideDisplayNames": (options?: OptionParams): string => t("status.buttons.hideDisplayNames", options)
    },
  },
  "history": {
    "texts": {
      "pleaseSelectDeviceAndTimePeriod": (options?: OptionParams): string => t("history.texts.pleaseSelectDeviceAndTimePeriod", options),
      "noTimeSeriesDataFound": (options?: OptionParams): string => t("history.texts.noTimeSeriesDataFound", options),
      "deviceSelected": (options?: OptionParams): string => t("history.texts.deviceSelected", options),
      "notEnoughDataForVisualisation": (options?: OptionParams): string => t("history.texts.notEnoughDataForVisualisation", options),
      "dragToZoomRightClickToReset": (options?: OptionParams): string => t("history.texts.dragToZoomRightClickToReset", options),
      "live": (options?: OptionParams): string => t("history.texts.live", options),
      "session": (options?: OptionParams): string => t("history.texts.session", options)
    },
    "buttons": {
      "exportAsCSV": (options?: OptionParams): string => t("history.buttons.exportAsCSV", options)
    },
  },
  "user": {
    "texts": {
      "chooseLanguage": (options?: OptionParams): string => t("user.texts.chooseLanguage", options),
      "doYouWantToLogOut": (options?: OptionParams): string => t("user.texts.doYouWantToLogOut", options),
      "enterFirstnameAndLastname": (options?: OptionParams): string => t("user.texts.enterFirstnameAndLastname", options),
      "enterEmail": (options?: OptionParams): string => t("user.texts.enterEmail", options),
      "confirmEmailTitle": (options?: OptionParams): string => t("user.texts.confirmEmailTitle", options),
      "confirmEmailMsg": (options?: OptionParams): string => t("user.texts.confirmEmailMsg", options),
      "enterPhoneNumber": (options?: OptionParams): string => t("user.texts.enterPhoneNumber", options),
      "confirmPhoneNumberTitle": (options?: OptionParams): string => t("user.texts.confirmPhoneNumberTitle", options),
      "confirmPhoneNumberMsg": (options?: OptionParams): string => t("user.texts.confirmPhoneNumberMsg", options),
      "enterOldPasswordAndNewPassword": (options?: OptionParams): string => t("user.texts.enterOldPasswordAndNewPassword", options),
      "youAreAboutToLogOut": (options?: OptionParams): string => t("user.texts.youAreAboutToLogOut", options),
      "invalidPhoneNumber": (options?: OptionParams): string => t("user.texts.invalidPhoneNumber", options),
      "invalidPhoneNumberFormat": (options?: OptionParams): string => t("user.texts.invalidPhoneNumberFormat", options),
      "invalidName": (options?: OptionParams): string => t("user.texts.invalidName", options),
      "successInNameChange": (options?: OptionParams): string => t("user.texts.successInNameChange", options),
      "successInPhoneNumberChange": (options?: OptionParams): string => t("user.texts.successInPhoneNumberChange", options),
      "successInLanguageChange": (options?: OptionParams): string => t("user.texts.successInLanguageChange", options),
      "successInPasswordChange": (options?: OptionParams): string => t("user.texts.successInPasswordChange", options),
      "logOut": (options?: OptionParams): string => t("user.texts.logOut", options),
      "incorrectCredentials": (options?: OptionParams): string => t("user.texts.incorrectCredentials", options),
      "missingAtCharacter": (options?: OptionParams): string => t("user.texts.missingAtCharacter", options),
      "missingUsername": (options?: OptionParams): string => t("user.texts.missingUsername", options),
      "missingDomain": (options?: OptionParams): string => t("user.texts.missingDomain", options),
      "codeMismatchError": (options?: OptionParams): string => t("user.texts.codeMismatchError", options),
      "unknownCodeError": (options?: OptionParams): string => t("user.texts.unknownCodeError", options)
    },
    "buttons": {
      "changeLanguage": (options?: OptionParams): string => t("user.buttons.changeLanguage", options),
      "confirmNameChange": (options?: OptionParams): string => t("user.buttons.confirmNameChange", options),
      "updateEmail": (options?: OptionParams): string => t("user.buttons.updateEmail", options),
      "updatePhoneNumber": (options?: OptionParams): string => t("user.buttons.updatePhoneNumber", options),
      "logOut": (options?: OptionParams): string => t("user.buttons.logOut", options),
      "confirmPasswordChange": (options?: OptionParams): string => t("user.buttons.confirmPasswordChange", options)
    },
    "inputs": {
      "firstname": (options?: OptionParams): string => t("user.inputs.firstname", options),
      "lastname": (options?: OptionParams): string => t("user.inputs.lastname", options),
      "email": (options?: OptionParams): string => t("user.inputs.email", options),
      "phonenumber": (options?: OptionParams): string => t("user.inputs.phonenumber", options),
      "oldPassword": (options?: OptionParams): string => t("user.inputs.oldPassword", options)
    },
    "languages": {
      "en": (options?: OptionParams): string => t("user.languages.en", options),
      "fi": (options?: OptionParams): string => t("user.languages.fi", options)
    },
  },
  "admin": {
    "texts": {
      "groups": (options?: OptionParams): string => t("admin.texts.groups", options),
      "devices": (options?: OptionParams): string => t("admin.texts.devices", options),
      "addNewDevice": (options?: OptionParams): string => t("admin.texts.addNewDevice", options),
      "addNewGroup": (options?: OptionParams): string => t("admin.texts.addNewGroup", options),
      "groupName": (options?: OptionParams): string => t("admin.texts.groupName", options),
      "failedToAddDevice": (options?: OptionParams): string => t("admin.texts.failedToAddDevice", options),
      "failedToAddGroup": (options?: OptionParams): string => t("admin.texts.failedToAddGroup", options),
      "failedToRemoveGroup": (options?: OptionParams): string => t("admin.texts.failedToRemoveGroup", options),
      "failedToRemoveDevice": (options?: OptionParams): string => t("admin.texts.failedToRemoveDevice", options),
      "newNotification": (options?: OptionParams): string => t("admin.texts.newNotification", options),
      "newBatchUpdate": (options?: OptionParams): string => t("admin.texts.newBatchUpdate", options),
      "selectOtaUpdate": (options?: OptionParams): string => t("admin.texts.selectOtaUpdate", options),
      "startUpdate": (options?: OptionParams): string => t("admin.texts.startUpdate", options),
      "startDate": (options?: OptionParams): string => t("admin.texts.startDate", options),
      "stopDate": (options?: OptionParams): string => t("admin.texts.stopDate", options),
      "progress": (options?: OptionParams): string => t("admin.texts.progress", options),
      "stopExecution": (options?: OptionParams): string => t("admin.texts.stopExecution", options),
      "stop": (options?: OptionParams): string => t("admin.texts.stop", options),
      "noFirmwareSelected": (options?: OptionParams): string => t("admin.texts.noFirmwareSelected", options),
      "noDeviceTypeSelected": (options?: OptionParams): string => t("admin.texts.noDeviceTypeSelected", options),
      "noSearchResults": (options?: OptionParams): string => t("admin.texts.noSearchResults", options),
      "selectFirmware": (options?: OptionParams): string => t("admin.texts.selectFirmware", options),
      "devicesFound": (options?: OptionParams): string => t("admin.texts.devicesFound", options),
      "maximum": (options?: OptionParams): string => t("admin.texts.maximum", options),
      "dailyMaximum": (options?: OptionParams): string => t("admin.texts.dailyMaximum", options),
      "query": (options?: OptionParams): string => t("admin.texts.query", options),
      "selectUser": (options?: OptionParams): string => t("admin.texts.selectUser", options),
      "selectEventType": (options?: OptionParams): string => t("admin.texts.selectEventType", options),
      "selectDeviceType": (options?: OptionParams): string => t("admin.texts.selectDeviceType", options),
      "searchQuery": (options?: OptionParams): string => t("admin.texts.searchQuery", options),
      "selectNotificationType": (options?: OptionParams): string => t("admin.texts.selectNotificationType", options),
      "addNotification": (options?: OptionParams): string => t("admin.texts.addNotification", options),
      "searchDevicesButton": (options?: OptionParams): string => t("admin.texts.searchDevicesButton", options),
      "failedToAddNotification": (options?: OptionParams): string => t("admin.texts.failedToAddNotification", options),
      "notificationManagement": (options?: OptionParams): string => t("admin.texts.notificationManagement", options),
      "otaManagement": (options?: OptionParams): string => t("admin.texts.otaManagement", options),
      "removeSubscription": (options?: OptionParams): string => t("admin.texts.removeSubscription", options),
      "removeGroup": (options?: OptionParams): string => t("admin.texts.removeGroup", options),
      "delete": (options?: OptionParams): string => t("admin.texts.delete", options),
      "failedToDeleteSubscription": (options?: OptionParams): string => t("admin.texts.failedToDeleteSubscription", options),
      "organisationsChildren": (options?: OptionParams): string => t("admin.texts.organisationsChildren", options),
      "organisationDetails": (options?: OptionParams): string => t("admin.texts.organisationDetails", options),
      "organisationsRoles": (options?: OptionParams): string => t("admin.texts.organisationsRoles", options),
      "noUsers": (options?: OptionParams): string => t("admin.texts.noUsers", options),
      "failedToDeleteUser": (options?: OptionParams): string => t("admin.texts.failedToDeleteUser", options),
      "failedToCreateUser": (options?: OptionParams): string => t("admin.texts.failedToCreateUser", options),
      "userAlreadyExists": (options?: OptionParams): string => t("admin.texts.userAlreadyExists", options),
      "deleteUser": (options?: OptionParams): string => t("admin.texts.deleteUser", options),
      "removeUser": (options?: OptionParams): string => t("admin.texts.removeUser", options),
      "role": (options?: OptionParams): string => t("admin.texts.role", options),
      "invalidEmailAddress": (options?: OptionParams): string => t("admin.texts.invalidEmailAddress", options),
      "roleMustBeSelected": (options?: OptionParams): string => t("admin.texts.roleMustBeSelected", options),
      "createNewUser": (options?: OptionParams): string => t("admin.texts.createNewUser", options),
      "failedToRemoveUserFromRole": (options?: OptionParams): string => t("admin.texts.failedToRemoveUserFromRole", options),
      "failedToAddUserToRole": (options?: OptionParams): string => t("admin.texts.failedToAddUserToRole", options),
      "roleOwner": (options?: OptionParams): string => t("admin.texts.roleOwner", options),
      "roleSuperAdmin": (options?: OptionParams): string => t("admin.texts.roleSuperAdmin", options),
      "roleAdmin": (options?: OptionParams): string => t("admin.texts.roleAdmin", options),
      "roleUser": (options?: OptionParams): string => t("admin.texts.roleUser", options),
      "organisation": (options?: OptionParams): string => t("admin.texts.organisation", options),
      "users": (options?: OptionParams): string => t("admin.texts.users", options),
      "roles": (options?: OptionParams): string => t("admin.texts.roles", options),
      "notifications": (options?: OptionParams): string => t("admin.texts.notifications", options),
      "ota": (options?: OptionParams): string => t("admin.texts.ota", options),
      "deviceIdentifier": (options?: OptionParams): string => t("admin.texts.deviceIdentifier", options),
      "type": (options?: OptionParams): string => t("admin.texts.type", options),
      "event": (options?: OptionParams): string => t("admin.texts.event", options),
      "user": (options?: OptionParams): string => t("admin.texts.user", options),
      "noNotificationsAvailable": (options?: OptionParams): string => t("admin.texts.noNotificationsAvailable", options),
      "noUpdateExecutionsAvailable": (options?: OptionParams): string => t("admin.texts.noUpdateExecutionsAvailable", options),
      "notificationFromUser": (options?: OptionParams): string => t("admin.texts.notificationFromUser", options),
      "failedToDeleteOrganization": (options?: OptionParams): string => t("admin.texts.failedToDeleteOrganization", options),
      "failedToDeleteOrganizationHasChildren": (options?: OptionParams): string => t("admin.texts.failedToDeleteOrganizationHasChildren", options),
      "deleteOrganization": (options?: OptionParams): string => t("admin.texts.deleteOrganization", options),
      "deleteOrganizationConfirmation": (options?: OptionParams): string => t("admin.texts.deleteOrganizationConfirmation", options),
      "deleteDevice": (options?: OptionParams): string => t("admin.texts.deleteDevice", options),
      "deleteDeviceConfirmation": (options?: OptionParams): string => t("admin.texts.deleteDeviceConfirmation", options),
      "addDeviceError": (options?: OptionParams): string => t("admin.texts.addDeviceError", options)
    },
    "notificationErrors": {
      "missingEmailAddress": (options?: OptionParams): string => t("admin.notificationErrors.missingEmailAddress", options),
      "missingPhoneNumber": (options?: OptionParams): string => t("admin.notificationErrors.missingPhoneNumber", options),
      "missingPushToken": (options?: OptionParams): string => t("admin.notificationErrors.missingPushToken", options),
      "unknownError": (options?: OptionParams): string => t("admin.notificationErrors.unknownError", options)
    },
    "buttons": {
      "addDevice": (options?: OptionParams): string => t("admin.buttons.addDevice", options),
      "addSubGroup": (options?: OptionParams): string => t("admin.buttons.addSubGroup", options),
      "removeGroup": (options?: OptionParams): string => t("admin.buttons.removeGroup", options)
    },
    "inputs": {
      "user": (options?: OptionParams): string => t("admin.inputs.user", options),
      "role": (options?: OptionParams): string => t("admin.inputs.role", options),
      "eventType": (options?: OptionParams): string => t("admin.inputs.eventType", options),
      "emailAlert": (options?: OptionParams): string => t("admin.inputs.emailAlert", options),
      "textMessageAlert": (options?: OptionParams): string => t("admin.inputs.textMessageAlert", options),
      "pushAlert": (options?: OptionParams): string => t("admin.inputs.pushAlert", options),
      "organisationName": (options?: OptionParams): string => t("admin.inputs.organisationName", options),
      "selectChild": (options?: OptionParams): string => t("admin.inputs.selectChild", options),
      "newOrganisationsName": (options?: OptionParams): string => t("admin.inputs.newOrganisationsName", options),
      "noRoleSelected": (options?: OptionParams): string => t("admin.inputs.noRoleSelected", options)
    },
  },
  "events": {
    "data": {
      "severity": (options?: OptionParams): string => t("events.data.severity", options),
      "sensor": (options?: OptionParams): string => t("events.data.sensor", options),
      "sensorValue": (options?: OptionParams): string => t("events.data.sensorValue", options),
      "metadata": (options?: OptionParams): string => t("events.data.metadata", options),
      "state": (options?: OptionParams): string => t("events.data.state", options),
      "type": (options?: OptionParams): string => t("events.data.type", options),
      "date": (options?: OptionParams): string => t("events.data.date", options)
    },
    "texts": {
      "events": (options?: OptionParams): string => t("events.texts.events", options),
      "noEventsFound": (options?: OptionParams): string => t("events.texts.noEventsFound", options),
      "selectDevice": (options?: OptionParams): string => t("events.texts.selectDevice", options),
      "invalidMeasurementData": (options?: OptionParams): string => t("events.texts.invalidMeasurementData", options)
    },
    "buttons": {
      "acknowledge": (options?: OptionParams): string => t("events.buttons.acknowledge", options)
    },
  },
  "analytics": {
    "inputs": {
      "onlySelectedDevice": (options?: OptionParams): string => t("analytics.inputs.onlySelectedDevice", options),
      "period": (options?: OptionParams): string => t("analytics.inputs.period", options),
      "today": (options?: OptionParams): string => t("analytics.inputs.today", options),
      "yesterday": (options?: OptionParams): string => t("analytics.inputs.yesterday", options),
      "lastXdays": (options?: OptionParams): string => t("analytics.inputs.lastXdays", options)
    },
    "texts": {
      "dashboardLoadError": (options?: OptionParams): string => t("analytics.texts.dashboardLoadError", options),
      "dashboardCreateError": (options?: OptionParams): string => t("analytics.texts.dashboardCreateError", options)
    },
  },
  "common": {
    "texts": {
      "username": (options?: OptionParams): string => t("common.texts.username", options),
      "status": (options?: OptionParams): string => t("common.texts.status", options),
      "history": (options?: OptionParams): string => t("common.texts.history", options),
      "analytics": (options?: OptionParams): string => t("common.texts.analytics", options),
      "events": (options?: OptionParams): string => t("common.texts.events", options),
      "user": (options?: OptionParams): string => t("common.texts.user", options),
      "admin": (options?: OptionParams): string => t("common.texts.admin", options),
      "errorOccurred": (options?: OptionParams): string => t("common.texts.errorOccurred", options),
      "successOccurred": (options?: OptionParams): string => t("common.texts.successOccurred", options),
      "notAvailable": (options?: OptionParams): string => t("common.texts.notAvailable", options),
      "N_A": (options?: OptionParams): string => t("common.texts.N_A", options),
      "sort": (options?: OptionParams): string => t("common.texts.sort", options),
      "error": (options?: OptionParams): string => t("common.texts.error", options),
      "success": (options?: OptionParams): string => t("common.texts.success", options),
      "noDataAvailable": (options?: OptionParams): string => t("common.texts.noDataAvailable", options),
      "passwordsNotMatching": (options?: OptionParams): string => t("common.texts.passwordsNotMatching", options),
      "tooManyAttempts": (options?: OptionParams): string => t("common.texts.tooManyAttempts", options),
      "networkError": (options?: OptionParams): string => t("common.texts.networkError", options),
      "userSessionExpired": (options?: OptionParams): string => t("common.texts.userSessionExpired", options),
      "unableToPerformAction": (options?: OptionParams): string => t("common.texts.unableToPerformAction", options),
      "passwordMustHaveNumbers": (options?: OptionParams): string => t("common.texts.passwordMustHaveNumbers", options),
      "passwordMustHaveLowercaseCharacters": (options?: OptionParams): string => t("common.texts.passwordMustHaveLowercaseCharacters", options),
      "passwordMustBeLongEnough": (options?: OptionParams): string => t("common.texts.passwordMustBeLongEnough", options),
      "passwordInvalid": (options?: OptionParams): string => t("common.texts.passwordInvalid", options),
      "of": (options?: OptionParams): string => t("common.texts.of", options),
      "showColumns": (options?: OptionParams): string => t("common.texts.showColumns", options),
      "filters": (options?: OptionParams): string => t("common.texts.filters", options),
      "measurements": (options?: OptionParams): string => t("common.texts.measurements", options)
    },
    "inputs": {
      "from": (options?: OptionParams): string => t("common.inputs.from", options),
      "to": (options?: OptionParams): string => t("common.inputs.to", options),
      "custom": (options?: OptionParams): string => t("common.inputs.custom", options),
      "none": (options?: OptionParams): string => t("common.inputs.none", options),
      "newPassword": (options?: OptionParams): string => t("common.inputs.newPassword", options),
      "email": (options?: OptionParams): string => t("common.inputs.email", options),
      "confirmNewPassword": (options?: OptionParams): string => t("common.inputs.confirmNewPassword", options),
      "search": (options?: OptionParams): string => t("common.inputs.search", options),
      "displayedRows": (options?: OptionParams): string => t("common.inputs.displayedRows", options),
      "rowsPerPage": (options?: OptionParams): string => t("common.inputs.rowsPerPage", options)
    },
    "data": {
      "timestamp": (options?: OptionParams): string => t("common.data.timestamp", options),
      "description": (options?: OptionParams): string => t("common.data.description", options),
      "temperature": (options?: OptionParams): string => t("common.data.temperature", options),
      "humidity": (options?: OptionParams): string => t("common.data.humidity", options),
      "pressure": (options?: OptionParams): string => t("common.data.pressure", options),
      "batteryLevel": (options?: OptionParams): string => t("common.data.batteryLevel", options),
      "batteryVoltage": (options?: OptionParams): string => t("common.data.batteryVoltage", options),
      "latitude": (options?: OptionParams): string => t("common.data.latitude", options),
      "longitude": (options?: OptionParams): string => t("common.data.longitude", options),
      "speed": (options?: OptionParams): string => t("common.data.speed", options),
      "deviceId": (options?: OptionParams): string => t("common.data.deviceId", options),
      "displayName": (options?: OptionParams): string => t("common.data.displayName", options),
      "rssi": (options?: OptionParams): string => t("common.data.rssi", options),
      "heapSize": (options?: OptionParams): string => t("common.data.heapSize", options),
      "sessionId": (options?: OptionParams): string => t("common.data.sessionId", options),
      "type": (options?: OptionParams): string => t("common.data.type", options)
    },
    "buttons": {
      "search": (options?: OptionParams): string => t("common.buttons.search", options),
      "ok": (options?: OptionParams): string => t("common.buttons.ok", options),
      "accept": (options?: OptionParams): string => t("common.buttons.accept", options),
      "create": (options?: OptionParams): string => t("common.buttons.create", options),
      "delete": (options?: OptionParams): string => t("common.buttons.delete", options),
      "remove": (options?: OptionParams): string => t("common.buttons.remove", options),
      "add": (options?: OptionParams): string => t("common.buttons.add", options),
      "refresh": (options?: OptionParams): string => t("common.buttons.refresh", options),
      "save": (options?: OptionParams): string => t("common.buttons.save", options),
      "apply": (options?: OptionParams): string => t("common.buttons.apply", options),
      "close": (options?: OptionParams): string => t("common.buttons.close", options),
      "confirm": (options?: OptionParams): string => t("common.buttons.confirm", options),
      "cancel": (options?: OptionParams): string => t("common.buttons.cancel", options),
      "settings": (options?: OptionParams): string => t("common.buttons.settings", options),
      "history": (options?: OptionParams): string => t("common.buttons.history", options),
      "nextPage": (options?: OptionParams): string => t("common.buttons.nextPage", options),
      "previousPage": (options?: OptionParams): string => t("common.buttons.previousPage", options),
      "print": (options?: OptionParams): string => t("common.buttons.print", options),
      "downloadCsv": (options?: OptionParams): string => t("common.buttons.downloadCsv", options),
      "download": (options?: OptionParams): string => t("common.buttons.download", options),
      "filterTable": (options?: OptionParams): string => t("common.buttons.filterTable", options),
      "viewColumns": (options?: OptionParams): string => t("common.buttons.viewColumns", options),
      "reset": (options?: OptionParams): string => t("common.buttons.reset", options)
    },
  },
};
