/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { AWSThingGroup } from "../device/AWSThingGroup";
import { DeviceGroup } from "../device/DeviceGroup";
import { Service } from "./AppSyncClientProvider";
import { Device } from "../device/Device";
import { AppSyncClientFactory } from "./AppSyncClientFactory";
import { AuthWrapper } from "../auth/AuthWrapper";
import { isDefined } from "../../common/isDefined";
import { throwGQLError } from "../private-utils/throwGQLError";
import { UrlsQsEmbedGenerateDocument } from "../../generated/gqlStats";
import { DeviceGroupsCreateDocument, DeviceGroupsDevicesListDocument, DeviceGroupsGetByOrgDocument, DeviceGroupsGetDocument, DeviceGroupsListDocument, DevicesDeviceGroupsListDocument, DevicesEncryptDocument, DevicesGetDocument, DevicesSearchDocument, } from "../../generated/gqlDevice";
import { AuthListener } from "../auth/AuthListener";
import { AsyncCache } from "../private-utils/AsyncCache";
import { EntityRelationCache } from "../private-utils/EntityRelationCache";
import { prefixlessId } from "../organization/Utils";
import { Auth } from "@aws-amplify/auth";
import { FingerprintUrlGetDocument } from "../../generated/gqlMeasurement";
export function narrowDownAttributeTypes(attrs) {
    return attrs.map(({ key, value }) => ({ key, value }));
}
export class AWSBackend {
    constructor(deviceFactory) {
        this.deviceFactory = deviceFactory;
        this.entityRelationCache = new EntityRelationCache();
        // TODO: it might be smarter to cache devices to DeviceFactory
        this.deviceCache = new AsyncCache();
        this.groupCache = new AsyncCache();
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.groupCache.clear();
                this.deviceCache.clear();
                this.entityRelationCache.clear();
                this.rootGroupIds = undefined;
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
        this.fragmentIntoDeviceGroup = async (fragment) => {
            return new AWSThingGroup(this, {
                groupId: fragment.id,
                attributes: narrowDownAttributeTypes(fragment.attr),
            });
        };
        this.deviceFragmentIntoDevice = async (fragment) => {
            const device = this.deviceFactory.createDevice(this, fragment.type, {
                deviceId: fragment.id,
                attributes: narrowDownAttributeTypes(fragment.attr),
            });
            if (device) {
                // While this is pretty bad for performance, it was already done everywhere anyways.
                // It should actually probably be part of the factory instead.
                await device.init();
                return device;
            }
        };
    }
    static async getOrganization(group) {
        let org = group ? group.getOrganization() : undefined;
        if (!org || org.length === 0) {
            const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
            org = claims === null || claims === void 0 ? void 0 : claims.homeOrganizationId;
        }
        if (!org) {
            throw new Error("No organization available");
        }
        return org;
    }
    async getQsEmbedUrl(openIdToken, dashboardId) {
        var _a;
        try {
            const user = await Auth.currentAuthenticatedUser();
            const variables = {
                request: {
                    dashboardId,
                    emailAddress: user.username,
                    openIdToken,
                    sessionName: user.username,
                    undoRedoDisabled: true,
                    resetDisabled: true,
                    sessionLifetimeInMinutes: 600,
                },
            };
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.STATS);
            const embedUrlResponse = await client.query(UrlsQsEmbedGenerateDocument, variables);
            return (_a = embedUrlResponse.data.urlsQsEmbedGenerate) === null || _a === void 0 ? void 0 : _a.embedUrl;
        }
        catch (error) {
            console.error("getQsEmbedUrl: ", error);
        }
    }
    async getFingerprintDownloadUrl(fingerprint) {
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.MEASUREMENT);
            const response = await client.query(FingerprintUrlGetDocument, {
                fingerprint,
            });
            return response.data.fingerprintUrlGet;
        }
        catch (error) {
            console.error("getFingerprintDownloadUrl: ", error);
        }
    }
    async getDevice(id, refreshCache) {
        const fetchDevice = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                const response = await client.query(DevicesGetDocument, {
                    deviceId: id,
                });
                if (response.data.devicesGet) {
                    return this.deviceFragmentIntoDevice(response.data.devicesGet);
                }
            }
            catch (err) {
                console.error("Failed to fetch device.", err);
            }
        };
        if (refreshCache)
            await this.deviceCache.delete(id);
        return this.deviceCache.get(id, fetchDevice);
    }
    async getDeviceGroup(id, refreshCache) {
        const fetchDeviceGroup = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.query(DeviceGroupsGetDocument, {
                groupId: id,
            });
            if (response.data.deviceGroupsGet) {
                return this.fragmentIntoDeviceGroup(response.data.deviceGroupsGet);
            }
        };
        if (refreshCache)
            await this.groupCache.delete(id);
        return this.groupCache.get(id, fetchDeviceGroup);
    }
    async getOrganizationDevices(organizationId) {
        const query = `attributes.organization: "${organizationId}"`;
        return await this.searchDevices(query);
    }
    async findDeviceGroupByOrganization(organizationId) {
        var _a;
        const fetchDeviceGroup = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.query(DeviceGroupsGetByOrgDocument, {
                organizationId: organizationId,
            });
            if (response.data.deviceGroupsGetByOrg) {
                return this.fragmentIntoDeviceGroup(response.data.deviceGroupsGetByOrg);
            }
        };
        const group = (_a = (await this.groupCache.find((iter) => {
            return iter.getOrganization() === organizationId;
        }))) !== null && _a !== void 0 ? _a : (await fetchDeviceGroup());
        if (group) {
            this.groupCache.set(group.getId(), group);
        }
        return group;
    }
    async findDeviceGroupByName(name, organizationId) {
        const orgString = organizationId ? prefixlessId(organizationId) : organizationId;
        const selectByOrganization = (group) => {
            if (!orgString)
                return true;
            const groupOrg = group.getOrganization();
            if (!groupOrg)
                return true;
            return orgString.startsWith(prefixlessId(groupOrg));
        };
        const roots = await this.getRootDeviceGroups();
        let selected = roots.filter(selectByOrganization);
        while (selected.length > 0) {
            const match = selected.find((group) => group.getLabel() === name);
            if (match)
                return match;
            selected = (await Promise.all(selected.map((group) => group.getGroups()))).flat();
        }
    }
    async getRootDeviceGroups() {
        if (this.rootGroupIds) {
            const groups = await Promise.all(this.rootGroupIds.map((id) => this.getDeviceGroup(id)));
            const filteredGroups = groups.filter(isDefined);
            if (groups.length !== filteredGroups.length) {
                console.error("Invalid root group id cache state, adjusting");
                this.rootGroupIds = filteredGroups.map((group) => group.getId());
            }
            return filteredGroups;
        }
        const groups = await this.getDeviceGroups();
        this.rootGroupIds = groups.map((group) => group.getId());
        return groups;
    }
    async getAllDeviceGroups() {
        return this.getDeviceGroups({ recursive: true });
    }
    async getDeviceGroups({ parent, recursive = false, } = {}) {
        var _a, _b, _c, _d;
        try {
            let nextToken;
            let groupFragments = [];
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            do {
                const groupListResponse = await client.query(DeviceGroupsListDocument, {
                    recursive,
                    includeAttributes: true,
                    parentGroupId: parent === null || parent === void 0 ? void 0 : parent.getId(),
                    nextToken,
                });
                nextToken = (_b = (_a = groupListResponse.data) === null || _a === void 0 ? void 0 : _a.deviceGroupsList) === null || _b === void 0 ? void 0 : _b.nextToken;
                groupFragments = groupFragments.concat((_d = (_c = groupListResponse.data.deviceGroupsList) === null || _c === void 0 ? void 0 : _c.deviceGroups) !== null && _d !== void 0 ? _d : []);
            } while (nextToken);
            const groups = await this.cacheFragments(this.groupCache, groupFragments, this.fragmentIntoDeviceGroup);
            if (parent && !recursive) {
                groups.forEach((group) => this.entityRelationCache.link(parent, group, { parentId: parent.getId() }));
            }
            else if (recursive) {
                console.warn("Cannot establish parent-child relationship between device groups in recursive calls");
            }
            return groups;
        }
        catch (error) {
            console.error("getDeviceGroups", error);
            return [];
        }
    }
    async createDeviceGroup(params) {
        var _a, _b, _c, _d;
        if (params.parentGroup && !AWSThingGroup.instanceOf(params.parentGroup)) {
            throw new Error("Invalid DeviceGroup implementation for parent group");
        }
        const org = (_a = params.organizationId) !== null && _a !== void 0 ? _a : (await AWSBackend.getOrganization(params.parentGroup));
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const groupResponse = await client.mutate(DeviceGroupsCreateDocument, {
            groupId: params.displayName,
            parentGroupId: (_b = params.parentGroup) === null || _b === void 0 ? void 0 : _b.getId(),
            organizationId: org,
        });
        if (!((_c = groupResponse.data) === null || _c === void 0 ? void 0 : _c.deviceGroupsCreate)) {
            console.error("Failed to create group, backend response empty: " + JSON.stringify(groupResponse.errors));
            throwGQLError(groupResponse);
        }
        const newGroup = await this.fragmentIntoDeviceGroup(groupResponse.data.deviceGroupsCreate);
        this.groupCache.set(newGroup.getId(), newGroup);
        if (!params.parentGroup) {
            this.rootGroupIds = ((_d = this.rootGroupIds) !== null && _d !== void 0 ? _d : []).concat(newGroup.getId());
        }
        else {
            this.entityRelationCache.link(params.parentGroup, newGroup, { parentId: params.parentGroup.getId() });
        }
    }
    async searchDevices(query) {
        var _a, _b, _c, _d, _e;
        // TODO: should we have query-specific cache?
        console.info(`Searching for devices with query ${query}`);
        try {
            const deviceFragments = [];
            let nextToken;
            const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            do {
                const searchDevicesResponse = await appSyncClient.query(DevicesSearchDocument, {
                    query,
                    nextToken,
                });
                nextToken = (_b = (_a = searchDevicesResponse.data) === null || _a === void 0 ? void 0 : _a.devicesSearch) === null || _b === void 0 ? void 0 : _b.nextToken;
                deviceFragments.push(...((_e = (_d = (_c = searchDevicesResponse.data) === null || _c === void 0 ? void 0 : _c.devicesSearch) === null || _d === void 0 ? void 0 : _d.devices) !== null && _e !== void 0 ? _e : []));
            } while (nextToken);
            return this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
        }
        catch (error) {
            console.error("searchDevices", error);
            return [];
        }
    }
    async getDeviceGroupDevices(group) {
        var _a, _b, _c;
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            let nextToken;
            const deviceFragments = [];
            do {
                const deviceIdListResponse = await client.query(DeviceGroupsDevicesListDocument, {
                    groupId: group.getId(),
                    nextToken,
                });
                nextToken = (_a = deviceIdListResponse.data.deviceGroupsDevicesList) === null || _a === void 0 ? void 0 : _a.nextToken;
                deviceFragments.push(...((_c = (_b = deviceIdListResponse.data.deviceGroupsDevicesList) === null || _b === void 0 ? void 0 : _b.devices) !== null && _c !== void 0 ? _c : []));
            } while (nextToken);
            // no need to generate group<->device links here, since the deviceFragmentIntoDevice call already generates the links
            const devices = await this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
            devices.forEach((device) => this.entityRelationCache.link(device, group));
            return devices;
        }
        catch (error) {
            console.error("getDeviceGroupDevices", error);
            return [];
        }
    }
    async encryptWithDeviceCertificate(message, certificateArn) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesEncryptDocument, { certificateArn, message });
        if (!response.data.devicesEncrypt) {
            throwGQLError(response, "Encryption failed");
        }
        return response.data.devicesEncrypt;
    }
    /////
    /// AWSBackend specific public methods
    /////
    async linkDeviceGroupsForDevice(device) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        let nextToken;
        const groupFragments = [];
        try {
            do {
                const response = await client.query(DevicesDeviceGroupsListDocument, {
                    deviceId: device.getId(),
                    nextToken,
                });
                nextToken = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesDeviceGroupsList) === null || _b === void 0 ? void 0 : _b.nextToken;
                groupFragments.push(...((_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.devicesDeviceGroupsList) === null || _d === void 0 ? void 0 : _d.deviceGroups) !== null && _e !== void 0 ? _e : []));
            } while (nextToken);
            const groups = (await this.cacheFragments(this.groupCache, groupFragments, this.fragmentIntoDeviceGroup));
            groups.forEach((group) => this.entityRelationCache.link(device, group));
        }
        catch (error) {
            console.error("linkDeviceGroupsForDevice", error);
        }
    }
    async removeLocal(thing) {
        if (Device.instanceOf(thing)) {
            await this.deviceCache.delete(thing.getId());
        }
        else if (DeviceGroup.instanceOf(DeviceGroup)) {
            await this.groupCache.delete(thing.getId());
        }
        this.entityRelationCache.remove(thing);
    }
    getSupportedDeviceTypes() {
        return this.deviceFactory.listDeviceTypes();
    }
    /**
     * Takes a collection of fragments, which are either
     * - if id matches something in cache, replaced with the cached entity
     * - converted into the desired entity, and then cached
     *
     * @param cache
     *    an AsyncCache into which to store the converted fragments
     * @param fragments
     *    list of fragments to go through
     * @param fragmentConverter
     *    method for converting fragment into the desired entity type
     * @private
     */
    async cacheFragments(cache, fragments, fragmentConverter) {
        const results = await Promise.all(fragments.map((fragment) => cache.get(fragment.id, () => fragmentConverter(fragment))));
        return results.filter(isDefined);
    }
}
