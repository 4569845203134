/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __rest } from "tslib";
import { Service } from "../backend/AppSyncClientProvider";
import { EventsFeedDocument } from "../../generated/gqlEvents";
import { AbstractReceiverSubscriptionManager } from "../subscriptions";
import { AppSyncClientFactory } from "../backend";
export class EventsSubscriptionManager extends AbstractReceiverSubscriptionManager {
    constructor() {
        super(() => AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS), EventsFeedDocument);
    }
    static get instance() {
        return this.__instance;
    }
    async handleSubscriptionResult(result, listeners) {
        var _a;
        if ((_a = result.eventsFeed) === null || _a === void 0 ? void 0 : _a.item) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _b = result.eventsFeed.item, { __typename } = _b, event = __rest(_b, ["__typename"]);
            listeners.forEach((listener) => {
                if (listener.getId() === event.deviceId) {
                    listener.onEvent(event);
                }
            });
        }
    }
}
EventsSubscriptionManager.__instance = new EventsSubscriptionManager();
