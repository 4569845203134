/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { DevicesStatesUpdateFeedDocument } from "../../generated/gqlDevice";
import { AbstractReceiverSubscriptionManager } from "../subscriptions";
import { AppSyncClientFactory } from "../backend";
export class ShadowSubscriptionManager extends AbstractReceiverSubscriptionManager {
    constructor() {
        super(() => AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE), DevicesStatesUpdateFeedDocument);
    }
    static get instance() {
        return this.__instance;
    }
    async handleSubscriptionResult(result, listeners) {
        var _a, _b;
        const { deviceId, reported, desired, timestamp, version, connectionState } = (_b = (_a = result === null || result === void 0 ? void 0 : result.devicesStatesUpdateFeed) === null || _a === void 0 ? void 0 : _a.item) !== null && _b !== void 0 ? _b : {};
        const reportedProps = reported ? JSON.parse(reported) : undefined;
        const desiredProps = desired ? JSON.parse(desired) : undefined;
        listeners.forEach((listener) => {
            if (listener.getId() === deviceId) {
                listener.onState(timestamp, version, reportedProps, desiredProps, connectionState);
            }
        });
    }
}
ShadowSubscriptionManager.__instance = new ShadowSubscriptionManager();
