/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export function isTimePeriod(value) {
    return !!value.startTimestamp && !!value.endTimestamp;
}
export function timePeriodIsEmpty(timePeriod) {
    return timePeriod.startTimestamp == null && timePeriod.endTimestamp == null;
}
export function timePeriodIsValid(timePeriod) {
    const start = timePeriod.startTimestamp, end = timePeriod.endTimestamp;
    return !!start && !!end && start <= end;
}
