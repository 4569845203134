/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { ReferenceHW } from "../ReferenceHW/ReferenceHW";
import { SuperHWState } from "./SuperHWState";
import { SuperHWStateProperties } from "./SuperHWStateProperties";
import { iotItem24Px } from "../../generated/SadeDataIconModel";
export class SuperHW extends ReferenceHW {
    constructor(backend, params) {
        super(SuperHW.type, backend, params);
    }
    static instanceOf(device) {
        return device instanceof SuperHW;
    }
    getIcon() {
        return iotItem24Px.data;
    }
    createState(timestamp, version, reported, desired, connectionState) {
        return new SuperHWState(this.getId(), new SuperHWStateProperties(reported !== null && reported !== void 0 ? reported : {}), new SuperHWStateProperties(desired !== null && desired !== void 0 ? desired : {}), timestamp, version, connectionState);
    }
    toggleLed() {
        if (this.state) {
            this.state.ledEnabled = !this.state.ledEnabled;
        }
    }
}
SuperHW.type = "Super";
