/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Grid, List, TextField } from "@material-ui/core";
import React from "react";
import SettingsListItem from "../settings-list-item";
import SettingsControls from "../settings-page-general/settings-controls";
import { Attribute, Device } from "@sade/data-access";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  selectedDevice: Device;
  onCloseSettings: () => void;
}

interface State {
  attributes: Attribute[];
  changesMade: boolean;
}

const EDITABLE_ATTRIBUTE_PREFIX = "w_";

export default class SettingsPageAttributes extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      attributes: props.selectedDevice.getAttributes(),
      changesMade: false,
    };
  }

  private handleCancel = (): void => {
    this.props.onCloseSettings();
  };

  private handleSave = (): void => {
    const attributes = this.state.attributes.filter((item) => item.key.startsWith(EDITABLE_ATTRIBUTE_PREFIX));
    this.props.selectedDevice.updateAttributes(attributes);
    this.setState({ changesMade: false });
  };

  private attributeChanged(attribute: Attribute): void {
    const newAttributes = this.state.attributes.map((item) => {
      if (item.key === attribute.key) {
        return attribute;
      }
      return item;
    });
    this.setState({ attributes: newAttributes, changesMade: true });
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} direction="column" spacing={2}>
        <Grid item={true}>
          <List>
            {this.state.attributes ? (
              this.state.attributes.map((item) => {
                if (item.key.startsWith(EDITABLE_ATTRIBUTE_PREFIX)) {
                  return (
                    <SettingsListItem key={item.key} label={item.key}>
                      <TextField
                        value={item.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                          this.attributeChanged({
                            key: item.key,
                            value: event.target.value,
                          })
                        }
                      />
                    </SettingsListItem>
                  );
                } else {
                  const translationFunc =
                    translations.deviceSettings.texts[item.key as keyof typeof translations.deviceSettings.texts];
                  if (!translationFunc) {
                    console.warn(`Missing translation for attribute ${item.key}`);
                  }

                  return (
                    <SettingsListItem key={item.key} label={translationFunc ? translationFunc() : item.key}>
                      {item.value}
                    </SettingsListItem>
                  );
                }
              })
            ) : (
              <p>{translations.deviceSettings.texts.noAttributesDefined()}</p>
            )}
          </List>
        </Grid>
        <Grid item>
          <SettingsControls
            changesMade={this.state.changesMade}
            submitButtonLabel={translations.common.buttons.save()}
            cancelButtonLabel={translations.common.buttons.close()}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
          />
        </Grid>
      </Grid>
    );
  }
}
