/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export class TypedAppSyncClient {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(client) {
        this.client = client;
    }
    async query(document, variables, options = {}) {
        return this.client.query(Object.assign({ query: document, variables }, options));
    }
    async mutate(document, variables, options = {}) {
        const result = await this.client.mutate(Object.assign({ mutation: document, variables }, options));
        return result;
    }
    subscribe(document, variables, options = {}) {
        return this.client.subscribe(Object.assign({ query: document, variables }, options));
    }
}
