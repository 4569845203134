/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { DeviceState } from "../../data/device/DeviceState";
export class RuuviTagHWState extends DeviceState {
    get displayName() {
        var _a;
        return (_a = this.desired.displayName) !== null && _a !== void 0 ? _a : "";
    }
    set displayName(name) {
        this.changedValues.displayName = name;
    }
    deltaExists() {
        return false;
    }
    revert() {
        this.changedValues = {};
    }
    get firmwareVersion() {
        return null;
    }
    static instanceOf(state) {
        return state instanceof RuuviTagHWState;
    }
}
