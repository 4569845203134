/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Button from "@material-ui/core/Button";
import React, { Component, Fragment, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../../types/routerprops";
import { ClientProperties, StatusEntry } from "../../../utils/ClientProperties";
import { BackendFactory, Data, Device } from "@sade/data-access";
import DeviceSettingsButton from "../../device-settings/device-settings-button";
import { Box, Grid, IconButton } from "@material-ui/core";
import Paths from "../../Paths";
import CloseIcon from "../../../assets/baseline-close-black-24px.svg";
import DeviceNavigationCache from "../../../utils/DeviceNavigationCache";
import { DeviceChangeType, idFromProps } from "../../../utils/NavigationUtils";
import { translations } from "../../../generated/translationHelper";
import { getDisplayName } from "../../../utils/GetDisplayName";

interface Props extends RouteComponentProps<DevicePathRouterProps> {
  popupData: Data;
  onClose: () => void;
}

interface State {
  device?: Device;
}

class StatusPopup extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private static convertToLocalTime(timestamp: number): string {
    // TODO: maybe this should use TimeUtils?
    return new Date(timestamp).toLocaleString();
  }

  public async componentDidMount(): Promise<void> {
    const deviceId = idFromProps(this.props);

    if (deviceId) {
      this.setState({ device: await BackendFactory.getBackend().getDevice(deviceId) });
    }
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const deviceChange = DeviceNavigationCache.getInstance().predictDeviceChange(this.props, prevProps);

    const deviceId = idFromProps(this.props);

    if (deviceChange === DeviceChangeType.ChangedToNew && deviceId) {
      this.setState({ device: await BackendFactory.getBackend().getDevice(deviceId) });
    } else if (deviceChange === DeviceChangeType.ChangedToNone) {
      this.setState({ device: undefined });
    }
  }

  private openHistory = (): void => {
    DeviceNavigationCache.getInstance().navigateToPath(this.props, Paths.HISTORY);
  };

  private getTableBody(): JSX.Element[] {
    const entries: StatusEntry[] = ClientProperties.getOverlayPopupEntries(this.props.popupData);
    return entries.map((e: StatusEntry) => {
      return (
        <tr key={e.title}>
          <td>
            <b>{e.title}</b>
          </td>
          <td>{e.value}</td>
        </tr>
      );
    });
  }

  private renderStatusBody(): JSX.Element {
    return (
      <div className="status-container">
        <p>{StatusPopup.convertToLocalTime(this.props.popupData.timestamp)}</p>
        <table>
          <tbody>{this.getTableBody()}</tbody>
        </table>
        {this.renderButtons()}
      </div>
    );
  }

  private renderButtons(): JSX.Element {
    return (
      <Box mb={2} paddingTop={2}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <DeviceSettingsButton device={this.state.device} isIcon={false} data-testid="open-settings" />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={this.openHistory} data-testid="history-button">
              {translations.common.buttons.history()}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  public render(): ReactNode {
    if (!this.state.device) return null;
    return (
      <Fragment>
        <div className="status-popup-container" data-testid="status-popup">
          <Grid container justifyContent="center">
            <Grid item container xs>
              <Grid container alignItems="center">
                <Grid item container justifyContent="flex-end" xs>
                  <span
                    className="device-icon"
                    dangerouslySetInnerHTML={{ __html: this.state.device.getIcon() }}
                  ></span>
                </Grid>
                <Grid item container justifyContent="center" xs={8}>
                  {getDisplayName(this.state.device)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={2}>
              <IconButton onClick={this.props.onClose}>
                <img alt="Close" src={CloseIcon} />
              </IconButton>
            </Grid>
          </Grid>
          <div className="status-popup-body">{this.renderStatusBody()}</div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(StatusPopup);
