/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { FormControl, FormControlLabel, FormGroup } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, { Component } from "react";
import { translations } from "../../generated/translationHelper";

interface Props {
  liveData: boolean;
  onLiveDataToggle: (status: boolean) => void;
}

interface State {}

export default class LiveControl extends Component<Props, State> {
  private onChange = (event: React.FormEvent<HTMLInputElement>): void => {
    this.props.onLiveDataToggle(event.currentTarget.checked);
  };

  public render(): JSX.Element {
    return (
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={this.props.liveData} onChange={this.onChange} color="primary" />}
            label={translations.history.texts.live()}
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
    );
  }
}
