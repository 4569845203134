/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import React, { Component, ReactNode } from "react";
import { AuthWrapper, BackendFactory, Device, DeviceGroup, Organization } from "@sade/data-access";
import SearchBar from "../ui/search-bar";
import ArrowBack from "../../assets/arrow-back-24px.svg";
import ArrowForward from "../../assets/arrow-forward-24px.svg";
import { getCaseInsensitiveSearchFilter } from "../device-browsing/helpers/search-filter";
import Loader from "../ui/loader";
import { translations } from "../../generated/translationHelper";
import { getDisplayName } from "../../utils/GetDisplayName";
import OrganizationSelector from "../ui/organization-selector";
import DrawerDeviceList from "./components/drawer-device-list";

interface Props {
  selectedDeviceId?: string;
  onDeviceSelect?: (device?: Device) => void;
  onOrganizationSelect?: (organization?: Organization) => void;
}

interface State {
  searchFilter: string;
  drawerOpen: boolean;
  groups?: DeviceGroup[];
  selectedDisplayName?: string;
  rootOrganization?: Organization;
  currentOrganization?: Organization;
  currentDevices?: Device[];
  loading: boolean;
  customQuery: boolean;
}

export default class DeviceDrawer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      searchFilter: "",
      drawerOpen: false,
      loading: true,
      customQuery: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const promiseFactories: Array<() => Promise<void>> = [(): Promise<void> => this.fetchGroups()];

    const deviceId = this.props.selectedDeviceId;

    const organizationBackend = BackendFactory.getOrganizationBackend();
    const homeOrg = await organizationBackend.getCurrentHomeOrganization();

    if (homeOrg) {
      this.setState({ currentOrganization: homeOrg, rootOrganization: homeOrg });
    }

    this.fetchOrgDevices();

    if (!deviceId) {
      this.setState({ drawerOpen: true });
    } else {
      promiseFactories.push(async (): Promise<void> => this.fetchDisplayName(deviceId));
    }

    if (await AuthWrapper.isCurrentUserAuthenticated()) {
      await Promise.all(promiseFactories.map((factory) => factory()));
    }
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    const currentId = this.props.selectedDeviceId,
      oldId = prevProps.selectedDeviceId;

    if (currentId && currentId !== oldId) {
      await this.fetchDisplayName(currentId);
      this.setState({ drawerOpen: false });
    } else if (!currentId && currentId !== oldId) {
      this.setState({
        selectedDisplayName: undefined,
      });
    }
  }

  private async fetchGroups(): Promise<void> {
    const groups: DeviceGroup[] = await BackendFactory.getBackend().getRootDeviceGroups();
    console.log(`Found ${groups.length} root groups`);
    this.setState({ groups });
  }

  private async fetchOrgDevices(): Promise<void> {
    if (this.state.currentOrganization) {
      this.setState({ loading: true });

      const devices = await BackendFactory.getBackend().getOrganizationDevices(this.state.currentOrganization.getId());

      this.setState({
        currentDevices: devices,
        loading: false,
      });
    } else {
      this.setState({ loading: false, currentDevices: undefined });
    }
  }

  private async fetchDevicesCustom(searchString: string): Promise<void> {
    if (this.state.searchFilter) {
      this.setState({ loading: true });

      const devices = await BackendFactory.getBackend().searchDevices(searchString.substring(1));

      this.setState({
        currentDevices: devices,
        loading: false,
      });
    } else {
      this.setState({ loading: false, currentDevices: undefined });
    }
  }

  private async fetchDisplayName(deviceId: string): Promise<void> {
    const device = await BackendFactory.getBackend().getDevice(deviceId);

    if (device) {
      this.setState({ selectedDisplayName: getDisplayName(device) });
    }
  }

  private toggleDrawer = (): void => {
    this.setState((prevState: State) => ({
      drawerOpen: !prevState.drawerOpen,
    }));
  };

  private readonly onSearchTriggered = (searchString: string): void => {
    if (searchString.charAt(0) === "$") {
      this.setState({
        searchFilter: searchString,
        customQuery: true,
      });
      this.fetchDevicesCustom(searchString);
    } else {
      this.setState({
        searchFilter: searchString,
        customQuery: false,
      });
    }
  };

  private handleOrganizationSelected = (organization: Organization): void => {
    this.setState({ currentOrganization: organization });

    if (this.props.onOrganizationSelect) {
      this.props.onOrganizationSelect(organization);
    }

    if (!this.state.customQuery) {
      this.fetchOrgDevices();
    }
  };

  private renderOrgBreadcrumbs(): ReactNode {
    if (this.state.rootOrganization) {
      return (
        <OrganizationSelector
          rootOrganization={this.state.rootOrganization}
          organizationSelected={this.handleOrganizationSelected}
        />
      );
    }

    return null;
  }

  private renderDeviceList(): ReactNode {
    return (
      <DrawerDeviceList
        devices={this.state.currentDevices}
        onDeviceSelect={this.props.onDeviceSelect}
        searchFilter={this.state.customQuery ? undefined : getCaseInsensitiveSearchFilter(this.state.searchFilter)}
      />
    );
  }

  public render(): ReactNode {
    return (
      <div className="iot-device-list-container col-sm-3 col-xsm-12">
        <Button onClick={this.toggleDrawer} data-testid="iot-drawer-forward-arrow">
          <img src={ArrowForward} alt="forward arrow" />
        </Button>
        {this.state.selectedDisplayName && <span>{this.state.selectedDisplayName}</span>}
        <Drawer
          open={this.state.drawerOpen}
          classes={{ paper: "col-xlg-3 col-sm-4 col-xsm-9 iot-drawer" }}
          onClose={this.toggleDrawer}
        >
          <div className="iot-drawer-list" data-testid="device-drawer">
            <div className="iot-drawer-list-header">
              <Button onClick={this.toggleDrawer} data-testid="iot-drawer-back-arrow">
                <img src={ArrowBack} alt="back arrow" />
              </Button>
              <span className="iot-drawer-list-header-title">{translations.deviceDrawer.texts.title()}</span>
            </div>
            <div className="device-list-content-container">
              <div className="device-list-breadcrumbs-container">{this.renderOrgBreadcrumbs()}</div>
              <div className="device-list-container">{this.state.loading ? <Loader /> : this.renderDeviceList()}</div>
              <div className="device-list-search-bar-container">
                <SearchBar
                  searchString={this.state.searchFilter}
                  onSearchTriggered={this.onSearchTriggered}
                  className="admin-search-bar-container"
                />
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
