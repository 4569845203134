/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { SessionSet } from "./SessionSet";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { DevicesSessionsListDocument } from "../../generated/gqlData";
import { isDefined } from "../../common/isDefined";
export class AWSSessionSet extends SessionSet {
    constructor(deviceId, startTimestamp, endTimestamp) {
        super();
        this.deviceId = deviceId;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.data = [];
    }
    getId() {
        return this.deviceId;
    }
    getSessions() {
        return this.data;
    }
    async fetch() {
        var _a, _b, _c;
        try {
            let nextToken;
            let sessions = [];
            const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
            do {
                const response = await appSyncClient.query(DevicesSessionsListDocument, {
                    deviceId: this.deviceId,
                    startTimestamp: this.startTimestamp.toString(),
                    endTimestamp: this.endTimestamp.toString(),
                    nextToken,
                });
                nextToken = (_a = response.data.devicesSessionsList) === null || _a === void 0 ? void 0 : _a.nextToken;
                const convertedSessions = (_c = (_b = response.data.devicesSessionsList) === null || _b === void 0 ? void 0 : _b.sessions) === null || _c === void 0 ? void 0 : _c.filter(isDefined).map((fragment) => {
                    var _a;
                    return ({
                        deviceId: fragment.deviceId,
                        sessionId: fragment.sessionId,
                        durationMs: (_a = fragment.durationMs) !== null && _a !== void 0 ? _a : 0,
                    });
                });
                sessions = sessions.concat(convertedSessions !== null && convertedSessions !== void 0 ? convertedSessions : []);
            } while (nextToken);
            this.data = sessions;
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    setData(data) {
        this.data.push(data);
    }
}
