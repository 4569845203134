/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { EventsFeedDocument, EventsMetadataListDocument } from "../../generated/gqlEvents";
import { AbstractReceiverSubscriptionManager } from "../subscriptions";
import { ReceiverManager } from "../receivers";
export class EventsRepository extends AbstractReceiverSubscriptionManager {
    constructor() {
        super(() => AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS), EventsFeedDocument, true);
        this.events = [];
        this.eventMetadata = new Map();
        ReceiverManager.instance.addObserver(this);
    }
    static get instance() {
        return EventsRepository.__instance;
    }
    static getIsSameEventComparator(event) {
        return (e) => event.deviceId === e.deviceId && event.eventId === e.eventId && event.timestamp === e.timestamp;
    }
    /**
     * Subscribes listener to all global receivers provided by {@link ReceiverManager}.
     *
     * @param listener
     */
    addListener(listener) {
        super.addListener(listener, ReceiverManager.instance.getFullReceiverList());
    }
    async init() {
        this.eventMetadata = new Map();
        await this.fetchEventMetadata();
    }
    uninit() {
        this.close();
        if (this.eventMetadata) {
            this.eventMetadata.clear();
        }
    }
    getEventName(eventId) {
        var _a, _b;
        return (_b = (_a = this.eventMetadata.get(eventId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : eventId;
    }
    getEventDescription(eventId) {
        var _a, _b;
        return (_b = (_a = this.eventMetadata.get(eventId)) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : eventId;
    }
    getAllEventDefinitions() {
        return [...this.eventMetadata.values()];
    }
    onReceiversChanged(newReceivers) {
        this.knownReceivers
            .filter((oldReceiver) => !newReceivers.includes(oldReceiver))
            .forEach((receiver) => this.unsubscribe(receiver));
        newReceivers.filter((receiver) => !this.isSubscribedTo(receiver)).forEach((receiver) => this.subscribe(receiver));
    }
    async handleSubscriptionResult(result, listeners) {
        if (!(result === null || result === void 0 ? void 0 : result.eventsFeed))
            return;
        const event = result.eventsFeed.item;
        const isNew = this.handleEvent(event);
        listeners.forEach((listener) => {
            if (isNew) {
                listener.onEvent(event);
            }
            else {
                listener.onEventStateChanged(event);
            }
        });
    }
    async fetchEventMetadata() {
        var _a, _b;
        let nextToken;
        try {
            do {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
                const metadataResponse = await client.query(EventsMetadataListDocument, {
                    nextToken,
                }, {
                    fetchPolicy: "network-only",
                });
                nextToken = (_a = metadataResponse.data.eventsMetadataList) === null || _a === void 0 ? void 0 : _a.nextToken;
                (_b = metadataResponse.data.eventsMetadataList) === null || _b === void 0 ? void 0 : _b.eventMetadataItems.forEach((metadata) => {
                    const { eventId, name, description } = metadata;
                    this.eventMetadata.set(eventId, { eventId, name, description });
                });
            } while (nextToken);
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    handleEvent(event) {
        const index = this.events.findIndex(EventsRepository.getIsSameEventComparator(event));
        const isNew = index === -1;
        if (isNew) {
            this.events.push(event);
        }
        else {
            this.events[index] = event;
        }
        return isNew;
    }
}
EventsRepository.EVENT_AGE_DAYS = 365;
EventsRepository.__instance = new EventsRepository();
