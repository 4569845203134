/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

export enum PhoneNumberResult {
  Valid = "Valid",
  MissingPlus = "MissingPlus",
  TooShort = "TooShort",
  BadCharacters = "BadCharacters",
}

const PHONE_NUMBER_MIN_LEN = 4;
const PHONE_NUMBER_REGEXP = RegExp(`^\\+[0-9]{${PHONE_NUMBER_MIN_LEN},}$`);

export function validatePhoneNumber(number: string): PhoneNumberResult {
  const hasInitialPlus = number.startsWith("+");
  if (!hasInitialPlus) return PhoneNumberResult.MissingPlus;
  const longEnough = number.length >= (hasInitialPlus ? PHONE_NUMBER_MIN_LEN + 1 : PHONE_NUMBER_MIN_LEN);
  if (!longEnough) return PhoneNumberResult.TooShort;
  if (!PHONE_NUMBER_REGEXP.test(number)) return PhoneNumberResult.BadCharacters;
  return PhoneNumberResult.Valid;
}

export enum EmailResult {
  Valid = "Valid",
  MissingAt = "MissingAt",
  MissingUsername = "MissingUsername",
  MissingDomain = "MissingDomain",
}

// checks that domain is in the format abcde.xyz
const DOMAIN_REGEXP = /.+\..+/;

export function validateEmail(email: string): EmailResult {
  const hasAt = email.includes("@");
  if (!hasAt) return EmailResult.MissingAt;
  const startsWithAt = email.startsWith("@");
  const [username, domain] = email.split("@");

  if (username && domain && DOMAIN_REGEXP.test(domain)) return EmailResult.Valid;
  if (startsWithAt) return EmailResult.MissingUsername;
  else return EmailResult.MissingDomain;
}
