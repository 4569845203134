/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { ChangeEvent, Component, Fragment } from "react";
import { Button, TextField, withStyles } from "@material-ui/core";
import Loader from "../../../components/ui/loader";
import { translations } from "../../../generated/translationHelper";
import { AuthWrapper, ErrorWithCode, isErrorWithCode, Maybe } from "@sade/data-access";
import { isValidEmail } from "../../../utils/validation";

export interface Props {
  onLoginRequest: () => void;
  onPasswordResetRequest: (user: string) => void;
}

interface State {
  username: string;
  isLoaderVisible: boolean;
  generalError?: string;
  emailError?: string;
}

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

export default class ForgotPasswordUserForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      username: "",
    };
  }

  private fieldsHaveValues(): boolean {
    return Boolean(this.state.username);
  }

  private handleErrors(reason: string, message?: string): void {
    switch (reason) {
      case "UsernameEmptyException":
        this.setState({ emailError: translations.logIn.texts.usernameCannotBeEmpty() });
        break;
      case "EmailFormatNotValidException":
        this.setState({ emailError: translations.logIn.texts.emailFormatNotValid() });
        break;
      case "LimitExceededException":
        this.setState({ emailError: translations.common.texts.tooManyAttempts() });
        break;
      case "NetworkError":
        this.setState({ generalError: translations.common.texts.networkError() });
        break;
      case "NotAuthorizedException":
        if (message?.includes("User password cannot be reset in the current state")) {
          this.setState({ generalError: translations.logIn.texts.passwordCannotBeResetCurrently() });
        } else {
          this.setState({ generalError: translations.common.texts.unableToPerformAction() });
        }
        break;
      case "UserNotFoundException":
        this.props.onPasswordResetRequest(this.state.username);
        break;
      case "ResetPasswordNotAuthorizedException":
        this.setState({ generalError: translations.logIn.texts.passwordCannotBeResetCurrently() });
        break;
      case "InvalidParameterException":
        this.setState({ emailError: translations.logIn.texts.emailNotVerified() });
        break;
      case "CodeMismatchException":
      case "ExpiredCodeException":
      default:
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
        break;
    }
  }

  private resetErrors(): void {
    this.setState({
      generalError: undefined,
      emailError: undefined,
    });
  }

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.state.isLoaderVisible) {
      return <Loader size={1} leftRightPadding="1rem" />;
    }
  };

  private renderLoaderCounter = (): Maybe<JSX.Element> => {
    if (this.state.isLoaderVisible) {
      return <div style={{ width: "3rem" }} />;
    }
  };

  private renderBackToLogInForm(): JSX.Element {
    return (
      <Fragment>
        <div className="login-links">
          <a className="login-back" onClick={(): void => this.props.onLoginRequest()} data-testid="back-to-login-page">
            {translations.logIn.buttons.backToLogIn()}
          </a>
        </div>
      </Fragment>
    );
  }

  private renderGeneralError(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext" data-testid="forgot-pass-general-err">
          {translations.common.texts.errorOccurred({ error: this.state.generalError })}
        </div>
      );
    }
  }

  private handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      this.submitUser(true);
    }
  };

  private isEmailValid(email?: string): void | never {
    const testedEmail = email ?? this.state.username;

    if (testedEmail === "") {
      throw new ErrorWithCode("UsernameEmptyException");
    }

    if (!isValidEmail(testedEmail)) {
      throw new ErrorWithCode("EmailFormatNotValidException");
    }
  }

  private submitUser = async (SendNewCode: boolean): Promise<void> => {
    try {
      this.resetErrors();
      this.isEmailValid();
      this.setState({ isLoaderVisible: true });
      if (SendNewCode) {
        await AuthWrapper.forgotPassword(this.state.username);
      }
      this.props.onPasswordResetRequest(this.state.username);
    } catch (error) {
      if (isErrorWithCode(error)) {
        this.handleErrors(error.code, error.message);
      } else {
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
      }
      this.setState({ isLoaderVisible: false });
    }
  };

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <ValidationTextField
            label={translations.common.inputs.email()}
            type="email"
            name="email"
            id="email"
            inputProps={{ "data-testid": "email-field" }}
            autoComplete="email"
            margin="normal"
            variant="outlined"
            autoFocus={true}
            required={true}
            helperText={this.state.emailError}
            error={this.state.emailError != null}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
              this.resetErrors();
              this.setState({ username: event.currentTarget.value });
            }}
            fullWidth={true}
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="login-links">
          <a data-testid="already-have-code" onClick={(): Promise<void> => this.submitUser(false)}>
            {translations.logIn.buttons.verificationCode()}
          </a>
        </div>
        <div className="login-buttons">
          <Button
            disabled={!this.fieldsHaveValues() || this.state.isLoaderVisible}
            variant="contained"
            color="primary"
            onClick={(): Promise<void> => this.submitUser(true)}
            data-testid="submit-email-button"
          >
            {this.renderLoader()}
            {translations.logIn.buttons.sendCode()}
            {this.renderLoaderCounter()}
          </Button>
        </div>
      </Fragment>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <p className="login-header">{translations.logIn.texts.forgotPassword()}</p>
        <p className="login-subheader">{translations.logIn.texts.forgotPasswordInstructions()}</p>
        {this.renderInputs()}
        {this.renderBackToLogInForm()}
        {this.renderGeneralError()}
      </Fragment>
    );
  }
}
