/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Link, MenuItem, Menu, IconButton } from "@material-ui/core";
import { Organization } from "@sade/data-access";
import React, { Component, Fragment, ReactNode } from "react";
import ArrowDropDownIcon from "../../../assets/arrow_drop_down_24px.svg";

interface Props {
  currentOrganization: Organization;
  onOrganizationSelected: (organization: Organization) => void;
}
interface State {
  siblingOrganizations: Organization[];
  menuOpen: boolean;
  menuAnchor?: Element;
}

export class ParentLinkRenderer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { siblingOrganizations: [], menuOpen: false };
  }
  public componentDidMount(): void {
    this.props.currentOrganization
      .getParentOrganization()
      .then((parentOrganization) => {
        return parentOrganization?.getChildOrganizations();
      })
      .then((childOrganizations) => {
        if (childOrganizations) this.setState({ siblingOrganizations: childOrganizations });
      });
  }

  public render(): ReactNode {
    const listOrganizations = this.state.siblingOrganizations.filter(
      (organization) => organization !== this.props.currentOrganization
    );
    return (
      <Fragment>
        <Link
          key={this.props.currentOrganization.getId()}
          color="inherit"
          href="#"
          onClick={(): void => void this.props.onOrganizationSelected(this.props.currentOrganization)}
          data-testid={`organization-link-${this.props.currentOrganization.getName()}`}
        >
          {this.props.currentOrganization.getName()}
        </Link>
        {listOrganizations.length > 0 && (
          <Fragment>
            <IconButton
              style={{ padding: "0.1em" }}
              id="menuButton"
              onClick={(event): void => {
                this.setState({ menuOpen: true, menuAnchor: event.currentTarget });
              }}
            >
              <img src={ArrowDropDownIcon} />
            </IconButton>
            <Menu
              open={this.state.menuOpen}
              onClose={(): void => {
                this.setState({ menuOpen: false });
              }}
              anchorEl={this.state.menuAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              getContentAnchorEl={null}
            >
              {listOrganizations.map((organization) => {
                return (
                  <MenuItem
                    key={organization.getId()}
                    onClick={(): void => {
                      this.setState({ menuOpen: false });
                      this.props.onOrganizationSelected(organization);
                    }}
                  >
                    {organization.getName()}
                  </MenuItem>
                );
              })}
            </Menu>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
