/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode, Fragment } from "react";
import { Device, Maybe } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import DraggableDeviceItem from "../../device-browsing/components/draggable-device-item";
import SearchFilter, { searchFilterDevice } from "../../device-browsing/helpers/search-filter";

//To do: search filter
interface Props {
  devices?: Device[];
  onDeviceSelect?: (device?: Device) => void;
  searchFilter?: SearchFilter;
}

interface State {
  selectedDeviceId?: string;
}

export default class DrawerDeviceList extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
  }

  private handleClick = (device?: Device): void => {
    if (device) {
      this.setState({ selectedDeviceId: device.getId() });

      if (this.props.onDeviceSelect) {
        this.props.onDeviceSelect(device);
      }
    }
  };

  private getFilteredDevices(): Maybe<Device[]> {
    const { searchFilter } = this.props;
    if (this.props.devices && searchFilter) {
      return this.props.devices.filter((item) => searchFilterDevice(searchFilter, item));
    }
    return this.props.devices;
  }

  private renderDeviceList(): ReactNode {
    const deviceList = this.getFilteredDevices();

    if (!deviceList) {
      return <div className="device-list-error-message">{translations.common.texts.noDataAvailable()}</div>;
    }

    if (deviceList.length !== 0) {
      return deviceList.map((device: Device) => {
        return (
          <DraggableDeviceItem
            key={device.getId()}
            device={device}
            editMode={false}
            onDeviceSelect={this.handleClick}
            selected={this.state?.selectedDeviceId === device.getId()}
          />
        );
      });
    } else {
      return (
        <div className="device-list-error-message">{translations.deviceDrawer.texts.noDevicesOrGroupsFound()}</div>
      );
    }
  }

  public render(): ReactNode {
    return <Fragment>{this.renderDeviceList()}</Fragment>;
  }
}
