/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { isObject } from "../../common";
/**
 * Handles receiver-based GraphQL subscriptions for a certain type of data objects.
 * Subscribes to a GQL topic, converts message data to TSubscriptionResult objects and passes those objects
 * to the registered listeners.
 */
export class AbstractReceiverSubscriptionManager {
    /**
     * @param clientProvider
     *    provides AppSync client pointing to the correct service
     * @param document
     *    subscription GraphQL document
     * @param invokeHandlerWithoutListeners
     *    set this to true, if there is a need to invoke {@link handleSubscriptionResult} event when there are no registered
     *    listeners for the receiver. Defaults to false
     * @protected
     */
    constructor(clientProvider, document, invokeHandlerWithoutListeners = false) {
        var _a, _b;
        this.clientProvider = clientProvider;
        this.document = document;
        this.invokeHandlerWithoutListeners = invokeHandlerWithoutListeners;
        this.subscriptions = new Map();
        this.listeners = new Map();
        this.operationName = (_b = (_a = document.definitions.find((def) => def.kind === "OperationDefinition")) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.value;
        console.log(`Initialized ${AbstractReceiverSubscriptionManager.name} for ${this.operationName}`);
        // TODO: if necessary, implementer should call: ReceiverManager.instance.addObserver(this);
    }
    get knownReceivers() {
        return [...this.subscriptions.keys()];
    }
    isSubscribedTo(receiver) {
        return this.knownReceivers.includes(receiver);
    }
    /**
     * Method for registering listeners that react to subscription messages
     * @param listener
     *    listener that handles subscription results
     * @param receivers
     *    list of custom receivers. The listener is subscribed to these receivers.
     *    Global receiver support needs to be implemented in the extending class, leveraging {@link ReceiverManager}.
     */
    addListener(listener, receivers) {
        var _a;
        if (receivers.length === 0)
            throw new Error("Cannot add listener for 0 receivers");
        for (const receiver of receivers.filter((r) => r.length > 0)) {
            if (!this.listeners.has(receiver))
                this.listeners.set(receiver, new Set());
            (_a = this.listeners.get(receiver)) === null || _a === void 0 ? void 0 : _a.add(listener);
            if (!this.subscriptions.has(receiver)) {
                this.subscribe(receiver);
            }
        }
    }
    /**
     * Method for unregistering listeners
     * @param listener
     */
    removeListener(listener) {
        const listenerEntries = [...this.listeners.entries()];
        for (const [key, set] of listenerEntries) {
            if (set.delete(listener) && set.size === 0) {
                this.listeners.delete(key);
                this.unsubscribe(key);
            }
        }
    }
    /**
     * Removes subscriptions and listeners
     * @protected
     */
    close() {
        this.knownReceivers.forEach((receiver) => this.unsubscribe(receiver));
        this.subscriptions.clear();
        this.listeners.clear();
    }
    /**
     * Creates a GraphQL subscription for receiver
     * @param receiver
     * @protected
     */
    subscribe(receiver) {
        if (this.subscriptions.has(receiver)) {
            return;
        }
        console.log(`${receiver} subscribing to ${this.operationName}`);
        const client = this.clientProvider();
        const subscription = client
            .subscribe(this.document, {
            receiver,
        })
            .subscribe({
            error: (error) => {
                if (isObject(error) && error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.debug(`Socket connection interrupted, resubscribing ${this.operationName} to ${receiver}`);
                    this.reSubscribe(receiver);
                }
                else {
                    console.error("Received subscription error", error);
                }
            },
            next: async (update) => {
                if (!update.data) {
                    console.error("Received subscription result without data", JSON.stringify(update));
                    return;
                }
                try {
                    const listeners = this.listeners.get(receiver);
                    if (listeners || this.invokeHandlerWithoutListeners)
                        await this.handleSubscriptionResult(update.data, listeners ? [...listeners] : []);
                }
                catch (err) {
                    console.error("subscription handler leaks errors, unsubscribing handler", err);
                    this.unsubscribe(receiver);
                }
            },
        });
        this.subscriptions.set(receiver, subscription);
    }
    /**
     * Deletes receiver's GraphQL subscription
     * @param receiver
     * @protected
     */
    unsubscribe(receiver) {
        var _a;
        console.log(`${receiver} unsubscribing from ${this.operationName}`);
        (_a = this.subscriptions.get(receiver)) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.subscriptions.delete(receiver);
    }
    reSubscribe(receiver) {
        this.unsubscribe(receiver);
        this.subscribe(receiver);
    }
}
