/*
 * Copyright (C)
 */

import { Button, Dialog, Typography } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import { translations } from "../../generated/translationHelper";

interface Props {
  filename: string;
  url: string;
  onClose: () => void;
}

export default class DownloadDialog extends Component<Props> {
  private handleDownload = (): void => {
    this.props.onClose();
  };

  private handleCancel = (): void => {
    this.props.onClose();
  };

  public render(): ReactNode {
    return (
      <Dialog onClose={this.props.onClose} open={true}>
        <div className="popup-inner">
          <Typography variant="h6" style={{ display: "inline-block" }}>
            {translations.common.buttons.download()}
          </Typography>
          <div className="popup-body" data-testid="success-popup">
            <p>{this.props.filename}</p>
            <div className="buttons-container">
              <Button
                style={{ margin: "0.2rem" }}
                variant="contained"
                color="primary"
                onClick={this.handleCancel}
                data-testid="cancel-popup-button"
              >
                {translations.common.buttons.cancel()}
              </Button>
              <a style={{ padding: "0.2rem" }} href={this.props.url} download="fingerprint">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownload}
                  data-testid="download-popup-button"
                >
                  {translations.common.buttons.download()}
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
