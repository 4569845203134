/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { ReferenceHWState } from "../ReferenceHW/ReferenceHWState";
export var HyperIntervalTypes;
(function (HyperIntervalTypes) {
    HyperIntervalTypes[HyperIntervalTypes["MEASURE"] = 0] = "MEASURE";
    HyperIntervalTypes[HyperIntervalTypes["UPDATE"] = 1] = "UPDATE";
})(HyperIntervalTypes || (HyperIntervalTypes = {}));
export class HyperHWState extends ReferenceHWState {
    static toValidInterval(value, type) {
        if (value == null) {
            return HyperHWState.DEFAULT_INTERVAL;
        }
        if (value > HyperHWState.MAX_INTERVAL) {
            return HyperHWState.MAX_INTERVAL;
        }
        const min = HyperHWState.getMinIntervalValue(type);
        if (value < min) {
            return min;
        }
        return value;
    }
    static getMinIntervalValue(type) {
        switch (type) {
            case HyperIntervalTypes.MEASURE:
                return this.MIN_MEASUREMENT_INTERVAL;
            case HyperIntervalTypes.UPDATE:
                return this.MIN_UPDATE_INTERVAL;
            default:
                return 0;
        }
    }
    get noModemSleep() {
        return this.deltaExists("noModemSleep") ? this.desired.noModemSleep : this.reported.noModemSleep;
    }
    set noModemSleep(enabled) {
        this.desired.noModemSleep = enabled;
        this.changedValues.noModemSleep = enabled;
    }
    get updateInterval() {
        return this.deltaExists("updateInterval") ? this.desired.updateInterval : this.reported.updateInterval;
    }
    set updateInterval(value) {
        const validatedValue = HyperHWState.toValidInterval(value, HyperIntervalTypes.UPDATE);
        this.desired.updateInterval = validatedValue;
        this.changedValues.updateInterval = validatedValue;
    }
    get measurementInterval() {
        return this.deltaExists("measurementInterval")
            ? this.desired.measurementInterval
            : this.reported.measurementInterval;
    }
    set measurementInterval(value) {
        const validatedValue = HyperHWState.toValidInterval(value, HyperIntervalTypes.MEASURE);
        this.desired.measurementInterval = validatedValue;
        this.changedValues.measurementInterval = validatedValue;
    }
    get ledsOn() {
        return this.deltaExists("ledsOn") ? this.desired.ledsOn : this.reported.ledsOn;
    }
    set ledsOn(enabled) {
        this.desired.ledsOn = enabled;
        this.changedValues.ledsOn = enabled;
    }
    static instanceOf(state) {
        return state instanceof HyperHWState;
    }
}
HyperHWState.DEFAULT_INTERVAL = 60;
HyperHWState.MAX_INTERVAL = 3600;
HyperHWState.MIN_UPDATE_INTERVAL = 0;
HyperHWState.MIN_MEASUREMENT_INTERVAL = 5;
