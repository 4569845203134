/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
/**
 * Verifies that value is an object.
 * @param {unknown} value Potential object
 * @returns {boolean} result
 */
export function isObject(value) {
    return typeof value === "object" && value != null && !Array.isArray(value);
}
export function isError(error) {
    return (isObject(error) &&
        typeof error.name === "string" &&
        typeof error.message === "string" &&
        (!error.stack || typeof error.stack === "string"));
}
