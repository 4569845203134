/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Button, Grid } from "@material-ui/core";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  SelectableRows,
} from "mui-datatables";
import React, { Component, Fragment, ReactNode } from "react";
import {
  Device,
  Event,
  EventObserver,
  EventSet,
  EventsRepository,
  EventState,
  TimePeriod,
  timePeriodIsValid,
} from "@sade/data-access";
import Loader from "../ui/loader";
import { DeviceAndTime, DeviceChangeType, idFromProps, parseDeviceAndTime } from "../../utils/NavigationUtils";
import TimeRangePicker from "../ui/time-range-picker";
import LiveControl from "../ui/live-control";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../types/routerprops";
import EventChart from "./components/event-chart";
import { DeviceChangeResultHandler } from "../../utils/DeviceChangeResultHandler";
import DeviceNavigationCache from "../../utils/DeviceNavigationCache";
import DeviceDrawer from "../drawers/device-drawer";
import { translations } from "../../generated/translationHelper";
import { ContentSplash } from "../ui/content-splash";
import { convertStringToTimestamp, convertTimestampToString, DateTimeFormatTarget } from "../../utils/TimeUtils";
import accessControlled from "../access-control/access-controlled";
import ViewAccessMethods from "../../ViewAccessMethods";

type Props = RouteComponentProps<DevicePathRouterProps>;

interface State {
  isLoading: boolean;
  liveMode: boolean;
  device?: Device;
  eventSet?: EventSet;
  events?: Event[];
}

const DEFAULT_TIME_WINDOW_MS: number = 7 * 24 * 60 * 60 * 1000;
const RESET_STATE = { eventSet: undefined, liveMode: false, device: undefined };

type EventTableCell = string | number | JSX.Element;
type EventTableRow = EventTableCell[];

class EventView extends Component<Props, State> implements EventObserver {
  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "false",
        viewColumns: false,
      },
    },
    {
      name: "timestamp",
      label: translations.common.data.timestamp(),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: translations.common.data.description(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: translations.events.data.type(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "severity",
      label: translations.events.data.severity(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sensorName",
      label: translations.events.data.sensor(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sensorValue",
      label: translations.events.data.sensorValue(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "metadata",
      label: translations.events.data.metadata(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "eventState",
      label: translations.events.data.state(),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  private readonly tableOptions: MUIDataTableOptions = {
    sortOrder: {
      name: "timestamp",
      direction: "desc",
    },
    filterType: "checkbox",
    // filter: false,
    customToolbarSelect: (
      _selectedRows: unknown,
      _displayData: Array<{ data: unknown[]; dataIndex: number }>,
      _setSelectedRows: unknown
    ): React.ReactNode => {
      // TODO: This is not supported yet.
      return null;
    },
    onRowsDelete: (_rowsDeleted: unknown): void => {
      // TODO: This is not supported yet.
    },
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: true,
    // TODO: Fix any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customSort: (data: any[], colIndex: number, order: string): any => {
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.sort((a: any, b: any) => {
        // TODO: Fix parameter assign
        // eslint-disable-next-line no-param-reassign
        a = a.data[colIndex] || "";
        // eslint-disable-next-line no-param-reassign
        b = b.data[colIndex] || "";

        const colObject = this.tableColumns[colIndex];
        let col: MUIDataTableColumn;

        if (typeof colObject === "object") {
          col = colObject;
        } else {
          return 0;
        }

        if (order === "asc") {
          if (col.name === "timestamp") {
            return EventView.compareTimestamps(a as string, b as string);
          } else {
            return a.toString().localeCompare(b, undefined, { numeric: true });
          }
        } else if (order === "desc") {
          if (col.name === "timestamp") {
            return EventView.compareTimestamps(b as string, a as string);
          } else {
            return b.toString().localeCompare(a, undefined, { numeric: true });
          }
        }
        return 0;
      });
    },
    textLabels: {
      body: {
        noMatch: translations.events.texts.noEventsFound(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
      toolbar: {
        downloadCsv: translations.common.buttons.downloadCsv(),
        filterTable: translations.common.buttons.filterTable(),
        print: translations.common.buttons.print(),
        search: translations.common.buttons.search(),
        viewColumns: translations.common.buttons.viewColumns(),
      },
      filter: {
        reset: translations.common.buttons.reset(),
        title: translations.common.texts.filters().toUpperCase(),
      },
      viewColumns: {
        title: translations.common.texts.showColumns(),
      },
    },
  };
  private readonly deviceChangeHandler = new DeviceChangeResultHandler({
    stateResetCallback: (): void => this.setState(RESET_STATE),
    deviceChangeCallback: (id: string): Promise<void> => this.changeDevice(id),
  });

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      liveMode: false,
    };
  }

  private static compareTimestamps(timeStringA: string, timeStringB: string): number {
    const timestampA = convertStringToTimestamp(timeStringA, DateTimeFormatTarget.StatusTable);
    const timestampB = convertStringToTimestamp(timeStringB, DateTimeFormatTarget.StatusTable);
    return timestampA - timestampB;
  }

  private static getDefaultTimePeriod(): TimePeriod {
    const endTimestamp = Date.now();
    return {
      endTimestamp,
      startTimestamp: endTimestamp - DEFAULT_TIME_WINDOW_MS,
    };
  }

  public async componentDidMount(): Promise<void> {
    const deviceResolveResult = DeviceNavigationCache.getInstance().predictDeviceChange(this.props);

    if (deviceResolveResult === DeviceChangeType.ChangedToNew) {
      const { deviceId, timePeriod } = parseDeviceAndTime(this.props);
      if (deviceId) await this.changeDevice(deviceId, timePeriod);
    } else if (deviceResolveResult === DeviceChangeType.WillRestore) {
      const deviceId = DeviceNavigationCache.getInstance().getSelectedDevice()?.getId();
      if (deviceId) await this.setUrlFromRouteInfo({ deviceId, timePeriod: {} });
    }
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    console.log("componentDidUpdate", this.props.location.pathname, this.state.device);
    await DeviceNavigationCache.getInstance().resolveDeviceChange(this.deviceChangeHandler, this.props, prevProps);
  }

  public componentWillUnmount(): void {
    this.state.eventSet?.removeObserver(this);
  }

  public async onEventSetUpdate(eventSet: EventSet): Promise<void> {
    if (this.state.liveMode && this.state.device?.getId() === eventSet.getId()) {
      this.setState({
        eventSet: eventSet,
        events: eventSet.getData(),
      });
      await this.setUrlFromRouteInfo({ deviceId: this.state.device.getId(), timePeriod: eventSet.getTimePeriod() });
    }
  }

  private async changeDevice(newDeviceId: string, timePeriod?: Partial<TimePeriod>): Promise<void> {
    this.state.eventSet?.removeObserver(this);

    const setNewDevice = async (): Promise<void> => {
      const newTimePeriod = timePeriod && timePeriodIsValid(timePeriod) ? timePeriod : EventView.getDefaultTimePeriod();
      const device = await DeviceNavigationCache.getInstance().setCurrentDevice(newDeviceId);
      this.setState({ device }, async () => {
        await this.updateUrlTimestampAndFetchEvents(newTimePeriod);
        this.setState({ isLoading: false });
      });
    };

    this.setState({ ...RESET_STATE, isLoading: true }, setNewDevice);
  }

  private async setUrlFromRouteInfo(routeInfo?: DeviceAndTime): Promise<void> {
    await DeviceNavigationCache.getInstance().navigateToDeviceAndTime(this.props, routeInfo);
  }

  private async fetchDeviceEvents(timePeriod: TimePeriod): Promise<void> {
    if (!this.state.device || !timePeriodIsValid(timePeriod)) {
      return;
    }
    this.setState({ isLoading: true });

    try {
      const eventSet = await this.state.device.getEventSet(timePeriod.startTimestamp, timePeriod.endTimestamp);

      if (eventSet) {
        if (this.state.liveMode) eventSet?.addObserver(this);
        this.setState({ eventSet, events: eventSet.getData() });
      }
    } catch (error) {
      console.error("fetchDeviceEvents", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private getTableData(): EventTableRow[] {
    return (this.state.events ?? [])
      .filter((event) => event.eventState === EventState.Active)
      .map((event) => {
        return [
          event.timestamp,
          // TODO: Change event updateTimestamp to be number
          convertTimestampToString(Number(event.updatedTimestamp), DateTimeFormatTarget.EventsTable),
          event.eventId ? EventsRepository.instance.getEventDescription(event.eventId) : "",
          event.type ?? "",
          event.severity ?? "",
          event.sensorName ?? "",
          event.sensorValue ?? "",
          event.metadata ?? "",
          this.renderAcknowledgementCell(event),
        ];
      });
  }

  private handleTimeRangeSelect = async (startTimestamp: number, endTimestamp: number): Promise<void> => {
    if (this.state.device) {
      await this.updateUrlTimestampAndFetchEvents({ startTimestamp, endTimestamp });
    }
  };

  private handleLiveDataToggle = (): void => {
    const enablingLiveMode = !this.state.liveMode && !!this.state.device;

    if (enablingLiveMode) {
      this.setState({ liveMode: true }, async () => {
        const { timePeriod } = parseDeviceAndTime(this.props);
        const { startTimestamp } = EventView.getDefaultTimePeriod();
        const newTimePeriod = {
          startTimestamp: timePeriod.startTimestamp ?? startTimestamp,
          endTimestamp: Date.now(),
        };

        await this.updateUrlTimestampAndFetchEvents(newTimePeriod);
      });
    } else {
      this.setState({ liveMode: false });
      this.state.eventSet?.removeObserver(this);
    }
  };

  private handleDeviceSelect = async (device?: Device): Promise<void> => {
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, device);
  };

  private async updateUrlTimestampAndFetchEvents(timePeriod: TimePeriod): Promise<void> {
    await Promise.all([
      this.setUrlFromRouteInfo({ deviceId: this.state.device?.getId(), timePeriod }),
      this.fetchDeviceEvents(timePeriod),
    ]);
  }

  private renderToolbar(): ReactNode {
    if (!this.state.device) return;

    const { startTimestamp, endTimestamp } = parseDeviceAndTime(this.props).timePeriod;
    return (
      <div className="iot-tool-container col-sm-9 col-xsm-12">
        <Grid container={true} spacing={2} alignItems="center" style={{ height: "100%", margin: 0 }}>
          <Grid item={true}>
            <TimeRangePicker
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              onTimeRangeSelect={this.handleTimeRangeSelect}
              disabled={this.state.liveMode}
            />
          </Grid>
          <Grid item={true}>
            <LiveControl liveData={this.state.liveMode} onLiveDataToggle={this.handleLiveDataToggle} />
          </Grid>
        </Grid>
      </div>
    );
  }

  private renderChart(): ReactNode {
    // No point to draw chart if only events list title row in the list
    if (!this.state.events?.length) return;

    const { startTimestamp, endTimestamp } = parseDeviceAndTime(this.props).timePeriod;
    return <EventChart events={this.state.events} startTimestamp={startTimestamp} endTimestamp={endTimestamp} />;
  }

  private renderEventsTable(): ReactNode {
    if (!this.state.events) return;
    return (
      <MUIDataTable
        title={translations.events.texts.events()}
        data={this.getTableData()}
        columns={this.tableColumns}
        options={this.tableOptions}
      />
    );
  }

  private renderAcknowledgementCell(event: Event): JSX.Element {
    return (
      <Button
        key={event.timestamp}
        data-testid="acknowledge-button"
        color="primary"
        variant="contained"
        onClick={(): void => {
          this.state.eventSet?.deactivateEvent(event);
          if (this.state.events) this.setState({ events: [...this.state.events] });
        }}
      >
        {translations.events.buttons.acknowledge()}
      </Button>
    );
  }

  private renderDeviceDrawer(): ReactNode {
    return <DeviceDrawer onDeviceSelect={this.handleDeviceSelect} selectedDeviceId={idFromProps(this.props)} />;
  }

  private renderInstructions(): ReactNode {
    if (this.state.isLoading || this.state.device) return;

    return <ContentSplash>{translations.events.texts.selectDevice()}</ContentSplash>;
  }

  private renderContent(): ReactNode {
    return (
      <div className="iot-content-container col-sm-12 col-xsm-12">
        {this.renderInstructions()}
        <Loader show={this.state.isLoading} />
        {this.renderChart()}
        {this.renderEventsTable()}
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderDeviceDrawer()}
        {this.renderToolbar()}
        {this.renderContent()}
      </Fragment>
    );
  }
}

export default accessControlled(withRouter(EventView), ViewAccessMethods.hasOwnerAccess);
