/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React, { ChangeEvent, Component } from "react";
import { Maybe, Nullable, Session } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { millisecondsToHoursAndMinutes } from "../../../utils/TimeUtils";

interface Props {
  sessions: Session[];
  disabled: boolean;
  onSelectSession: (session?: Session) => void;
  selectedSession?: Session;
}

export default class SessionPicker extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  private listSessions(): Maybe<JSX.Element[]> {
    if (this.props.sessions != null) {
      let sessions = this.props.sessions.map((session: Session) => {
        const startTimestamp: string = new Date(Number(session.sessionId)).toLocaleString();
        return (
          <MenuItem key={session.sessionId} value={session.sessionId}>
            {startTimestamp + " - " + millisecondsToHoursAndMinutes(session.durationMs)}
          </MenuItem>
        );
      });
      sessions = sessions.reverse();
      return sessions;
    }
  }

  private selectSession = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
    const value = event.target.value;
    const match = this.props.sessions.find((session: Session) => session.sessionId === value);

    if (this.props.selectedSession !== match) {
      this.props.onSelectSession(match);
    }
  };

  private getSessionId(): string {
    if (this.props.selectedSession && !this.props.sessions.find((session) => session === this.props.selectedSession)) {
      console.error(`Selected session '${this.props.selectedSession.sessionId}' is not in the selection list`);
      return "";
    }
    return this.props.selectedSession?.sessionId ?? "";
  }

  public render(): Nullable<JSX.Element> {
    if (!this.props.sessions) {
      return null;
    }
    return (
      <div className="session-picker-container">
        <FormControl className="session-picker">
          <InputLabel shrink={true}>{translations.history.texts.session()}</InputLabel>
          <Select
            value={this.getSessionId()}
            onChange={this.selectSession}
            disabled={this.props.disabled}
            displayEmpty={true}
          >
            <MenuItem key="Custom" value="" hidden={true}>
              {translations.common.inputs.custom()}
            </MenuItem>
            {this.listSessions()}
          </Select>
        </FormControl>
      </div>
    );
  }
}
