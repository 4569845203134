/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component } from "react";
import HEADER_LOGO from "../../assets/CeLLife-Electrical-Fingerprint_logo_pos_RGB.png";
import { AuthenticatedUser } from "@sade/data-access";
import CompleteAccountCreationForm from "./components/complete-account-creation-form";
import ForgotPasswordUserForm from "./components/forgot-password-user-form";
import ForgotPasswordResetForm from "./components/forgot-password-reset-form";
import LoginForm from "./components/login-form";
import { translations } from "../../generated/translationHelper";
import { getYear } from "date-fns";

interface Props {}

interface State {
  form: Forms;
  user?: string | AuthenticatedUser;
}

export enum Forms {
  ForgotPasswordUser,
  ForgotPasswordReset,
  ConfirmSignUp,
  SignIn,
}

export default class LoginView extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      form: Forms.SignIn,
    };
  }

  private renderInputFields(): JSX.Element {
    if (this.state.form === Forms.ForgotPasswordUser) {
      return (
        <ForgotPasswordUserForm
          onLoginRequest={(): void => this.setState({ form: Forms.SignIn })}
          onPasswordResetRequest={(user): void => this.setState({ form: Forms.ForgotPasswordReset, user })}
        />
      );
    } else if (this.state.form === Forms.ForgotPasswordReset && LoginView.isString(this.state.user)) {
      return (
        <ForgotPasswordResetForm
          onLoginRequest={(): void => this.setState({ form: Forms.SignIn })}
          username={this.state.user}
        />
      );
    } else if (this.state.form === Forms.ConfirmSignUp && this.state.user && !LoginView.isString(this.state.user)) {
      return <CompleteAccountCreationForm user={this.state.user} />;
    }
    return (
      <LoginForm
        onSignUpConfirmRequest={(user): void => this.setState({ form: Forms.ConfirmSignUp, user })}
        onPasswordResetRequest={(user): void => {
          if (user) {
            this.setState({ form: Forms.ForgotPasswordReset, user });
          } else {
            this.setState({ form: Forms.ForgotPasswordUser });
          }
        }}
      />
    );
  }

  private static isString(value: unknown): value is string {
    return typeof value === "string";
  }

  public render(): JSX.Element {
    return (
      <section className="login-page-container">
        <section className="login-page-bg col-lg-6">
          <section className="login-container col-xsm-11 col-sm-9 col-md-6 col-lg-9 col-xlg-6">
            <header className="header-container col-md-12">
              <div className="logo-container login col-xlg-10 col-lg-8 col-mg-8 col-sm-8 col-xsm-10">
                <img className="header-logo" src={HEADER_LOGO} alt="SADE Innovations" />
              </div>
            </header>
            <div className="login-content">{this.renderInputFields()}</div>
            <div className="login-legal">
              <p className="login-copyright">{translations.logIn.texts.copyright({ year: getYear(new Date()) })}</p>
            </div>
          </section>
        </section>
      </section>
    );
  }
}
