/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

/**
 * Reads stage (REACT_APP_CLIENT_STAGE) from the environment variables.
 * @throws Error if stage is not set.
 */
export function getStage(): string {
  const stage = process.env.REACT_APP_CLIENT_STAGE;
  if (!stage) {
    throw new Error("Stage not set");
  }
  return stage;
}
