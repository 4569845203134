/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Maybe } from "@sade/data-access";

/**
 * Reads endpoints-service custom url (REACT_APP_ENDPOINTS_SERVICE_URL_OVERRIDE)
 * from the environment variables.
 *
 * @returns The url or undefined if not set.
 */
export function endpointsOverrideUrlReader(): Maybe<string> {
  return process.env.REACT_APP_ENDPOINTS_SERVICE_URL_OVERRIDE || undefined;
}
