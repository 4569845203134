/**
 * Provides the configuration for the app.
 *
 * Fetches the configuration from the endpoints service, using the stage to determine which environment to fetch from.
 * In order to run this provider in node, you must provide a fetch implementation.
 * For example, you can use node-fetch: https://www.npmjs.com/package/node-fetch (v2 for CommonJs)
 */
export class EndpointsConfigurationProvider {
    /**
     *
     * @param stage - The stage to fetch the configuration for.
     * @param overrideUrlReader - The url of the endpoints service.
     *    Method that returns a potential override for the endpoints service url, if available.
     *    If undefined is returned, url is constructed from the stage.
     * @param options - Options for the provider.
     * @param options.fetch - The fetch implementation to use. If not provided, browser's fetch is used.
     */
    constructor(stage, overrideUrlReader, options) {
        this.stage = stage;
        this.url = overrideUrlReader() || undefined;
        this.fetch = options === null || options === void 0 ? void 0 : options.fetch;
    }
    async getConfiguration() {
        const endpoints = await this.fetchEndpoints();
        return this.responseToConfiguration(endpoints);
    }
    responseToConfiguration(response) {
        try {
            const { GraphQL, Config, RestApi } = response.endpoints;
            return {
                awsConfig: {
                    Analytics: {
                        disabled: true,
                    },
                    Auth: {
                        stage: this.stage,
                        userPoolId: Config.UserPoolId,
                        userPoolWebClientId: Config.ClientId,
                        region: Config.Region,
                        identityPoolId: Config.IdentityPoolId,
                    },
                    AppSync: {
                        AuthenticationType: Config.AuthType,
                        Region: Config.Region,
                        GqlEndpointUsers: GraphQL.users.https,
                        GqlEndpointData: GraphQL.data.https,
                        GqlEndpointDevice: GraphQL.device.https,
                        GqlEndpointEvents: GraphQL.events.https,
                        GqlEndpointStats: GraphQL.stats.https,
                        GqlEndpointMeasurement: GraphQL.measurement.https,
                    },
                },
                resources: {
                    schemas: EndpointsConfigurationProvider.getSchemaUrls(GraphQL),
                    apis: EndpointsConfigurationProvider.getRestApiUrls(RestApi),
                },
            };
        }
        catch (err) {
            console.error("Response and error", response, err);
            throw new Error("Invalid response from endpoints service");
        }
    }
    async fetchEndpoints() {
        var _a;
        const fetchApi = (_a = this.fetch) !== null && _a !== void 0 ? _a : fetch;
        const response = await fetchApi(this.getEndpointsUrl());
        if (response.ok) {
            return response.json();
        }
        else {
            console.error("Response failure body", response.body);
            throw new Error(response.statusText);
        }
    }
    getEndpointsUrl() {
        var _a;
        return (_a = this.url) !== null && _a !== void 0 ? _a : `https://${this.stage}.api.electrical-fingerprint.com/endpoints`;
    }
    static getSchemaUrls(graphql) {
        return Object.entries(graphql).reduce((acc, [key, value]) => {
            acc[key] = { url: value.schemaUrl };
            return acc;
        }, {});
    }
    static getRestApiUrls(restApi) {
        return Object.entries(restApi).reduce((acc, [key, value]) => {
            acc[key] = Object.assign({}, value);
            return acc;
        }, {});
    }
}
