/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import ErrorDialog from "../../../ui/error-dialog";
import FormDialog from "../../../ui/form-dialog";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import Loader from "../../../ui/loader";
import DropdownSelection from "../../../ui/dropdown-selection";
import {
  BackendFactory,
  EventDefinition,
  getEventDefinitionName,
  Maybe,
  NotificationType,
  Organization,
  User,
} from "@sade/data-access";
import BusinessIcon from "@material-ui/icons/Business";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  organization: Organization;
  users: User[];
  events: EventDefinition[];
  open: boolean;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  phoneNumber: string;
  selectedSubscriptionType: NotificationType;
  selectedUser?: User;
  selectedEvent?: EventDefinition;
  errorMessage?: string;
}

export default class AddNotificationSubscriptionPopup extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      phoneNumber: "",
      selectedSubscriptionType: NotificationType.Email,
    };
  }

  private addNotification = async (): Promise<void> => {
    this.setState({ loading: true });

    if (!this.formIsValid()) {
      return this.setErrorState("Missing input data");
    }

    if (this.state.selectedSubscriptionType == null) {
      return this.setErrorState("Missing type");
    }

    try {
      if (this.state.selectedUser && this.state.selectedEvent) {
        await BackendFactory.getNotificationBackend().createNotificationSubscription({
          userId: this.state.selectedUser.getId(),
          owningOrganizationId: this.props.organization.getId(),
          eventType: this.state.selectedSubscriptionType,
          target: {
            eventId: this.state.selectedEvent.eventId,
            organizationId: this.props.organization.getId(),
          },
        });
      }

      this.props.onClose(true);
    } catch (error) {
      this.setErrorState(error instanceof Error ? error : JSON.stringify(error));
    } finally {
      this.setState({ loading: false });
    }
  };

  private setErrorState(message: string | Error): void {
    console.error("AddNotificationSubscriptionPopup", message);
    this.setState({
      errorMessage: translations.admin.texts.failedToAddNotification(),
    });
  }

  private formIsValid(): boolean {
    return !!this.state.selectedEvent && !!this.state.selectedUser;
  }

  private handleTypeSelected = (index?: number): void => {
    this.setState({
      selectedEvent: index !== undefined ? this.props.events[index] : undefined,
    });
  };

  private handleUserSelected = (index?: number): void => {
    this.setState({
      selectedUser: index !== undefined ? this.props.users[index] : undefined,
    });
  };

  private getCurrentRuleSelectionIndex(): Maybe<number> {
    const index = this.props.events.findIndex((event) => event.eventId === this.state.selectedEvent?.eventId);
    return index !== -1 ? index : undefined;
  }

  private getCurrentUserSelectionIndex(): Maybe<number> {
    const index = this.props.users.findIndex((user) => user.getId() === this.state.selectedUser?.getId());
    return index !== -1 ? index : undefined;
  }

  private renderError(): ReactNode {
    return (
      <ErrorDialog
        onClose={(): void => {
          this.setState({ errorMessage: undefined });
          this.props.onClose(false);
        }}
        errorMsg={this.state.errorMessage}
      />
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderError()}
        <FormDialog
          title={translations.admin.texts.newNotification()}
          acceptButtonText={translations.admin.texts.addNotification()}
          isOpen={this.props.open}
          onAccept={this.addNotification}
          onCancel={(): void => this.props.onClose(false)}
          disableAccept={!this.formIsValid()}
        >
          <BusinessIcon className="notification-organization-icon" />
          <Typography>{this.props.organization.getName()}</Typography>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <FormLabel component="legend">{translations.admin.texts.selectEventType()}</FormLabel>
            <DropdownSelection
              currentSelection={this.getCurrentRuleSelectionIndex()}
              onSelect={this.handleTypeSelected}
              selectionList={this.props.events.map((event) => ({
                key: event.eventId,
                label: getEventDefinitionName(event),
              }))}
              emptySelectionItem={translations.admin.inputs.eventType()}
              disabled={this.props.events.length === 0}
            />
          </FormControl>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <FormLabel component="legend">{translations.admin.texts.selectUser()}</FormLabel>
            <DropdownSelection
              currentSelection={this.getCurrentUserSelectionIndex()}
              onSelect={this.handleUserSelected}
              selectionList={this.props.users.map((user) => ({ key: user.getId(), label: user.getEmail() }))}
              emptySelectionItem={translations.admin.inputs.user()}
              disabled={this.props.users.length === 0}
            />
          </FormControl>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <FormLabel component="legend">{translations.admin.texts.selectNotificationType()}</FormLabel>
            <RadioGroup
              name="subscription-type"
              value={this.state.selectedSubscriptionType}
              onChange={(_event, value): void => this.setState({ selectedSubscriptionType: value as NotificationType })}
            >
              <FormControlLabel
                value={NotificationType.Email}
                control={<Radio />}
                label={translations.admin.inputs.emailAlert()}
              />
              <FormControlLabel
                value={NotificationType.Sms}
                control={<Radio />}
                label={translations.admin.inputs.textMessageAlert()}
              />
              <FormControlLabel
                value={NotificationType.Push}
                control={<Radio />}
                label={translations.admin.inputs.pushAlert()}
              />
            </RadioGroup>
          </FormControl>
          <Loader show={this.state.loading} />
        </FormDialog>
      </Fragment>
    );
  }
}
