/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import React, { Component, ReactNode } from "react";
import FormDialog from "../../ui/form-dialog";
import AuthCode from "react-auth-code-input";
import { Typography } from "@material-ui/core";
import { isErrorWithCode } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";

interface Props {
  title: string;
  msg: string;
  onConfirm: (code: string) => Promise<void>;
  onCancel: () => void;
}

interface State {
  code?: string;
  error?: string;
}

const CODE_LENGTH = 6;

export class ConfirmCodeDialog extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  private handleAccept = async (): Promise<void> => {
    this.setState({ error: undefined });
    try {
      await this.props.onConfirm(this.state.code!);
    } catch (err) {
      console.error("ConfirmCodeDialog", err);
      if (isErrorWithCode(err) && err.code === "CodeMismatchException") {
        this.setState({ error: translations.user.texts.codeMismatchError() });
      } else {
        this.setState({ error: translations.user.texts.unknownCodeError() });
      }
    }
  };

  private renderError(): ReactNode {
    const { error } = this.state;
    if (!error) return;
    return (
      <Typography className="user-errortext" align={"center"}>
        {error}
      </Typography>
    );
  }

  public render(): ReactNode {
    return (
      <FormDialog
        title={this.props.title}
        isOpen={true}
        onAccept={this.handleAccept}
        disableAccept={!this.state.code || this.state.code.length !== CODE_LENGTH}
        onCancel={this.props.onCancel}
      >
        {this.props.msg}
        <AuthCode
          inputClassName="code-field"
          allowedCharacters="numeric"
          length={CODE_LENGTH}
          onChange={(val): void => this.setState({ code: val })}
        />
        {this.renderError()}
      </FormDialog>
    );
  }
}
