/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import fromUnixTime from "date-fns/fromUnixTime";
const TIMESTAMP_LENGTH_CUTOFF = 11;
/**
 * Less than or equal is {@link UnixTimestamp}, otherwise {@link Timestamp}
 */
export function isTimestamp(timestamp) {
    return timestamp > 0 && timestamp.toString().length > TIMESTAMP_LENGTH_CUTOFF;
}
export function isUnixTimestamp(timestamp) {
    return timestamp > 0 && timestamp.toString().length <= TIMESTAMP_LENGTH_CUTOFF;
}
export function convertStringTimestampToTimestamp(timestamp) {
    const time = Number(timestamp);
    if (Number.isNaN(time))
        throw new Error(`Given string ${timestamp} cannot be converted to a number`);
    if (isUnixTimestamp(time)) {
        return fromUnixTime(time).getTime();
    }
    else {
        return time;
    }
}
export function timestampToMillisecondPrecision(timestamp) {
    if (isUnixTimestamp(timestamp)) {
        return fromUnixTime(timestamp).getTime();
    }
    else if (isTimestamp(timestamp)) {
        return timestamp;
    }
    else
        throw new Error(`Given number (${timestamp}) was not a valid timestamp`);
}
