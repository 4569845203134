/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { AuthWrapper, Maybe } from "@sade/data-access";

export const SUPPORTED_LANGUAGES = ["en", "fi"] as const;
export type SupportedLanguageCode = (typeof SUPPORTED_LANGUAGES)[number];

/**
 * Gets debug mode status.
 * @returns {boolean} debug mode status
 */
export function isDebugModeEnabled(): boolean {
  const envValue = process.env.REACT_APP_TRANSLATOR_DEBUG_MODE;
  return envValue ? JSON.parse(envValue.toLowerCase()) : false;
}

/**
 * Gets environment language.
 * @returns {Maybe<string>} language code
 */
export function getEnvironmentLanguage(): Maybe<string> {
  return process.env.REACT_APP_LANGUAGE || undefined;
}

/**
 * Verifies language code validity against supported language codes {@link SUPPORTED_LANGUAGES}
 * @param {string} languageCode language code string
 * @returns {boolean}
 */
export function isValidLanguageCode(languageCode: string): languageCode is SupportedLanguageCode {
  return SUPPORTED_LANGUAGES.includes(languageCode as SupportedLanguageCode);
}

export async function fetchLanguageFromBackend(): Promise<Maybe<SupportedLanguageCode>> {
  const code = await AuthWrapper.getLanguage();
  if (!code) return;
  if (isValidLanguageCode(code)) return code;
  console.warn(`Language code '${code}' is not supported`);
}
