/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, ReactNode } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  addDeviceHandler: (id: string) => void;
}

interface State {
  loading: boolean;
  deviceId: string;
}

export default class NewDeviceForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = { loading: false, deviceId: "" };
  }

  private handleAddDeviceButton = (): void => {
    this.props.addDeviceHandler(this.state.deviceId);
  };

  private renderAddButton(): ReactNode {
    return (
      <Button variant="contained" color="primary" onClick={this.handleAddDeviceButton} disabled={!this.state.deviceId}>
        {translations.common.buttons.add()}
      </Button>
    );
  }

  public render(): ReactNode {
    return (
      <Table className="org-groups-table">
        <TableHead>
          <TableRow>
            <TableCell>{translations.admin.texts.addNewDevice()}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <TextField
                fullWidth={true}
                label={translations.common.data.deviceId()}
                variant="outlined"
                type="text"
                value={this.state.deviceId}
                onChange={(event): void => {
                  this.setState({ deviceId: event.currentTarget.value });
                }}
              />
            </TableCell>
            <TableCell align="right">{this.renderAddButton()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}
