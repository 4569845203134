/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../../ui/loader";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { AuthenticatedUser, AuthWrapper, isError, Maybe } from "@sade/data-access";
import PasswordField from "../../ui/password-field";
import { translations } from "../../../generated/translationHelper";
import PasswordRequirements from "./password-requirements";

interface Props {
  user: AuthenticatedUser;
}

interface State {
  isLoaderVisible: boolean;
  newPassword: string;
  confirmPassword: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
  generalError?: string;
  acceptTOS: boolean;
}

export default class CompleteAccountCreationForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      newPassword: "",
      confirmPassword: "",
      acceptTOS: false,
    };
  }

  private handleErrors(code?: string): void {
    switch (code) {
      case "UserNotLoggedIn":
        this.setState({ generalError: translations.logIn.texts.userNotLoggedIn() });
        break;
      case "Password does not conform to policy: Password must have numeric characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveNumbers() });
        break;
      case "Password does not conform to policy: Password must have lowercase characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveLowercaseCharacters() });
        break;
      case "Password does not conform to policy: Password must satisfy regular expression pattern: ^\\S.*\\S$":
        this.setState({ generalError: translations.common.texts.passwordInvalid() });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password does not conform to policy: Password not long enough":
        this.setState({ newPasswordError: translations.common.texts.passwordMustBeLongEnough() });
        break;
      case "Invalid session for the user, session is expired.":
        this.setState({ generalError: translations.common.texts.userSessionExpired() });
        break;
      default:
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
        break;
    }
  }

  private resetErrors(): void {
    this.setState({
      generalError: undefined,
      newPasswordError: undefined,
      confirmPasswordError: undefined,
    });
  }

  private async completeAccountCreation(): Promise<void> {
    this.resetErrors();

    if (!this.passwordsMatch()) {
      this.setState({ confirmPasswordError: translations.common.texts.passwordsNotMatching() });
      return;
    }

    //passwords of length 1 will throw a regex error. This is clearer to the user.
    if (this.state.newPassword.length < 2) {
      this.setState({ newPasswordError: translations.common.texts.passwordMustBeLongEnough() });
      return;
    }

    try {
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.completeNewPassword(this.props.user, this.state.newPassword);
    } catch (error) {
      console.error("completePassword", error);
      if (isError(error)) this.handleErrors(error.message);
      this.setState({ isLoaderVisible: false });
    }
  }

  private validateInputs = (): boolean => {
    return !!this.state.newPassword.length && !!this.state.confirmPassword.length && this.state.acceptTOS;
  };

  private passwordsMatch(): boolean {
    return this.state.newPassword === this.state.confirmPassword;
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoaderVisible) {
      return <Loader />;
    }
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <PasswordField
            label={translations.common.inputs.newPassword()}
            autoComplete="new-password"
            required={true}
            error={this.state.newPasswordError != null}
            helperText={this.state.newPasswordError}
            onChange={(newPassword: string): void => {
              this.resetErrors();
              this.setState({ newPassword });
            }}
            fullWidth={true}
            margin="normal"
            inputProps={{ "data-testid": "new-password-field" }}
          />
          <PasswordField
            label={translations.common.inputs.confirmNewPassword()}
            autoComplete="new-password"
            error={this.state.confirmPasswordError != null}
            helperText={this.state.confirmPasswordError}
            onChange={(confirmPassword: string): void => {
              this.resetErrors();
              this.setState({ confirmPassword });
            }}
            fullWidth={true}
            required={true}
            margin="normal"
            inputProps={{ "data-testid": "confirm-password-field" }}
          />
        </div>
        <PasswordRequirements />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event: ChangeEvent, accept: boolean): void => {
                this.setState({ acceptTOS: accept });
              }}
              data-testid="accept-tos"
            />
          }
          label={
            <div>
              <p>
                {translations.logIn.inputs.acceptTermsOfUse().split("<a>")[0]}
                <a href="https://sadeinnovations.com">{translations.logIn.inputs.acceptTermsOfUse().split("<a>")[1]}</a>
                {translations.logIn.inputs.acceptTermsOfUse().split("<a>")[2]}
              </p>
            </div>
          }
        />
        <div className="login-buttons">
          <Button
            disabled={!this.validateInputs()}
            variant="contained"
            color="primary"
            onClick={(): Promise<void> => this.completeAccountCreation()}
            data-testid="confirm-login-button"
          >
            {translations.logIn.buttons.confirmLogIn()}
          </Button>
        </div>
      </Fragment>
    );
  }

  private renderGeneralError(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext" data-testid="complete-account-err">
          {translations.common.texts.errorOccurred({ error: this.state.generalError })}
        </div>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="login-header">{translations.logIn.texts.finishAccount()}</div>
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderGeneralError()}
      </Fragment>
    );
  }
}
