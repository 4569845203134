/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { ReferenceHWStateProperties } from "../ReferenceHW/ReferenceHWStateProperties";
export class HyperHWStateProperties extends ReferenceHWStateProperties {
    constructor(properties) {
        var _a, _b, _c, _d;
        super(properties);
        this.updateInterval = (_a = properties.updateInterval) !== null && _a !== void 0 ? _a : null;
        this.measurementInterval = (_b = properties.measurementInterval) !== null && _b !== void 0 ? _b : null;
        this.noModemSleep = (_c = properties.noModemSleep) !== null && _c !== void 0 ? _c : null;
        this.ledsOn = (_d = properties.ledsOn) !== null && _d !== void 0 ? _d : null;
    }
}
