/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { AWSBackend } from "./backend/AWSBackend";
import { AWSOrganizationBackend } from "./organization/AWSOrganizationBackend";
import { AWSDeviceFactory } from "./device/AWSDeviceFactory";
import { AWSNotificationBackend } from "./events/AWSNotificationBackend";
export class BackendFactory {
    static getBackend() {
        if (!BackendFactory.genericBackend) {
            BackendFactory.genericBackend = new AWSBackend(new AWSDeviceFactory());
        }
        return BackendFactory.genericBackend;
    }
    static getOrganizationBackend() {
        if (!BackendFactory.organizationBackend) {
            BackendFactory.organizationBackend = new AWSOrganizationBackend();
        }
        return BackendFactory.organizationBackend;
    }
    static getNotificationBackend() {
        if (!BackendFactory.notificationBackend) {
            BackendFactory.notificationBackend = new AWSNotificationBackend();
        }
        return BackendFactory.notificationBackend;
    }
}
