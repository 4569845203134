/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Grid } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import { Event, EventState } from "@sade/data-access";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ArcElement } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { compareAsc, format, isSameDay, startOfDay } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, ChartDataLabels);

interface Props {
  events: Event[];
}

interface State {
  labels?: Date[];
  gradings?: string[];
  barData?: { label: string; data: number[] }[];
  pieData?: { label: string; data: number[] }[];
}

const BAR_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right" as const,
    },
    datalabels: {
      display: false,
    },
  },
};

const PIE_CHART_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        title: {
          font: {
            size: 16,
          },
        },
      },
    },
  },
};

const BACKGROUND_COLORS = { passed: "rgba(75, 192, 192, 0.3)", failed: "rgba(255, 99, 132, 0.3)" };

const BORDER_COLORS = { passed: "rgba(75, 192, 192, 1)", failed: "rgba(255, 99, 132, 1)" };

export default class MeasurementCharts extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
    this.setChartLabels();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.events !== this.props.events) this.setChartLabels();
  }

  private getEventDay = (event: Event): Date => {
    return startOfDay(new Date(parseInt(event.timestamp)));
  };

  private setChartLabels = (): void => {
    const labels = this.props.events.map(this.getEventDay);
    labels.sort(compareAsc);
    this.setState({ labels: labels.filter((date, index) => !isSameDay(labels[index + 1], date)) }, () =>
      this.setChartData()
    );
  };

  private formatDate = (date: Date): string => {
    return format(date, "dd/MM/yyyy");
  };

  private setChartData = (): void => {
    const { events } = this.props;
    const { labels } = this.state;
    if (!labels) return;
    const barDataSet = [
      {
        label: "passed",
        data: labels.map(() => 0),
        borderWidth: 1,
        backgroundColor: BACKGROUND_COLORS.passed,
        borderColor: BORDER_COLORS.passed,
      },
      {
        label: "failed",
        data: labels.map(() => 0),
        borderWidth: 1,
        backgroundColor: BACKGROUND_COLORS.failed,
        borderColor: BORDER_COLORS.failed,
      },
    ];
    const pieDataSet = [
      {
        label: "pie data set",
        data: [0, 0],
        borderWidth: 1,
        backgroundColor: [BACKGROUND_COLORS.passed, BACKGROUND_COLORS.failed],
        borderColor: [BORDER_COLORS.passed, BORDER_COLORS.failed],
      },
    ];
    const activeCellMeasuredEvents = events.filter(
      (event) => event.eventState === EventState.Active && event.eventId === "event-cell-measured"
    );
    for (const event of activeCellMeasuredEvents) {
      const eventDay = this.getEventDay(event);
      try {
        const metadata = JSON.parse(event.metadata!);
        const grading = metadata.grading;
        const dataIndex = labels.findIndex((day) => isSameDay(eventDay, day));
        if (grading < 100) {
          barDataSet[0].data[dataIndex] += 1;
          pieDataSet[0].data[0] += 1;
        } else if (grading >= 100) {
          barDataSet[1].data[dataIndex] += 1;
          pieDataSet[0].data[1] += 1;
        }
      } catch (error) {
        console.error("invalid data format");
      }
    }
    this.setState({
      barData: barDataSet,
      pieData: pieDataSet,
    });
  };

  public render(): ReactNode {
    const { barData, labels, pieData } = this.state;
    if (!barData || !labels || labels.length === 0 || !pieData) return null;
    const pieDataLabels = [];
    for (const data of pieData) {
      pieDataLabels.push(data.label);
    }
    return (
      <Grid container direction="row" spacing={0} className="measurement-chart-container">
        <Grid item xs={9} className="measurement-bar-chart">
          <Bar options={BAR_CHART_OPTIONS} data={{ labels: labels.map(this.formatDate), datasets: barData }} />
        </Grid>
        <Grid item container xs={3} alignItems="center" className="measurement-pie-chart">
          <Pie options={PIE_CHART_OPTIONS} data={{ labels: ["passed", "failed"], datasets: pieData }} />
        </Grid>
      </Grid>
    );
  }
}
