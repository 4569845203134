/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
const WILDCARD = "*";
function permissionEndsInWildCard(permission) {
    return permission.endsWith(WILDCARD);
}
function removeWildcard(permission) {
    return permissionEndsInWildCard(permission) ? permission.substring(0, permission.length - 1) : permission;
}
/**
 * Checks if first permission is wide enough to cover another.
 * This is true, if the permission ends with * AND another starts with permission[0, length-1].
 *
 * Note: this method is not case-sensitive, but permissions should be:
 * provide the strings in lower-case.
 *
 * @param permission
 *    potential prefix
 * @param another
 *    permission to check
 */
function permissionCoversAnother(permission, another) {
    if (!permissionEndsInWildCard(permission)) {
        return false;
    }
    const prefix = permission.substring(0, permission.length - 1);
    return another.startsWith(prefix);
}
/**
 * Checks if given permission is already part of the permission list (including wildcards).
 * Case insensitive.
 *
 * @param permissionList
 * @param permission
 */
export function permissionListCoversPermission(permissionList, permission) {
    const permissionLowerCase = permission.toLowerCase();
    const coveringPermission = permissionList.find((listPermission) => {
        const listPermissionLowerCase = listPermission.toLowerCase();
        return (listPermissionLowerCase === permissionLowerCase ||
            permissionCoversAnother(listPermissionLowerCase, permissionLowerCase));
    });
    return Boolean(coveringPermission);
}
/**
 * Removes permissions covered by other permissions in the set.
 * Immutable operation.
 * Case-insensitive.
 *
 * @param set
 */
export function minimizeSetOfPermissions(set) {
    if (set.size === 0)
        return new Set();
    const orderedPermissions = [...set].sort((a, b) => removeWildcard(a).length - removeWildcard(b).length);
    const [start, ...rest] = orderedPermissions;
    const finalPermissions = [start];
    for (const permission of rest) {
        if (!permissionListCoversPermission(finalPermissions, permission)) {
            finalPermissions.push(permission);
        }
    }
    return new Set(finalPermissions);
}
