/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { ReferenceHWStateProperties } from "../ReferenceHW/ReferenceHWStateProperties";
export class SuperHWStateProperties extends ReferenceHWStateProperties {
    constructor(deviceProperties) {
        var _a, _b;
        super(deviceProperties);
        this.ledEnabled = (_a = deviceProperties.ledEnabled) !== null && _a !== void 0 ? _a : null;
        this.ledColor = (_b = deviceProperties.ledColor) !== null && _b !== void 0 ? _b : null;
    }
}
