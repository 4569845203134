/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { BaseObservable } from "../observer/BaseObservable";
import { toNumber } from "../../common/toNumber";
export class EventSet extends BaseObservable {
    static eventOrdering(a, b) {
        const at = toNumber(a.timestamp);
        const bt = toNumber(b.timestamp);
        if (at === undefined && bt === undefined)
            return 0;
        if (at === undefined)
            return -1;
        if (bt === undefined)
            return 1;
        return at - bt;
    }
    static invertedEventOrdering(a, b) {
        const at = toNumber(a.timestamp);
        const bt = toNumber(b.timestamp);
        if (at === undefined && bt === undefined)
            return 0;
        if (at === undefined)
            return 1;
        if (bt === undefined)
            return -1;
        return bt - at;
    }
}
