/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { eventIdentitiesMatch } from "./Event";
import { EventSet } from "./EventSet";
import { EventsSubscriptionManager } from "./EventsSubscriptionManager";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { DevicesEventsListDocument, EventsDeactivateDocument, EventState } from "../../generated/gqlEvents";
import { isDefined } from "../../common/isDefined";
import { toNumber } from "../../common/toNumber";
export class AWSEventSet extends EventSet {
    constructor(device, startTimestamp, endTimestamp) {
        super();
        this.changeTimePeriod = (startTimestamp, endTimestamp) => {
            this.period = { startTimestamp, endTimestamp };
            this.events = [];
        };
        this.eventListener = {
            getId: () => this.getId(),
            onEvent: (event) => this.addOrUpdateEvent(event),
        };
        this.device = device;
        this.period = { startTimestamp, endTimestamp };
        this.events = [];
    }
    async fetch() {
        var _a, _b, _c, _d;
        let nextToken;
        let events = [];
        try {
            do {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
                const deviceEventsResponse = await client.query(DevicesEventsListDocument, {
                    deviceId: this.device.getId(),
                    period: {
                        endTimestamp: this.period.endTimestamp.toString(),
                        startTimestamp: this.period.startTimestamp.toString(),
                    },
                    nextToken,
                }, 
                // mobile-app specific: Force fetch or will return old data from cache
                {
                    fetchPolicy: "network-only",
                });
                nextToken = (_a = deviceEventsResponse.data.devicesEventsList) === null || _a === void 0 ? void 0 : _a.nextToken;
                events = events.concat((_d = (_c = (_b = deviceEventsResponse.data.devicesEventsList) === null || _b === void 0 ? void 0 : _b.events) === null || _c === void 0 ? void 0 : _c.filter(isDefined)) !== null && _d !== void 0 ? _d : []);
            } while (nextToken);
            this.events = events.sort(EventSet.eventOrdering);
        }
        catch (error) {
            console.error("Error", error);
            this.events = [];
        }
    }
    getId() {
        return this.device.getId();
    }
    getData() {
        return [...this.events];
    }
    getTimePeriod() {
        return this.period;
    }
    addOrUpdateEvent(event) {
        const matchIndex = this.events.findIndex((e) => eventIdentitiesMatch(event, e));
        if (matchIndex >= 0) {
            this.events[matchIndex] = event;
        }
        else {
            this.addEvent(event);
        }
        this.notifyAction((observer) => observer.onEventSetUpdate(this));
    }
    async deactivateEvent(event) {
        // Set inactive for fast UI update. State will recover via subs if deactivation fails in cloud side
        event.eventState = EventState.Inactive;
        const eventPayload = {
            deviceId: event.deviceId,
            timestamp: event.timestamp,
        };
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
            await client.mutate(EventsDeactivateDocument, {
                payload: eventPayload,
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    /**
     * Add observer for device event updates
     *
     * Customization:
     * Current implementation registers for all receivers device returns.
     * Modify this list if state access needs different limits.
     *
     * @param observer
     */
    async addObserver(observer) {
        if (!this.events) {
            await this.fetch();
        }
        super.addObserver(observer);
        EventsSubscriptionManager.instance.addListener(this.eventListener, await this.device.getReceivers());
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        EventsSubscriptionManager.instance.removeListener(this.eventListener);
    }
    addEvent(event) {
        this.events.push(event);
        this.events.sort(EventSet.eventOrdering);
        const timestamp = toNumber(event.timestamp);
        if (timestamp && timestamp > this.period.endTimestamp) {
            this.period.endTimestamp = timestamp;
        }
    }
}
