/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Box, Button, Grid } from "@material-ui/core";
import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";

interface Props {
  changesMade: boolean;
  submitButtonLabel: string;
  cancelButtonLabel: string;
  onSave: () => void;
  onCancel: () => void;
}

const SettingsControls: FunctionComponent<Props> = (props: PropsWithChildren<Props>): ReactElement => {
  return (
    <Box>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!props.changesMade}
            onClick={props.onSave}
            data-testid="apply-settings"
          >
            {props.submitButtonLabel}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={props.onCancel} data-testid="close-settings">
            {props.cancelButtonLabel}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsControls;
