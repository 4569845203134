/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { ChangeEvent, Component, ReactNode } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Loader from "../../ui/loader";
import { AuthWrapper, isError, Maybe } from "@sade/data-access";
import { changeLanguage } from "../../../locales/localisator";
import DropdownSelection from "../../ui/dropdown-selection";
import {
  fetchLanguageFromBackend,
  SUPPORTED_LANGUAGES,
  SupportedLanguageCode,
} from "../../../locales/localizationUtils";
import { translations } from "../../../generated/translationHelper";
import ErrorDialog from "../../ui/error-dialog";
import SuccessDialog from "../../ui/success-dialog";
import { ConfirmCodeDialog } from "./confirm-code-dialog";
import { EmailResult, PhoneNumberResult, validateEmail, validatePhoneNumber } from "./field-validators";

interface Props {}

interface State {
  isLoading: boolean;
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumber?: string;
  language?: SupportedLanguageCode;
  error?: string;
  successMessage?: string;
  nameChangesMade: boolean;
  emailChangesMade: boolean;
  phoneChangesMade: boolean;
  languageChangesMade: boolean;
  confirmRequest?: {
    title: string;
    msg: string;
    confirm: (code: string) => Promise<void>;
  };
}

export default class AttributeForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      nameChangesMade: false,
      emailChangesMade: false,
      phoneChangesMade: false,
      languageChangesMade: false,
    };
  }

  private static getSettledValue<TResult = string>(
    result: PromiseSettledResult<Maybe<TResult>>,
    fallbackResult?: TResult
  ): Maybe<TResult> {
    if (result.status === "rejected") {
      console.error("Failed to settle", result.reason);
    } else {
      return result.value ?? fallbackResult;
    }
  }

  public componentDidMount(): void {
    this.getUserDetails().then();
  }

  private async getUserDetails(): Promise<void> {
    this.setState({ isLoading: true });

    const [givenName, familyName, phoneNumber, language, email] = await Promise.allSettled([
      AuthWrapper.getGivenName(),
      AuthWrapper.getFamilyName(),
      AuthWrapper.getPhoneNumber(),
      fetchLanguageFromBackend(),
      AuthWrapper.getEmail(),
    ]);

    this.setState({
      firstname: AttributeForm.getSettledValue(givenName),
      lastname: AttributeForm.getSettledValue(familyName),
      phoneNumber: AttributeForm.getSettledValue(phoneNumber),
      language: AttributeForm.getSettledValue(language),
      email: AttributeForm.getSettledValue(email),
      isLoading: false,
    });
  }

  private handleNameSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.setName(this.state.firstname ?? "", this.state.lastname ?? "");
      this.setState({
        firstname: this.state.firstname,
        lastname: this.state.lastname,
      });
      this.setSuccessMessage(translations.user.texts.successInNameChange());
    } catch (error) {
      console.error("handleNameSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.resetChangesMade();
    }
  };

  private handleEmailSubmit = async (): Promise<void> => {
    const { email } = this.state;
    if (!email) return;
    this.setState({ isLoading: true });
    try {
      const confirm = await AuthWrapper.setEmail(email);
      this.setState({
        confirmRequest: {
          title: translations.user.texts.confirmEmailTitle(),
          msg: translations.user.texts.confirmEmailMsg(),
          confirm,
        },
      });
    } catch (error) {
      console.error("handleEmailSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    }
  };

  private handlePhoneNumberSubmit = async (): Promise<void> => {
    const { phoneNumber } = this.state;
    if (!phoneNumber) return;
    this.setState({ isLoading: true });
    try {
      const confirm = await AuthWrapper.setPhoneNumber(phoneNumber);
      this.setState({
        confirmRequest: {
          title: translations.user.texts.confirmPhoneNumberTitle(),
          msg: translations.user.texts.confirmPhoneNumberMsg(),
          confirm,
        },
      });
    } catch (error) {
      console.error("handlePhoneNumberSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    }
  };

  private handleChangeLanguageSubmit = async (): Promise<void> => {
    try {
      if (!this.state.language) {
        return console.error("Invalid language setting");
      }
      this.setState({ isLoading: true });
      // TODO: maybe we should not save language to backend if it is not a valid choice
      await AuthWrapper.setLanguage(this.state.language);
      await changeLanguage(this.state.language);
      this.setSuccessMessage(translations.user.texts.successInLanguageChange());
    } catch (error) {
      console.error("handleChangeLanguageSubmit", error);
      if (isError(error)) this.handleErrorCode(error.message);
    } finally {
      this.resetChangesMade();
    }
  };

  private resetChangesMade(): void {
    this.setState({
      isLoading: false,
      nameChangesMade: false,
      phoneChangesMade: false,
      languageChangesMade: false,
      emailChangesMade: false,
      confirmRequest: undefined,
    });
  }

  private handleErrorCode(code?: string): void {
    switch (code) {
      case "Attribute value for given_name must not be null":
      case "Attribute value for family_name must not be null":
        this.setErrorMessage(translations.user.texts.invalidName());
        break;
      case "Invalid phone number":
        this.setErrorMessage(translations.user.texts.invalidPhoneNumber());
        break;
      case "Invalid phone number format.":
        this.setErrorMessage(translations.user.texts.invalidPhoneNumberFormat());
        break;
      case "Network error":
        this.setErrorMessage(translations.common.texts.networkError());
        break;
      default:
        this.setErrorMessage(translations.common.texts.unableToPerformAction());
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }

  private setSuccessMessage(successMessage?: string): void {
    this.setState({ successMessage });
  }

  private renderErrorPopUpMessage(): ReactNode {
    if (this.state.error) {
      return <ErrorDialog errorMsg={this.state.error} onClose={(): void => this.setState({ error: undefined })} />;
    }
  }

  private renderConfirmChangeDialog(): ReactNode {
    const { confirmRequest } = this.state;
    if (!confirmRequest) return;
    return (
      <ConfirmCodeDialog
        title={confirmRequest.title}
        msg={confirmRequest.msg}
        onConfirm={async (code): Promise<void> => {
          await confirmRequest.confirm(code);
          this.resetChangesMade();
        }}
        onCancel={(): void => this.resetChangesMade()}
      />
    );
  }

  private renderSuccessPopUpMessage(): ReactNode {
    if (this.state.successMessage) {
      return (
        <SuccessDialog
          successMsg={this.state.successMessage}
          onClose={(): void => this.setState({ successMessage: undefined })}
        />
      );
    }
  }

  private renderNameInputs(): JSX.Element {
    const hasName = !!this.state.firstname || !!this.state.lastname;
    return (
      <Grid item xs={12} sm={10} md={8}>
        <Grid item>
          <TextField
            fullWidth={true}
            label={translations.user.inputs.firstname()}
            type="text"
            margin="normal"
            variant="outlined"
            inputProps={{ "data-testid": "first-name-field" }}
            value={this.state.firstname ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({
                firstname: event.target.value,
                error: undefined,
                successMessage: undefined,
                nameChangesMade: true,
              });
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth={true}
            label={translations.user.inputs.lastname()}
            type="text"
            margin="normal"
            variant="outlined"
            inputProps={{ "data-testid": "last-name-field" }}
            value={this.state.lastname ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({
                lastname: event.target.value,
                error: undefined,
                successMessage: undefined,
                nameChangesMade: true,
              });
            }}
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            disabled={!hasName || !this.state.nameChangesMade}
            variant="contained"
            color="primary"
            onClick={this.handleNameSubmit}
            data-testid="change-name-button"
          >
            {translations.user.buttons.confirmNameChange()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private renderEmailInputs(): JSX.Element {
    const { email } = this.state;
    const validEmail = email && validateEmail(email) === EmailResult.Valid;

    return (
      <Grid item xs={12} sm={10} md={8}>
        <Grid item>
          <TextField
            fullWidth={true}
            label={translations.user.inputs.email()}
            type="text"
            margin="normal"
            variant="outlined"
            inputProps={{ "data-testid": "email-field" }}
            value={email ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({
                email: event.target.value,
                error: undefined,
                successMessage: undefined,
                emailChangesMade: true,
              });
            }}
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            disabled={!this.state.emailChangesMade || !validEmail}
            variant="contained"
            color="primary"
            data-testid="change-email-button"
            onClick={this.handleEmailSubmit}
          >
            {translations.user.buttons.updateEmail()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private renderEmailEnteringMessage(): ReactNode {
    const { email, emailChangesMade } = this.state;
    if (!email || !emailChangesMade) return;
    const validationResult = validateEmail(email);

    let translation = "";

    switch (validationResult) {
      case EmailResult.Valid:
        return;
      case EmailResult.MissingAt:
        translation = translations.user.texts.missingAtCharacter();
        break;
      case EmailResult.MissingUsername:
        translation = translations.user.texts.missingUsername();
        break;
      case EmailResult.MissingDomain:
        translation = translations.user.texts.missingDomain();
        break;
    }

    return this.renderErrorText(translation);
  }

  private renderPhoneNumberInputs(): JSX.Element {
    const { phoneNumber } = this.state;
    const validPhoneNumber = phoneNumber && validatePhoneNumber(phoneNumber) === PhoneNumberResult.Valid;

    return (
      <Grid item xs={12} sm={10} md={8}>
        <Grid item>
          <TextField
            fullWidth={true}
            label={translations.user.inputs.phonenumber()}
            type="text"
            margin="normal"
            variant="outlined"
            inputProps={{ "data-testid": "phone-number-field" }}
            value={this.state.phoneNumber ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              this.setState({
                phoneNumber: event.target.value,
                error: undefined,
                successMessage: undefined,
                phoneChangesMade: true,
              });
            }}
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            disabled={!this.state.phoneChangesMade || !validPhoneNumber}
            variant="contained"
            color="primary"
            data-testid="change-phone-number-button"
            onClick={this.handlePhoneNumberSubmit}
          >
            {translations.user.buttons.updatePhoneNumber()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private renderPhoneNumberEnteringMessage(): ReactNode {
    const { phoneNumber, phoneChangesMade } = this.state;
    if (!phoneNumber || !phoneChangesMade) return;
    const validationResult = validatePhoneNumber(phoneNumber);

    let translation = "";

    switch (validationResult) {
      case PhoneNumberResult.Valid:
        return;
      case PhoneNumberResult.MissingPlus:
        translation = translations.user.texts.invalidPhoneNumber();
        break;
      case PhoneNumberResult.TooShort:
      case PhoneNumberResult.BadCharacters:
        translation = translations.user.texts.invalidPhoneNumberFormat();
        break;
    }

    return this.renderErrorText(translation);
  }

  private renderLocalisationInputs(): JSX.Element {
    const localisedLanguages = SUPPORTED_LANGUAGES.map((code) => ({
      key: code,
      label: translations.user.languages[code](),
    }));
    return (
      <Grid item xs={12} sm={10} md={8}>
        <Grid item>
          <DropdownSelection
            selectionList={localisedLanguages}
            onSelect={(selection?: number): void => {
              this.setState({
                language: selection !== undefined ? SUPPORTED_LANGUAGES[selection] : undefined,
                error: undefined,
                successMessage: undefined,
                languageChangesMade: true,
              });
            }}
            currentSelection={SUPPORTED_LANGUAGES.findIndex((language) => language === this.state.language)}
            variant="outlined"
            fullWidth={true}
            margin="normal"
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            disabled={!this.state.languageChangesMade}
            variant="contained"
            color="primary"
            data-testid="change-language-button"
            onClick={this.handleChangeLanguageSubmit}
          >
            {translations.user.buttons.changeLanguage()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private renderErrorText(text: string): JSX.Element {
    return (
      <Grid item xs={10} sm={8} md={8} lg={6}>
        <Typography className="user-errortext" align={"center"}>
          {text}
        </Typography>
      </Grid>
    );
  }

  private renderHeading(text: string): JSX.Element {
    return (
      <Grid item>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {text}
        </Typography>
      </Grid>
    );
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoading) {
      return (
        <Grid item container justifyContent="center">
          <Loader />
        </Grid>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Grid container spacing={4}>
        <Grid item container justifyContent="center">
          {this.renderHeading(translations.user.texts.enterFirstnameAndLastname())}
          <Grid item container justifyContent="center">
            {this.renderNameInputs()}
          </Grid>
        </Grid>
        <Grid item container spacing={2} justifyContent="center">
          {this.renderHeading(translations.user.texts.enterEmail())}
          <Grid item container justifyContent="center">
            {this.renderEmailInputs()}
          </Grid>
          {this.renderEmailEnteringMessage()}
        </Grid>
        <Grid item container justifyContent="center">
          {this.renderHeading(translations.user.texts.enterPhoneNumber())}
          <Grid item container justifyContent="center">
            {this.renderPhoneNumberInputs()}
          </Grid>
          {this.renderPhoneNumberEnteringMessage()}
        </Grid>
        <Grid item container justifyContent="center">
          {this.renderHeading(translations.user.texts.chooseLanguage())}
          <Grid item container justifyContent="center">
            {this.renderLocalisationInputs()}
          </Grid>
        </Grid>
        {this.renderLoader()}
        {this.renderErrorPopUpMessage()}
        {this.renderSuccessPopUpMessage()}
        {this.renderConfirmChangeDialog()}
      </Grid>
    );
  }
}
