/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

/**
 * Converts an object into an object with the same keys but values are strings.
 * If the value was a string, it is kept the same. Simple values are converted with toString() and complex objects
 * are converted using JSON.stringify().
 *
 * @param obj
 *    object to convert
 */
export function toStringObject(obj: Record<string, unknown>): Record<string, string> {
  const entries = Object.entries(obj);
  const stringValuedEntries = entries
    .filter(([, v]) => v != null && typeof v !== "function")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map(([k, v]) => [k, typeof v === "object" ? JSON.stringify(v) : (v as any).toString()]);
  return Object.fromEntries(stringValuedEntries);
}

/**
 * Filter down the given array to new array with only unique items.
 * @param array
 */
export function onlyUniqueElements<T>(array: T[]): T[] {
  return [...new Set(array)];
}
