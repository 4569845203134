/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { DeviceState } from "../../data/device/DeviceState";
export class ReferenceHWState extends DeviceState {
    get gpsFix() {
        return this.reported.gpsFix;
    }
    get chargerConnected() {
        return this.reported.chargerConnected;
    }
    get batterySoc() {
        return this.reported.batterySoc;
    }
    get modem() {
        return this.reported.modem;
    }
    get signalStrength() {
        return this.reported.signalStrength;
    }
    get firmwareVersion() {
        return this.reported.deviceFwVersion;
    }
    get resetCount() {
        return this.reported.resetCount;
    }
    static instanceOf(state) {
        return state instanceof ReferenceHWState;
    }
}
