/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { TypedAppSyncClient } from "./TypedAppSyncClient";
import { AUTH_TYPE } from "aws-appsync-auth-link";
import { AppConfiguration, AuthListener } from "../auth";
import { Auth } from "@aws-amplify/auth";
import { AWSAppSyncClient } from "aws-appsync";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import usersFragments from "../../generated/fragments/users.json";
import dataFragments from "../../generated/fragments/data.json";
import deviceFragments from "../../generated/fragments/device.json";
import eventsFragments from "../../generated/fragments/events.json";
import statsFragments from "../../generated/fragments/stats.json";
// using non-zero positive values in order for client caching to work
export var Service;
(function (Service) {
    Service[Service["USERS"] = 1] = "USERS";
    Service[Service["DATA"] = 2] = "DATA";
    Service[Service["DEVICE"] = 3] = "DEVICE";
    Service[Service["EVENTS"] = 4] = "EVENTS";
    Service[Service["STATS"] = 5] = "STATS";
    Service[Service["MEASUREMENT"] = 6] = "MEASUREMENT";
})(Service || (Service = {}));
// using prime numbers to avoid client cache collisions
export var ClientType;
(function (ClientType) {
    ClientType[ClientType["TYPE_COGNITO"] = 3] = "TYPE_COGNITO";
    ClientType[ClientType["TYPE_IAM"] = 5] = "TYPE_IAM";
})(ClientType || (ClientType = {}));
// TODO: merge this with AppSyncClientFactory
export class AppSyncClientProvider {
    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.clientCache = []; // sparse array
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.clientCache = [];
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
    }
    getTypedClient(service, type = ClientType.TYPE_COGNITO) {
        const innerClient = this.getAppSyncClient(service, type);
        return new TypedAppSyncClient(innerClient);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAppSyncClient(service, type = ClientType.TYPE_COGNITO) {
        const cacheSlot = service * type;
        if (!this.clientCache[cacheSlot]) {
            this.clientCache[cacheSlot] = this.createClient(service, type);
        }
        return this.clientCache[cacheSlot];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createClient(service, type) {
        let authOptions;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cache;
        if (type === ClientType.TYPE_IAM) {
            authOptions = {
                credentials: () => Auth.currentCredentials(),
                type: AUTH_TYPE.AWS_IAM,
            };
        }
        else if (type === ClientType.TYPE_COGNITO) {
            authOptions = {
                // using Identity Token for authentication, otherwise our custom claims cannot be added to the auth token.
                // this has the distinct downside that the token cannot be invalidated from "server" side, only timeout will
                // clean identity tokens. maybe one day AWS will allow custom claims on access tokens :fingers-crossed:
                jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
                type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            };
        }
        else {
            throw new Error("Unknown AppSync authentication requested!");
        }
        const introspectionQueryResultData = this.getFragmentDefinitions(service);
        if (introspectionQueryResultData) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
            cache = new InMemoryCache({ fragmentMatcher });
        }
        const awsConfig = AppConfiguration.getAwsConfiguration();
        return new AWSAppSyncClient({
            auth: authOptions,
            region: awsConfig.AppSync.Region,
            url: this.getEndpoint(service, awsConfig),
            disableOffline: true,
        }, {
            defaultOptions: {
                query: {
                    fetchPolicy: "network-only",
                },
            },
            cache,
        });
    }
    getEndpoint(service, awsConfig) {
        switch (service) {
            case Service.USERS:
                return awsConfig.AppSync.GqlEndpointUsers;
            case Service.DATA:
                return awsConfig.AppSync.GqlEndpointData;
            case Service.DEVICE:
                return awsConfig.AppSync.GqlEndpointDevice;
            case Service.EVENTS:
                return awsConfig.AppSync.GqlEndpointEvents;
            case Service.STATS:
                return awsConfig.AppSync.GqlEndpointStats;
            case Service.MEASUREMENT:
                return awsConfig.AppSync.GqlEndpointMeasurement;
            default:
                throw new Error("Unknown AppSync service requested");
        }
    }
    getFragmentDefinitions(service) {
        switch (service) {
            case Service.USERS:
                return usersFragments;
            case Service.DATA:
                return dataFragments;
            case Service.DEVICE:
                return deviceFragments;
            case Service.EVENTS:
                return eventsFragments;
            case Service.STATS:
                return statsFragments;
            default:
                return;
        }
    }
}
