/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import { Chart } from "react-google-charts";
import Loader from "../../ui/loader";
import { ChartArea, EventChartRow } from "../../../types/chartprops";
import { Grid } from "@material-ui/core";
import { Event, EventType, Nullable } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { convertDateToString, convertTimestampToString, DateTimeFormatTarget } from "../../../utils/TimeUtils";

interface Props {
  chartTitle?: string;
  events: Event[];
  startTimestamp?: number;
  endTimestamp?: number;
}

interface State {
  chartData?: EventChartRow[];
}

const CHART_AREA: ChartArea = {
  bottom: 5,
  top: 50,
  height: "10%",
  width: "80%",
};

export default class EventChart extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
    this.setChartData();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.events.length !== this.props.events.length) {
      this.setChartData();
    }
  }

  private setChartData(): void {
    if (this.props.events != null) {
      // Title row is static date + event types
      const titleRow: EventChartRow = [translations.events.data.date(), EventType.Generic, EventType.Sensor];
      const chartData: EventChartRow[] = [titleRow];

      if (this.props.startTimestamp && this.props.endTimestamp) {
        const currentDate = new Date(this.props.startTimestamp);
        const endDate = new Date(this.props.endTimestamp);
        // set comparable times as we are only interested about the dates here
        // current time can be anything in a day
        // end time should include all possible times in a day
        currentDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        // Loop through period, add chart row with zero values for each day
        while (currentDate.getTime() <= endDate.getTime()) {
          const eventChartRow: EventChartRow = [
            convertDateToString(currentDate, DateTimeFormatTarget.DayMonthYear),
            0,
            0,
          ];
          chartData.push(eventChartRow);
          currentDate.setDate(currentDate.getDate() + 1);
        }

        this.props.events?.forEach((value: Event) => {
          const eventDate = convertTimestampToString(Number(value.timestamp), DateTimeFormatTarget.DayMonthYear);

          // Find correct chart item based on the event date
          const chartItem = chartData.find((item: EventChartRow) => item[0] === eventDate);

          if (chartItem !== undefined) {
            const type = value.type ?? EventType.Generic;
            // Find index for correct type count
            const typeIndex = titleRow.findIndex((titleItem) => titleItem === type);
            // If index found, increase that, otherwise handle as generic
            // TODO: should there be "UNKNOWN" type?
            const actualIndex =
              typeIndex > -1 ? typeIndex : titleRow.findIndex((titleItem) => titleItem === EventType.Generic);
            chartItem[actualIndex] = Number(chartItem[typeIndex]) + 1;
          }
        });
        this.setState({ chartData });
      }
    } else {
      this.setState({ chartData: [] });
    }
  }

  public render(): Nullable<JSX.Element> {
    if (!this.state.chartData?.length) {
      return null;
    }

    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Chart
          chartType="Bar"
          loader={<Loader />}
          data={this.state.chartData}
          options={{
            chartArea: CHART_AREA,
            title: this.props.chartTitle,
          }}
          style={{
            width: "95%",
            height: "60%",
          }}
        />
      </Grid>
    );
  }
}
