/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, Fragment } from "react";
import { translations } from "../../../generated/translationHelper";

export default class PasswordRequirements extends Component {
  public render(): JSX.Element {
    return (
      <Fragment>
        <p className="login-password-requirements-header">{translations.logIn.texts.passwordMustContain()}</p>
        <ul className="login-password-requirements">
          <li>{translations.logIn.texts.passwordNumber({ count: 1 })}</li>
          <li>{translations.logIn.texts.passwordLength({ min: 8, max: 256 })}</li>
          <li>{translations.logIn.texts.passwordLowercase({ count: 1 })}</li>
        </ul>
      </Fragment>
    );
  }
}
