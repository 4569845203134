/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { DataUtil } from "./Data";
import { DataSet } from "./DataSet";
import { DataSubscriptionManager } from "./DataSubscriptionManager";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { DevicesMeasurementsListDocument } from "../../generated/gqlData";
import { isDefined } from "../../common/isDefined";
export class AWSDataSet extends DataSet {
    constructor(device, type, startTimestamp, endTimestamp) {
        super();
        this.device = device;
        this.type = type;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.data = [];
        this.dataListener = {
            getId: () => {
                return this.getId();
            },
            onData: (data) => {
                this.appendData(data);
            },
        };
        this.timestampOrder = (a, b) => a.timestamp - b.timestamp;
    }
    async fetch() {
        var _a, _b, _c;
        let nextToken;
        let data = [];
        try {
            do {
                const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
                const dataResponse = await appSyncClient.query(DevicesMeasurementsListDocument, {
                    deviceId: this.device.getId(),
                    startTimestamp: this.startTimestamp.toString(),
                    endTimestamp: this.endTimestamp.toString(),
                    nextToken,
                }, 
                // mobile-app specific: Force fetch or will return old data from cache
                {
                    fetchPolicy: "network-only",
                });
                nextToken = (_a = dataResponse.data.devicesMeasurementsList) === null || _a === void 0 ? void 0 : _a.nextToken;
                const dataItems = ((_c = (_b = dataResponse.data.devicesMeasurementsList) === null || _b === void 0 ? void 0 : _b.measurementItems) !== null && _c !== void 0 ? _c : [])
                    .map((item) => DataUtil.parseDataFragment(item, this.type))
                    .filter(isDefined);
                data = data.concat(dataItems);
            } while (nextToken);
            this.setData(data);
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    getId() {
        return this.device.getId();
    }
    getData() {
        return [...this.data];
    }
    getType() {
        return this.type;
    }
    getTimePeriod() {
        return { startTimestamp: this.startTimestamp, endTimestamp: this.endTimestamp };
    }
    setData(data) {
        const newData = [...data];
        newData.sort(this.timestampOrder);
        this.data = newData;
    }
    appendData(data) {
        this.data.push(data);
        this.data.sort(this.timestampOrder);
        if (data.timestamp > this.endTimestamp) {
            this.endTimestamp = data.timestamp;
        }
        this.notifyAction((observer) => observer.onDataUpdate(this));
    }
    /**
     * Add observer for data updates
     *
     * Customization:
     * Current implementation registers for all receivers device returns.
     * Modify this list if state access needs different limits.
     *
     * @param observer
     */
    async addObserver(observer) {
        if (!this.data) {
            await this.fetch();
        }
        super.addObserver(observer);
        if (this.observerCount === 1) {
            DataSubscriptionManager.instance.addListener(this.dataListener, await this.device.getReceivers());
        }
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        if (this.observerCount === 0) {
            DataSubscriptionManager.instance.removeListener(this.dataListener);
        }
    }
    clearObservers() {
        super.clearObservers();
        DataSubscriptionManager.instance.removeListener(this.dataListener);
    }
}
