/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Button, Drawer } from "@material-ui/core";
import React, { Component, Fragment, ReactNode } from "react";
import { TableContent } from "../../types/tableprops";
import { ClientProperties, StatusDataRow, StatusEntry } from "../../utils/ClientProperties";
import DeviceSettingsButton from "../device-settings/device-settings-button";
import ArrowDouble from "../../assets/arrow-double-24px.svg";
import DataTable from "../ui/data-table";
import LocationIcon from "../ui/location-icon";
import StatusMap from "./components/status-map";
import Loader from "../ui/loader";
import {
  BackendFactory,
  BaseObservable,
  Data,
  Device,
  DeviceObserver,
  Organization,
  isDefined,
  LatestData,
  LatestDataObserver,
  Nullable,
  ReferenceHWState,
  ReferenceHWStateProperties,
} from "@sade/data-access";
import DeviceNavigationCache from "../../utils/DeviceNavigationCache";
import DeviceDrawer from "../drawers/device-drawer";
import { idFromProps, isDeviceInProps } from "../../utils/NavigationUtils";
import { onlyUniqueElements } from "../../utils/Generic";
import { translations } from "../../generated/translationHelper";
import { DrawerState } from "../../types/DrawerState";
import { getDisplayName } from "../../utils/GetDisplayName";
import { RouteComponentProps, withRouter } from "react-router-dom";

const DEVICE_ID_INDEX = 0;

interface Props extends RouteComponentProps {}

interface State {
  mapDrawerState: DrawerState;
  isLoading: boolean;
  selectedOrganizationId?: string;
  devices?: Device[];
  latestDataList?: LatestData[];
}

class StatusView extends Component<Props, State> implements LatestDataObserver, DeviceObserver {
  public constructor(props: Props) {
    super(props);

    this.state = {
      mapDrawerState: DrawerState.Open,
      isLoading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const restoredDevice = await DeviceNavigationCache.getInstance().navigateToCachedIfNoDeviceInPath(this.props);

    if (restoredDevice) {
      const deviceId = DeviceNavigationCache.getInstance().getSelectedDevice()?.getId();
      if (deviceId) await this.fullFetchForDevice(deviceId);
    } else if (!isDeviceInProps(this.props)) {
      this.setState({ isLoading: true });
      await this.fetchDevicesAndDataForOrganizations();
      this.setState({ isLoading: false });
    } else {
      const deviceId = idFromProps(this.props);
      if (deviceId) await this.fullFetchForDevice(deviceId);
    }
  }

  public async componentWillUnmount(): Promise<void> {
    this.state.devices?.forEach((device) => device.removeObserver(this));
    this.state.latestDataList?.forEach((latestData) => latestData.removeObserver(this));
    await DeviceNavigationCache.getInstance().setCurrentGroup();
  }

  // LatestDataObserver
  public onDataUpdate(latestData: LatestData): void {
    console.debug(`onDataUpdate(${latestData.getId()}, ${latestData.getData()?.deviceId})`);
    if (this.state.latestDataList) {
      const device = this.state.devices?.find((device) => device.getId() === latestData.getId());

      if (device) {
        this.setState({ latestDataList: onlyUniqueElements([...this.state.latestDataList, latestData]) });
      } else {
        console.error("Received latest data update for unknown device", latestData.getId());
      }
    } else {
      console.error(`Received update for device's (${latestData.getId()}) latest data with no known latest data`);
    }
  }

  // DeviceObserver
  public async onDeviceStateUpdated(device: Device): Promise<void> {
    console.debug(`onDeviceStateUpdated(${device.getId()})`);

    if (this.state.devices) {
      const devices = onlyUniqueElements([...this.state.devices, device]);
      this.setState({ devices: [...devices] });

      if (!this.state.latestDataList?.find((data) => data.getId() === device.getId())) {
        console.log("No latest data for", device.getId());
        await this.fetchLatestDataForDevices(devices);
      }
    } else {
      console.error(`Received update from a device '${device.getId()}' with no known devices`);
    }
  }

  private async fullFetchForDevice(deviceId: string): Promise<void> {
    this.setState({ isLoading: true });
    const device = await BackendFactory.getBackend().getDevice(deviceId);

    if (device) {
      const deviceOrgId = device.getAttribute("organization");
      this.setState({ selectedOrganizationId: deviceOrgId });
      await this.fetchDevicesAndDataForOrganizations();
    } else {
      console.error(`No device found for id ${deviceId}`);
      await DeviceNavigationCache.getInstance().navigateToDevice(this.props);
    }
    this.setState({ isLoading: false });
  }

  private async fetchDevicesAndDataForOrganizations(): Promise<void> {
    let queryStr = "connectivity.connected:true";

    if (this.state.selectedOrganizationId) {
      queryStr += " AND attributes.organization:" + this.state.selectedOrganizationId.replace(/(:|\/)/gi, "\\$1");
    } else {
      const homeOrgId = (await BackendFactory.getOrganizationBackend().getCurrentHomeOrganization()).getId();
      queryStr += " AND attributes.organization:" + homeOrgId.replace(/(:|\/)/gi, "\\$1");
    }

    const devices = await BackendFactory.getBackend().searchDevices(queryStr);
    this.updateObserverRegistrations(devices, this.state.devices);

    await this.fetchLatestDataForDevices(devices);

    this.setState({ devices });
  }

  private async fetchLatestDataForDevices(devices: Device[]): Promise<void> {
    console.log(`Fetching the latest data for ${devices.length}`);
    const latestDataList = await Promise.all(devices.map((device: Device) => device.getLatestData())).then(
      (latestData) => latestData.filter(isDefined)
    );
    this.updateObserverRegistrations(latestDataList, this.state.latestDataList);
    this.setState({ latestDataList });
  }

  private updateObserverRegistrations<TObservable extends BaseObservable<unknown>>(
    newObservables?: TObservable[],
    oldObservables?: TObservable[]
  ): void {
    const oldSet = new Set(oldObservables ?? []);
    const sharedObservables = new Set(newObservables?.filter((observable) => oldSet.has(observable)));

    oldObservables
      ?.filter((observable) => !sharedObservables.has(observable))
      .forEach((observable) => observable.removeObserver(this));
    newObservables
      ?.filter((observable) => !observable.isObservedBy(this))
      .forEach((observable) => observable.addObserver(this));
  }

  private getDataList(): Data[] {
    if (!this.state.latestDataList) return [];

    return this.state.latestDataList.map((latestData) => latestData.getData()).filter(isDefined);
  }

  private getTableData(): TableContent {
    let tableHeader: string[] = [];
    const tableData: StatusDataRow[] = [];

    const dataList = this.getDataList();
    dataList.forEach((data: Data) => {
      const rowDevice = this.state.devices?.find((device: Device) => device.getId() === data.deviceId);

      if (rowDevice) {
        let statusRow: StatusEntry[] = [
          { title: translations.common.data.deviceId(), value: rowDevice.getId() },
          { title: translations.common.data.displayName(), value: getDisplayName(rowDevice) },
        ];
        const deviceState = rowDevice.getState() as Nullable<ReferenceHWState<ReferenceHWStateProperties>>;
        const statusRowEntries: StatusEntry[] = ClientProperties.getStatusRowEntries(data);
        statusRow = statusRow.concat(statusRowEntries);
        statusRow.push({
          title: "",
          value: (
            <Fragment>
              <DeviceSettingsButton device={rowDevice} isIcon={true} />
              <LocationIcon
                locationStatus={deviceState?.gpsFix ?? undefined}
                updateMilliseconds={deviceState?.getStateUpdatedTimestampMillis()}
              />
            </Fragment>
          ),
        });
        tableHeader = statusRow.map((entry: StatusEntry) => {
          return entry.title;
        });
        const statusTableRowData = statusRow.map((entry: StatusEntry) => {
          return entry.value;
        });
        tableData.push(statusTableRowData);
      }
    });

    return {
      tableHeader,
      tableData,
    };
  }

  private isLocationInData(): boolean {
    const filteredDataList = this.getDataList();
    return filteredDataList.some((data: Data) => data["latitude"] != null && data["longitude"] != null);
  }

  private handleTableRowSelect = async (index: number, key?: string): Promise<void> => {
    if (!this.getTableData().tableData) return;

    const tableData = this.getTableData().tableData;
    let selectedRow: StatusDataRow = tableData[index];

    if (key) {
      const row = tableData.filter((td: StatusDataRow) => {
        return td[DEVICE_ID_INDEX] === key;
      });

      if (row && row.length > 0) {
        selectedRow = row[0];
      }
    }
    const rowDeviceId = selectedRow[DEVICE_ID_INDEX].toString();

    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, rowDeviceId);

    if (this.isLocationInData()) {
      this.setState({
        mapDrawerState: DrawerState.Open,
      });
    }
  };

  private openDrawer = (): void => {
    this.setState({
      mapDrawerState: DrawerState.Open,
    });
  };

  private openFullDrawer = (): void => {
    this.setState({
      mapDrawerState: DrawerState.Full,
    });
  };

  private closeDrawer = (): void => {
    this.setState({
      mapDrawerState: DrawerState.Closed,
    });
  };

  private handleDeviceSelect = async (device?: Device): Promise<void> => {
    const deviceId = device?.getId();
    if (deviceId === idFromProps(this.props)) return;

    if (deviceId) {
      await this.fullFetchForDevice(deviceId);
    } else {
      this.updateObserverRegistrations([], this.state.latestDataList);
      this.updateObserverRegistrations([], this.state.devices);
      this.setState({
        selectedOrganizationId: undefined,
        devices: undefined,
        latestDataList: undefined,
      });
    }
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, device);
  };

  private handleOrgSelect = async (organization?: Organization): Promise<void> => {
    const organizationId = organization?.getId();
    if (this.state.selectedOrganizationId === organizationId) return;

    this.setState({ selectedOrganizationId: organizationId });

    if (organizationId) {
      await this.fetchDevicesAndDataForOrganizations();
    } else {
      this.updateObserverRegistrations([], this.state.latestDataList);
      this.updateObserverRegistrations([], this.state.devices);
      this.setState({
        selectedOrganizationId: organization?.getId(),
        devices: undefined,
        latestDataList: undefined,
      });
    }
  };

  private renderButtons(): ReactNode {
    if (this.state.mapDrawerState === DrawerState.Open) {
      return (
        <div className="drawer-button-container">
          <Button className="drawer-button close" onClick={this.closeDrawer} data-testid="close-map-button">
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
          <Button onClick={this.openFullDrawer} className="drawer-button visible" data-testid="open-map-button">
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
        </div>
      );
    }

    if (this.state.mapDrawerState === DrawerState.Full) {
      return (
        <div className="drawer-button-container">
          <Button className="drawer-button close" onClick={this.openDrawer} data-testid="close-map-button">
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
        </div>
      );
    }
  }

  private renderMapDrawer(): ReactNode {
    if (!this.isLocationInData()) return;

    const filteredData = this.getDataList();

    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={this.state.mapDrawerState !== DrawerState.Closed}
        transitionDuration={500}
        classes={{
          paper:
            (this.state.mapDrawerState === DrawerState.Full
              ? "col-xsm-12 col-sm-12 col-md-12 col-lg-12"
              : "col-xsm-12 col-sm-12 col-md-12 col-lg-4") + " iot-content-drawer",
        }}
      >
        <div className="status-map-container" data-testid="device-map">
          <StatusMap
            mapData={filteredData}
            drawerState={this.state.mapDrawerState}
            // Needed for marker displayNames:
            devices={this.state.devices}
          />
        </div>
        {this.renderButtons()}
      </Drawer>
    );
  }

  private renderTable(): ReactNode {
    if (this.state.mapDrawerState === DrawerState.Full) return;
    if (this.state.isLoading) return <Loader />;

    const tableData = this.getTableData();
    return (
      <div className="table-wrapper" data-testid="devices-data-table">
        <DataTable
          title={translations.status.texts.activeDevices()}
          rowsPerPageDefault={20}
          header={tableData.tableHeader}
          data={tableData.tableData}
          stickyHeader={false}
          onTableRowSelect={this.handleTableRowSelect}
        />
      </div>
    );
  }

  private renderSidebarButton(): ReactNode {
    if (this.state.devices && this.state.mapDrawerState !== DrawerState.Open && this.isLocationInData()) {
      return (
        <div className="drawer-button-container">
          <Button onClick={this.openDrawer} className="drawer-button">
            <img src={ArrowDouble} alt="Forward arrow" />
          </Button>
        </div>
      );
    }
  }

  private renderDeviceDrawer(): ReactNode {
    return (
      <DeviceDrawer
        onDeviceSelect={this.handleDeviceSelect}
        selectedDeviceId={idFromProps(this.props)}
        onOrganizationSelect={this.handleOrgSelect}
      />
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderDeviceDrawer()}
        {this.renderMapDrawer()}
        <div
          className="iot-content-container col-xsm-12 col-sm-12 col-md-12 col-lg-12"
          style={
            this.state.mapDrawerState === DrawerState.Open && this.isLocationInData()
              ? { marginLeft: "33%", width: "66%" }
              : this.state.mapDrawerState === DrawerState.Full
              ? { width: "0%" }
              : undefined
          }
        >
          {this.renderSidebarButton()}
          {this.renderTable()}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(StatusView);
