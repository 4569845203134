/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Button, FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select";
import React, { Component } from "react";
import { Data, Device, isDefined, Maybe } from "@sade/data-access";
import { toStringObject } from "../../../utils/Generic";
import { CSVLink } from "react-csv";
import { translations } from "../../../generated/translationHelper";
import getSensorName from "../../../utils/GetSensorName";
import { getDisplayName } from "../../../utils/GetDisplayName";

interface Props {
  onSensorSelect: (sensors: string[], sensors2: string[]) => void;
  data: Data[];
  selectedDevice: Device;
  allowCsvDownload: boolean;
}

interface State {
  selectedSensor: string[];
  selectedSensor2: string[];
  availableSensors?: string[];
  csvData?: Record<string, unknown>[];
}

const METADATA_KEYS = new Set([
  "__typename",
  "timestamp",
  "deviceId",
  "sessionId",
  "unprocessed",
  "organization",
  "type",
]);

export default class IoTHistoryTools extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedSensor: [],
      selectedSensor2: [],
    };
  }

  public componentDidMount(): void {
    this.generateCSV();
    this.setAvailableSensors();
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.data !== prevProps.data) {
      if (this.props.allowCsvDownload) this.generateCSV();
      this.setAvailableSensors();
    }

    if (
      this.state.selectedSensor !== prevState.selectedSensor ||
      this.state.selectedSensor2 !== prevState.selectedSensor2
    ) {
      this.props.onSensorSelect(this.state.selectedSensor, this.state.selectedSensor2);
    }
  }

  private setAvailableSensors(): void {
    if (this.props.data.length !== 0) {
      // TODO:  This needs to be refactored, too complex solution. Idea is that cloud splits data into to
      //        parts (metadata and actual sensor data)
      const sensorSet = this.props.data.reduce((set, data) => {
        Object.keys(data)
          .filter((key) => !METADATA_KEYS.has(key))
          .forEach(set.add.bind(set));
        return set;
      }, new Set<string>());
      const sensors = [...sensorSet].filter(isDefined);

      if (this.state.selectedSensor.length > 0) {
        this.setState({
          availableSensors: sensors,
        });

        if (!this.state.selectedSensor.every((item) => sensors.includes(item))) {
          console.log("Sensor set changed, resetting selection");
          this.setState({
            selectedSensor: [sensors[0]],
          });
        }
      } else {
        this.setState({
          availableSensors: sensors,
          selectedSensor: [sensors[0]],
        });
      }
    }
  }

  private generateCSV(): void {
    console.log("Creating CSV export");

    const csv = this.props.data.map((data) => {
      const stringObject = toStringObject(data);
      const entries = Object.entries(stringObject)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [getSensorName(k), v]);
      return Object.fromEntries(entries);
    });

    this.setState({ csvData: csv });
  }

  private selectSensor1 = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const value: string[] = event.target.value as string[];

    if (value[value.length - 1] === "") {
      this.setState({ selectedSensor: [] });
    } else {
      this.setState({ selectedSensor: value });
    }
  };

  private selectSensor2 = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const value: string[] = event.target.value as string[];

    if (value[value.length - 1] === "") {
      this.setState({ selectedSensor2: [] });
    } else {
      this.setState({ selectedSensor2: value });
    }
  };

  private getCSVName = (): string => {
    return getDisplayName(this.props.selectedDevice) + ".csv";
  };

  private listSensors = (): Maybe<JSX.Element[]> => {
    if (this.state.availableSensors != null && this.state.availableSensors.length !== 0) {
      // to enable alphabetical sorting in the pull-up menu, the original sensorId names need to be paired with their UI label names:
      const availableSensorsListPaired = this.state.availableSensors.map((sensorId: string) => ({
        id: sensorId,
        label: getSensorName(sensorId),
      }));

      const pairedListSorted = availableSensorsListPaired.sort((sensorBlobA, sensorBlobB) => {
        const labelA = sensorBlobA.label.toLowerCase();
        const labelB = sensorBlobB.label.toLowerCase();
        if (labelA < labelB) return -1;
        else if (labelA > labelB) return 1;
        return 0;
      });

      return pairedListSorted.map((sensorBlob) => (
        <MenuItem key={sensorBlob.id} value={sensorBlob.id}>
          {sensorBlob.label}
        </MenuItem>
      ));
    }
  };

  private renderValue = (selected: SelectProps["value"]): string => {
    const selectedStringList: string[] = selected as string[];
    const sensorNames: string[] = [];

    if (selectedStringList.length === 0) {
      return translations.common.inputs.none();
    } else if (selectedStringList.length > 0 && selectedStringList.length < 3) {
      selectedStringList.forEach((sensor: string) => {
        const newSensor = getSensorName(sensor);
        sensorNames.push(newSensor);
      });
      return sensorNames.join(", ");
    } else {
      return String(selectedStringList.length) + " sensors selected";
    }
  };

  private renderSensorSelection1 = (): Maybe<JSX.Element> => {
    if (this.state.availableSensors) {
      return (
        <div className="select-sensor-container left">
          <FormControl className="select-sensor-picker">
            <Select
              multiple={true}
              renderValue={this.renderValue}
              value={this.state.selectedSensor}
              onChange={this.selectSensor1}
              name="sensor-selection1"
              displayEmpty={true}
            >
              <MenuItem value="">
                <em>{translations.common.inputs.none()}</em>
              </MenuItem>
              {this.listSensors()}
            </Select>
          </FormControl>
        </div>
      );
    }
  };

  private renderSensorSelection2 = (): Maybe<JSX.Element> => {
    if (this.state.availableSensors) {
      return (
        <div className="select-sensor-container right">
          <FormControl className="select-sensor-picker">
            <Select
              multiple={true}
              renderValue={this.renderValue}
              value={this.state.selectedSensor2}
              onChange={this.selectSensor2}
              displayEmpty={true}
              name="sensor-selection2"
            >
              <MenuItem value="">
                <em>{translations.common.inputs.none()}</em>
              </MenuItem>
              {this.listSensors()}
            </Select>
          </FormControl>
        </div>
      );
    }
  };

  private renderCSVExportButton = (): JSX.Element => {
    return (
      <div className="export-container">
        <Typography
          variant="subtitle1"
          align="left"
          style={{
            marginRight: "5rem",
            display: "inline-block",
          }}
        >
          {translations.history.texts.dragToZoomRightClickToReset()}
        </Typography>
        {this.state.csvData != null && (
          <CSVLink filename={this.getCSVName()} data={this.state.csvData}>
            <Button
              variant="contained"
              color="primary"
              data-testid="export-csv-button"
              disabled={!this.props.allowCsvDownload}
            >
              {translations.history.buttons.exportAsCSV()}
            </Button>
          </CSVLink>
        )}
      </div>
    );
  };

  public render(): JSX.Element {
    return (
      <div className="stats-tools-container">
        {this.renderSensorSelection1()}
        {this.renderSensorSelection2()}
        {this.renderCSVExportButton()}
      </div>
    );
  }
}
