/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, ReactNode } from "react";
import { Maybe, Device, DeviceState } from "@sade/data-access";
import { getDisplayName } from "../../../../utils/GetDisplayName";
import { Button, TableCell, TableRow } from "@material-ui/core";
import { translations } from "../../../../generated/translationHelper";
import DeviceSettingsButton from "../../../device-settings/device-settings-button";
import { getConnectionStateIcon } from "../../../device-browsing/helpers/icon-factory";

interface Props {
  device: Device;
  onButtonClick: (device: Device) => void;
}

interface State {
  loading: boolean;
  deviceState: Maybe<DeviceState>;
}

export default class DeviceListItem extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = { loading: false, deviceState: props.device.getState() };
  }

  private onDeleteButtonClick = (): void => {
    this.props.onButtonClick(this.props.device);
  };

  private getFirmwareVersion(): string {
    const state = this.state.deviceState;
    return state?.firmwareVersion ?? translations.common.texts.N_A();
  }

  private renderDeleteButton(): ReactNode {
    return (
      <Button onClick={this.onDeleteButtonClick} variant="contained" color="primary">
        {translations.common.buttons.remove()}
      </Button>
    );
  }

  private renderSettingsButton(): ReactNode {
    return <DeviceSettingsButton device={this.props.device} isIcon={true} />;
  }

  private renderDeviceIcon(): ReactNode {
    return <span dangerouslySetInnerHTML={{ __html: this.props.device.getIcon() }}></span>;
  }

  private renderConnectionStatusIcon(): ReactNode {
    const icon = getConnectionStateIcon(this.state.deviceState);

    if (icon) {
      return <img className="status-icon" src={icon} alt={translations.deviceDrawer.alts.connectionState()}></img>;
    } else {
      return null;
    }
  }

  public render(): ReactNode {
    return (
      <TableRow key={this.props.device.getId()}>
        <TableCell component="td" scope="row">
          {this.renderDeviceIcon()}
        </TableCell>
        <TableCell align="center">{this.renderConnectionStatusIcon()}</TableCell>
        <TableCell align="left">{getDisplayName(this.props.device)}</TableCell>
        <TableCell align="center">{this.props.device.getId()}</TableCell>
        <TableCell align="center">{this.props.device.getType()}</TableCell>
        <TableCell align="center">{this.getFirmwareVersion()}</TableCell>
        <TableCell align="right">{this.renderSettingsButton()}</TableCell>
        <TableCell align="right">{this.renderDeleteButton()}</TableCell>
      </TableRow>
    );
  }
}
