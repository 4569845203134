/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { AWSDataSet } from "../data/AWSDataSet";
import { AWSLatestData } from "../data/AWSLatestData";
import { AWSSessionSet } from "../data/AWSSessionSet";
import { AWSEventSet } from "../events/AWSEventSet";
import { Device } from "./Device";
import { ShadowSubscriptionManager } from "./ShadowSubscriptionManager";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { narrowDownAttributeTypes } from "../backend/AWSBackend";
import { DevicesDeleteDocument, DevicesEncryptDocument, DevicesStatesGetDocument, DevicesUpdateDocument, } from "../../generated/gqlDevice";
import { AWSThingGroup } from "./AWSThingGroup";
import { PromiseSemaphore } from "../private-utils/PromiseSemaphore";
import { throwGQLError } from "../private-utils/throwGQLError";
import { validate as isUuid } from "uuid";
import { AuthWrapper } from "../auth";
/**
 * Base-class for typed AWS Thing implementations. Do not create this directly.
 *
 * This class is no longer abstract since TypeScript does not allow for run-time type comparison against an
 * abstract class (since abstract things do not exist in TypeScript at run-time).
 */
export class AWSThing extends Device {
    /*
     * DO NOT CALL DIRECTLY
     *
     * This constructor needs to be public so {@link EntityRelationCache} can use it for type checks.
     */
    constructor(type, backend, params) {
        super();
        this.type = type;
        this.backend = backend;
        this.entityType = AWSThing;
        this.groupSemaphore = new PromiseSemaphore(() => this.backend.linkDeviceGroupsForDevice(this));
        this.stateListener = {
            getId: () => {
                return this.getId();
            },
            onState: (timestamp, version, current, next, connectionState) => {
                this.setState(timestamp, version, current, next, connectionState);
            },
        };
        this.deviceId = params.deviceId;
        this.attributes = params.attributes;
    }
    async getGroups() {
        await this.groupSemaphore.guard();
        return this.backend.entityRelationCache.listFor(this, AWSThingGroup);
    }
    getAttribute(key) {
        var _a, _b;
        return (_b = (_a = this.attributes) === null || _a === void 0 ? void 0 : _a.find((attribute) => attribute.key === key)) === null || _b === void 0 ? void 0 : _b.value;
    }
    getAttributes() {
        var _a;
        return (_a = this.attributes) !== null && _a !== void 0 ? _a : [];
    }
    /*
     * Customization:
     * Current implementation returns all receivers from canSee field
     * which user is allowed use.
     * Modify this if access needs different limits.
     */
    async getReceivers() {
        var _a, _b;
        const canSee = this.getAttribute("canSee");
        const deviceReceivers = canSee === null || canSee === void 0 ? void 0 : canSee.slice(1, canSee.length - 1).split(",").map((value) => (value.endsWith(":") ? value.slice(0, value.length - 1) : value)).filter((value) => value.startsWith("ORG/") || isUuid(value));
        // filter receivers user has no access
        const userReceivers = (_a = (await AuthWrapper.getCurrentAuthenticatedUserClaims())) === null || _a === void 0 ? void 0 : _a.canSee;
        return ((_b = deviceReceivers === null || deviceReceivers === void 0 ? void 0 : deviceReceivers.filter((r) => userReceivers === null || userReceivers === void 0 ? void 0 : userReceivers.some((allowed) => allowed === r || r.startsWith(allowed)))) !== null && _b !== void 0 ? _b : []);
    }
    getId() {
        return this.deviceId;
    }
    getType() {
        return this.type;
    }
    getState() {
        return this.state;
    }
    /**
     * Add observer for device state updates
     *
     * Customization:
     * Current implementation registers for all receivers device returns.
     * Modify this list if state access needs different limits.
     *
     * @param observer
     */
    // TODO: Consider moving this functionality inside DeviceState
    async addObserver(observer) {
        super.addObserver(observer);
        ShadowSubscriptionManager.instance.addListener(this.stateListener, await this.getReceivers());
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        ShadowSubscriptionManager.instance.removeListener(this.stateListener);
    }
    onRelationChange(change) {
        if (change.ofType(AWSThingGroup)) {
            this.notifyAction((observer) => { var _a; return (_a = observer.onDeviceGroupsChanged) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
        }
    }
    async init() {
        var _a;
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const deviceStateResponse = await client.query(DevicesStatesGetDocument, {
                deviceId: this.deviceId,
            }, 
            // mobile-app specific: Force fetch or will return old data from cache
            {
                fetchPolicy: "network-only",
            });
            const { desired, reported, timestamp, version, connectionState } = (_a = deviceStateResponse.data.devicesStatesGet) !== null && _a !== void 0 ? _a : {};
            this.state = this.createState(timestamp, version, reported ? JSON.parse(reported) : undefined, desired ? JSON.parse(desired) : undefined, connectionState);
        }
        catch (error) {
            console.error("init", error);
        }
    }
    setState(timestamp, version, current, next, connectionState) {
        var _a, _b;
        if (version && ((_a = this.state) === null || _a === void 0 ? void 0 : _a.version) && version < this.state.version) {
            console.log("New state version " + version + " is older than " + ((_b = this.state) === null || _b === void 0 ? void 0 : _b.version));
            return;
        }
        // TODO: Consider implementing a method that just updates the existing state
        this.state = this.createState(timestamp, version, current, next, connectionState);
        this.notifyAction((observer) => { var _a; return (_a = observer.onDeviceStateUpdated) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
    }
    async getSessionSet(startTimestamp, endTimestamp) {
        try {
            const sessionSet = new AWSSessionSet(this.deviceId, startTimestamp, endTimestamp);
            await sessionSet.fetch();
            return sessionSet;
        }
        catch (error) {
            console.error("getSessionSet", error);
        }
    }
    async getDataSet(startTimestamp, endTimestamp) {
        try {
            if (this.dataSet &&
                this.dataSet.getTimePeriod().startTimestamp === startTimestamp &&
                this.dataSet.getTimePeriod().endTimestamp === endTimestamp) {
                return this.dataSet;
            }
            const dataSet = new AWSDataSet(this, this.type, startTimestamp, endTimestamp);
            await dataSet.fetch();
            this.dataSet = dataSet;
            return this.dataSet;
        }
        catch (error) {
            console.error("getDataSet", error);
        }
    }
    async getLatestData() {
        try {
            if (!this.latestData) {
                this.latestData = new AWSLatestData(this, this.type);
                await this.latestData.fetch();
            }
            return this.latestData;
        }
        catch (error) {
            console.error("getLatestData", error);
        }
    }
    async getEventSet(startTimestamp, endTimestamp) {
        try {
            if (this.eventSet &&
                startTimestamp === this.eventSet.getTimePeriod().startTimestamp &&
                endTimestamp === this.eventSet.getTimePeriod().endTimestamp) {
                return this.eventSet;
            }
            if (this.eventSet) {
                this.eventSet.changeTimePeriod(startTimestamp, endTimestamp);
            }
            else {
                // fetch new event set
                this.eventSet = new AWSEventSet(this, startTimestamp, endTimestamp);
            }
            await this.eventSet.fetch();
            return this.eventSet;
        }
        catch (error) {
            console.error("getEventSet", error);
        }
    }
    // TODO: can user set null values for attr's value?
    async updateAttributes(attributes) {
        var _a, _b, _c;
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.mutate(DevicesUpdateDocument, {
                deviceId: this.deviceId,
                attributes,
            });
            this.attributes = narrowDownAttributeTypes((_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesUpdate) === null || _b === void 0 ? void 0 : _b.attr) !== null && _c !== void 0 ? _c : []);
        }
        catch (error) {
            console.error("updateAttributes", error);
        }
    }
    async delete() {
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            await client.mutate(DevicesDeleteDocument, { deviceId: this.deviceId });
            await this.backend.removeLocal(this);
            return true;
        }
        catch (error) {
            console.error("Delete device failed: ", error);
        }
        return false;
    }
    /**
     * Encrypt a message.
     *
     * Encryption API uses public key (RSA) encryption where the public key is derived from device certificate.
     * @param message Message to encrypt
     * @returns Encrypted message
     */
    async encrypt(message) {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const response = await client.query(DevicesEncryptDocument, { deviceId: this.deviceId, message });
        if (!response.data.devicesEncrypt) {
            throwGQLError(response, "Encryption failed");
        }
        return response.data.devicesEncrypt;
    }
    // OVERRIDE THIS
    createState(_timestamp, _version, _reported, _desired, _connectionState) {
        throw new Error("AWSThing MUST NOT be created directly");
    }
    // OVERRIDE THIS
    getIcon() {
        throw new Error("AWSThing MUST NOT be created directly");
    }
    static instanceOf(value) {
        return value instanceof AWSThing;
    }
}
