/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { translations } from "../../generated/translationHelper";
import DateFnsUtils from "@date-io/date-fns";
import { Nullable, Timestamp, timestampToMillisecondPrecision } from "@sade/data-access";
import { DateTimeFormatTarget, getDateTimeFormat } from "../../utils/TimeUtils";

interface Props {
  startTimestamp?: Timestamp;
  endTimestamp?: Timestamp;
  disabled: boolean;
  onTimeRangeSelect: (startTimestamp: Timestamp, endTimestamp: Timestamp) => void;
}

interface State {
  startTimestamp?: Timestamp;
  endTimestamp?: Timestamp;
}

export default class TimeRangePicker extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    const start = this.props.startTimestamp;
    const end = this.props.endTimestamp;

    this.state = {
      startTimestamp: start != null ? timestampToMillisecondPrecision(start) : undefined,
      endTimestamp: end != null ? timestampToMillisecondPrecision(end) : undefined,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.startTimestamp !== prevProps.startTimestamp || this.props.endTimestamp !== prevProps.endTimestamp) {
      this.setState({
        startTimestamp: this.props.startTimestamp,
        endTimestamp: this.props.endTimestamp,
      });
    }
  }

  private setStartDateTime(date: Nullable<Date>): void {
    if (date) {
      const timestamp = date.getTime();
      this.setState({ startTimestamp: timestamp });
    }
  }

  private setEndDateTime(date: Nullable<Date>): void {
    if (date) {
      const timestamp = date.getTime();
      this.setState({ endTimestamp: timestamp });
    }
  }

  private handleSearch = (): void => {
    if (this.state.startTimestamp && this.state.endTimestamp && this.state.endTimestamp >= this.state.startTimestamp) {
      this.props.onTimeRangeSelect(this.state.startTimestamp, this.state.endTimestamp);
    }
  };

  public render(): JSX.Element {
    const startDatum = this.state.startTimestamp ? new Date(this.state.startTimestamp) : undefined;
    const endDatum = this.state.endTimestamp ? new Date(this.state.endTimestamp) : undefined;

    return (
      <Grid container={true} spacing={2} alignItems="center">
        <Grid item={true}>
          {/* TODO: https://github.com/mui/material-ui-pickers/issues/1440 */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <DateTimePicker
                  autoOk={true}
                  ampm={false}
                  disableFuture={true}
                  value={startDatum}
                  onChange={(date): void => this.setStartDateTime(date)}
                  disabled={this.props.disabled}
                  label={translations.common.inputs.from()}
                  format={getDateTimeFormat(DateTimeFormatTarget.DateTimePicker)}
                  data-testid="datetime-from-input"
                />
              </Grid>
              <Grid item={true}>
                <DateTimePicker
                  autoOk={true}
                  ampm={false}
                  disableFuture={true}
                  value={endDatum}
                  onChange={(date): void => this.setEndDateTime(date)}
                  disabled={this.props.disabled}
                  label={translations.common.inputs.to()}
                  format={getDateTimeFormat(DateTimeFormatTarget.DateTimePicker)}
                  data-testid="datetime-to-input"
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item={true}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleSearch}
            disabled={this.props.disabled}
            data-testid="datetime-submit-button"
          >
            {translations.common.buttons.search()}
          </Button>
        </Grid>
      </Grid>
    );
  }
}
