/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { HyperHW } from "../../devices/HyperHW/HyperHW";
import { SuperHW } from "../../devices/SuperHW/SuperHW";
import { RuuviTagHW } from "../../devices/RuuviTagHW/RuuviTagHW";
const DEVICE_CONSTRUCTORS = {
    [SuperHW.type]: SuperHW,
    [HyperHW.type]: HyperHW,
    [RuuviTagHW.type]: RuuviTagHW,
};
export class AWSDeviceFactory {
    createDevice(backend, type, params) {
        const ctor = DEVICE_CONSTRUCTORS[type];
        if (ctor) {
            return new ctor(backend, params);
        }
        else {
            console.error(`Could not locate device implementation for type ${type}`);
        }
    }
    listDeviceTypes() {
        return Object.keys(DEVICE_CONSTRUCTORS);
    }
}
