/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Device, DeviceGroup, Maybe } from "@sade/data-access";
import { getDisplayName } from "../../../utils/GetDisplayName";

function filterString(filter: SearchFilter, haystack: string): boolean {
  if (typeof filter.searchToken === "string") {
    return haystack === filter.searchToken;
  } else {
    return filter.searchToken.test(haystack);
  }
}

export default interface SearchFilter {
  searchToken: string | RegExp;
}

export function getCaseInsensitiveSearchFilter(searchString: string): Maybe<SearchFilter> {
  if (searchString) {
    return {
      searchToken: new RegExp(searchString, "i"),
    };
  }
}

export function searchFilterDevice(filter: SearchFilter, device: Device): boolean {
  const haystack = getDisplayName(device);
  return filterString(filter, haystack);
}

export function searchFilterDeviceGroup(filter: SearchFilter, group: DeviceGroup): boolean {
  const haystack = group.getLabel();
  return filterString(filter, haystack);
}
