/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Drawer, Grid, IconButton } from "@material-ui/core";
import React, { Component, Fragment, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../types/routerprops";
import {
  Data,
  DataObserver,
  DataSet,
  Device,
  ReferenceHWData,
  Session,
  SessionSet,
  TimePeriod,
  timePeriodIsValid,
} from "@sade/data-access";
import IoTHistoryTools from "./components/history-tools";
import LiveControl from "../ui/live-control";
import SessionPicker from "./components/session-picker";
import TimeRangePicker from "../ui/time-range-picker";
import Loader from "../ui/loader";
import ArrowDouble from "../../assets/arrow-double-24px.svg";
import IoTChart from "./components/history-chart";
import IoTMap from "./components/history-map";
import { DeviceAndTime, DeviceChangeType, idFromProps, parseDeviceAndTime } from "../../utils/NavigationUtils";
import { DeviceChangeResultHandler } from "../../utils/DeviceChangeResultHandler";
import DeviceNavigationCache from "../../utils/DeviceNavigationCache";
import DeviceDrawer from "../drawers/device-drawer";
import { translations } from "../../generated/translationHelper";
import { ContentSplash } from "../ui/content-splash";
import { getDisplayName } from "../../utils/GetDisplayName";

type Props = RouteComponentProps<DevicePathRouterProps>;

interface State {
  isLoading: boolean;
  drawerOpen: boolean;
  selectedSensor: string[];
  selectedSensor2: string[];
  liveMode: boolean;
  selectedLocation?: number[];
  dataSet?: DataSet;
  sensorData?: Data[];
  sessionSet?: SessionSet;
  selectedSession?: Session;
  device?: Device;
}

const DEFAULT_TIME_WINDOW_MS: number = 2 * 60 * 60 * 1000;
const RESET_STATE = {
  dataSet: undefined,
  liveMode: false,
  selectedLocation: undefined,
  drawerOpen: false,
  device: undefined,
};

class HistoryView extends Component<Props, State> implements DataObserver {
  private readonly deviceChangeHandler = new DeviceChangeResultHandler({
    stateResetCallback: (): void => this.setState(RESET_STATE),
    deviceChangeCallback: (id: string): Promise<void> => this.changeDevice(id),
  });

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      drawerOpen: false,
      selectedSensor: [],
      selectedSensor2: [],
      liveMode: false,
    };
  }

  private static getDefaultTimePeriod(): TimePeriod {
    const endTimestamp = Date.now();
    return {
      endTimestamp,
      startTimestamp: endTimestamp - DEFAULT_TIME_WINDOW_MS,
    };
  }

  public async componentDidMount(): Promise<void> {
    const deviceResolveResult = DeviceNavigationCache.getInstance().predictDeviceChange(this.props);

    if (deviceResolveResult === DeviceChangeType.ChangedToNew) {
      const { deviceId, timePeriod } = parseDeviceAndTime(this.props);
      if (deviceId) await this.changeDevice(deviceId, timePeriod);
    } else if (deviceResolveResult === DeviceChangeType.WillRestore) {
      const deviceId = DeviceNavigationCache.getInstance().getSelectedDevice()?.getId();
      if (deviceId) await this.setUrlFromRouteInfo({ deviceId, timePeriod: {} });
    }
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    await DeviceNavigationCache.getInstance().resolveDeviceChange(this.deviceChangeHandler, this.props, prevProps);
  }

  public componentWillUnmount(): void {
    this.state.dataSet?.removeObserver(this);
  }

  public async onDataUpdate(dataSet: DataSet): Promise<void> {
    if (this.state.liveMode && dataSet.getId() === this.state.device?.getId()) {
      this.setState({ sensorData: dataSet.getData() });
      await this.setUrlFromRouteInfo({ deviceId: this.state.device.getId(), timePeriod: dataSet.getTimePeriod() });
    }
  }

  private async setUrlFromRouteInfo(routeInfo?: DeviceAndTime): Promise<void> {
    await DeviceNavigationCache.getInstance().navigateToDeviceAndTime(this.props, routeInfo);
    this.setState({ device: DeviceNavigationCache.getInstance().getSelectedDevice() });
  }

  private async changeDevice(newDeviceId: string, timePeriod?: Partial<TimePeriod>): Promise<void> {
    this.state.dataSet?.removeObserver(this);

    const setNewDevice = async (): Promise<void> => {
      const newTimePeriod =
        timePeriod && timePeriodIsValid(timePeriod) ? timePeriod : HistoryView.getDefaultTimePeriod();
      const device = await DeviceNavigationCache.getInstance().setCurrentDevice(newDeviceId);

      this.setState({ device }, async () => {
        await Promise.all([this.updateUrlTimestampAndFetchData(newTimePeriod), this.fetchSessions()]);
        this.setState({ isLoading: false });
      });
    };

    this.setState({ ...RESET_STATE, isLoading: true }, setNewDevice);
  }

  private async fetchData(timePeriod: TimePeriod): Promise<void> {
    if (!this.state.device || !timePeriodIsValid(timePeriod)) {
      return;
    }
    this.setState({ isLoading: true });

    try {
      const dataSet = await this.state.device.getDataSet(timePeriod.startTimestamp, timePeriod.endTimestamp);
      this.state.dataSet?.removeObserver(this);

      if (dataSet) {
        if (this.state.liveMode) dataSet.addObserver(this);
        this.setState({ dataSet, sensorData: dataSet.getData() });
        this.setLocationToLatest(dataSet);
      }
    } catch (error) {
      console.error("fetchData", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private async fetchSessions(): Promise<void> {
    if (this.state.device) {
      const sessionSet = await this.state.device.getSessionSet(0, Date.now());
      this.setState({ sessionSet });
    }
  }

  private handlePointSelect = (timestamp: number): void => {
    const dataItems = (this.state.dataSet?.getData() ?? []) as ReferenceHWData[];
    const item = dataItems.find((item) => Number(item.timestamp) === timestamp && item.latitude && item.longitude);

    if (item) {
      this.setState({
        selectedLocation: [item.longitude, item.latitude],
        liveMode: false,
        drawerOpen: true,
      });
    } else if (this.state.selectedLocation) {
      this.setState({ selectedLocation: undefined });
    }
  };

  private isLocationInData(): boolean {
    return this.state.sensorData?.some((entry) => entry.latitude != null && entry.longitude != null) ?? false;
  }

  private setLocationToLatest(dataSet: DataSet): void {
    let selectedLocation: number[] | undefined = undefined;

    const data = dataSet.getData();

    if (data) {
      const lastDataPoint = data[data.length - 1] as ReferenceHWData;

      if (lastDataPoint?.longitude && lastDataPoint.latitude) {
        selectedLocation = [lastDataPoint.longitude, lastDataPoint.latitude];
      }
    }
    this.setState({ selectedLocation });
  }

  private handleTimeRangeSelect = async (startTimestamp: number, endTimestamp: number): Promise<void> => {
    this.setState({ selectedSession: undefined });
    await this.updateUrlTimestampAndFetchData({ startTimestamp, endTimestamp });
  };

  private handleLiveDataToggle = (): void => {
    const enablingLiveMode = !this.state.liveMode && !!this.state.device;

    if (enablingLiveMode) {
      this.setState({ liveMode: true }, async () => {
        const { timePeriod } = parseDeviceAndTime(this.props);
        const { startTimestamp } = HistoryView.getDefaultTimePeriod();
        const newTimePeriod = {
          startTimestamp: timePeriod.startTimestamp ?? startTimestamp,
          endTimestamp: Date.now(),
        };
        await this.updateUrlTimestampAndFetchData(newTimePeriod);
      });
    } else {
      this.setState({ liveMode: false });
      this.state.dataSet?.removeObserver(this);
    }
  };

  private async updateUrlTimestampAndFetchData(timePeriod: TimePeriod): Promise<void> {
    await Promise.all([
      this.setUrlFromRouteInfo({ deviceId: this.state.device?.getId(), timePeriod }),
      await this.fetchData(timePeriod),
    ]);
  }

  private handleSensorSelect = (sensors: string[], sensors2: string[]): void => {
    this.setState({
      selectedSensor: sensors,
      selectedSensor2: sensors2,
    });
  };

  private handleSelectSession = async (session?: Session): Promise<void> => {
    if (!session) return;
    const timePeriod = {
      startTimestamp: Number(session.sessionId),
      endTimestamp: Number(session.sessionId) + Number(session.durationMs),
    };
    await Promise.all([
      this.fetchData(timePeriod),
      this.setUrlFromRouteInfo({ deviceId: session.deviceId, timePeriod }),
    ]);
    this.setState({ selectedSession: session });
  };

  private handleToggleDrawer = (): void => {
    this.setState((prevState: State) => ({
      drawerOpen: !prevState.drawerOpen,
    }));
  };

  private handleDeviceSelect = async (device?: Device): Promise<void> => {
    await DeviceNavigationCache.getInstance().navigateToDevice(this.props, device);
    this.setState({ device: DeviceNavigationCache.getInstance().getSelectedDevice() });
  };

  private renderErrorMessage(): ReactNode {
    if (this.state.isLoading) return;

    if (this.state.device && (this.state.dataSet?.getData().length ?? 0) === 0) {
      return (
        <ContentSplash>
          <p>
            <span>{translations.history.texts.deviceSelected({ deviceName: getDisplayName(this.state.device) })}</span>
          </p>
          <p>
            <span>{translations.history.texts.noTimeSeriesDataFound()}</span>
          </p>
        </ContentSplash>
      );
    } else if (!this.state.device) {
      return (
        <ContentSplash>
          <p>
            <span>{translations.history.texts.pleaseSelectDeviceAndTimePeriod()}</span>
          </p>
        </ContentSplash>
      );
    }
  }

  private renderToolbar(): ReactNode {
    if (!this.state.device) return;

    const { startTimestamp, endTimestamp } = parseDeviceAndTime(this.props).timePeriod;
    return (
      <div className="iot-tool-container col-xsm-12 col-sm-9">
        <Grid container={true} spacing={2} alignItems="center" style={{ height: "100%", margin: 0 }}>
          {this.state.sessionSet && this.state.sessionSet.getSessions().length > 0 && (
            <Grid item={true}>
              <SessionPicker
                sessions={this.state.sessionSet.getSessions()}
                onSelectSession={this.handleSelectSession}
                disabled={this.state.liveMode}
                selectedSession={this.state.selectedSession}
              />
            </Grid>
          )}
          <Grid item={true}>
            <TimeRangePicker
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              onTimeRangeSelect={this.handleTimeRangeSelect}
              disabled={this.state.liveMode}
            />
          </Grid>
          <Grid item={true}>
            <LiveControl liveData={this.state.liveMode} onLiveDataToggle={this.handleLiveDataToggle} />
          </Grid>
        </Grid>
      </div>
    );
  }

  private renderLoader(): ReactNode {
    return <Loader show={this.state.isLoading} />;
  }

  private renderSidebar(): ReactNode {
    if (!this.state.device || !this.isLocationInData()) return;

    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={this.state.drawerOpen}
        transitionDuration={500}
        classes={{ paper: "col-sm-4 col-xsm-9 iot-content-drawer" }}
      >
        <div className="history-map-container" data-testid="device-map">
          <IoTMap selectedLocation={this.state.selectedLocation} mapsData={this.state.dataSet?.getData()} />
        </div>
        <div className="drawer-button-container">
          <IconButton className="drawer-button close" onClick={this.handleToggleDrawer} data-testid="close-map-button">
            <img src={ArrowDouble} alt="forward arrow" />
          </IconButton>
        </div>
      </Drawer>
    );
  }

  private renderSidebarButton(): ReactNode {
    if (this.state.drawerOpen || !this.isLocationInData()) return;

    return (
      <div className="drawer-button-container">
        <IconButton onClick={this.handleToggleDrawer} className="drawer-button" data-testid="open-map-button">
          <img src={ArrowDouble} alt="forward arrow" />
        </IconButton>
      </div>
    );
  }

  private renderChart(): ReactNode {
    if (!this.state.sensorData?.length) return;

    return (
      <div className="chart-container" data-testid="device-chart">
        <IoTChart
          onPointSelect={this.handlePointSelect}
          selectedSensor={this.state.selectedSensor}
          selectedSensor2={this.state.selectedSensor2}
          data={this.state.sensorData}
        />
      </div>
    );
  }

  private renderHistoryTools(): ReactNode {
    if (this.state.device && this.state.sensorData && this.state.sensorData.length > 1) {
      return (
        <IoTHistoryTools
          onSensorSelect={this.handleSensorSelect}
          data={this.state.sensorData}
          selectedDevice={this.state.device}
          allowCsvDownload={!this.state.liveMode}
        />
      );
    }
  }

  private renderDeviceDrawer(): ReactNode {
    return <DeviceDrawer onDeviceSelect={this.handleDeviceSelect} selectedDeviceId={idFromProps(this.props)} />;
  }

  private renderContent(): ReactNode {
    return (
      <div
        className="iot-content-container col-sm-12 col-xsm-12"
        style={this.state.drawerOpen ? { marginLeft: "33%", width: "66%" } : undefined}
      >
        {this.renderSidebarButton()}
        {this.renderErrorMessage()}
        {this.renderLoader()}
        {this.renderChart()}
        {this.renderHistoryTools()}
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderDeviceDrawer()}
        {this.renderToolbar()}
        {this.renderSidebar()}
        {this.renderContent()}
      </Fragment>
    );
  }
}

export default withRouter(HistoryView);
