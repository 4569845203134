/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { ReferenceHWState } from "../ReferenceHW/ReferenceHWState";
export class SuperHWState extends ReferenceHWState {
    get ledEnabled() {
        return this.deltaExists("ledEnabled") ? this.desired.ledEnabled : this.reported.ledEnabled;
    }
    set ledEnabled(enabled) {
        this.desired.ledEnabled = enabled;
        this.changedValues.ledEnabled = enabled;
    }
    get ledColor() {
        return this.deltaExists("ledColor") ? this.desired.ledColor : this.reported.ledColor;
    }
    set ledColor(color) {
        this.desired.ledColor = color;
        this.changedValues.ledColor = color;
    }
    static instanceOf(state) {
        return state instanceof SuperHWState;
    }
}
