/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import LocationOff from "../../assets/baseline-location_off-24px.svg";
import LocationOn from "../../assets/baseline-location_on-24px.svg";
import { translations } from "../../generated/translationHelper";
import { convertTimestampToString, DateTimeFormatTarget } from "../../utils/TimeUtils";

interface Props {
  locationStatus?: boolean;
  updateMilliseconds?: number;
}

export default class LocationIcon extends Component<Props> {
  private getLocationIcon(): string {
    return this.props.locationStatus ? LocationOn : LocationOff;
  }

  private getLocationAlt(): string {
    return this.props.locationStatus
      ? translations.status.texts.locationAvailable()
      : translations.status.texts.locationUnavailable();
  }

  private getTooltip = (): string => {
    let timestampAsString = translations.common.texts.N_A();

    const timestamp = this.props.updateMilliseconds;
    if (timestamp != null) {
      timestampAsString = convertTimestampToString(timestamp, DateTimeFormatTarget.ShadowUpdate);
    }
    return `${translations.deviceDrawer.texts.lastUpdated()}: ${timestampAsString}`;
  };

  public render(): ReactNode {
    if (!this.props.locationStatus == null) return null;

    return (
      <img
        className="status-icon"
        data-tip={this.getTooltip()}
        src={this.getLocationIcon()}
        alt={this.getLocationAlt()}
      />
    );
  }
}
