/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, Fragment, ReactNode } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { BackendFactory, Device, DeviceGroup, Organization } from "@sade/data-access";
import ErrorDialog from "../../../ui/error-dialog";
import DeviceListItem from "./device-list-item";
import NewDeviceForm from "./new-device-form";
import { translations } from "../../../../generated/translationHelper";
import ConfirmationDialog from "../../../ui/confirmation-dialog";
import { getDisplayName } from "../../../../utils/GetDisplayName";
import Loader from "../../../ui/loader";

interface Props {
  organization: Organization;
}

interface State {
  loading: boolean;
  showDeletionDialog: boolean;
  deviceGroup?: DeviceGroup;
  deviceToDelete?: Device;
  devices?: Device[];
  errorMsg?: string;
}

export default class DeviceList extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      deviceToDelete: undefined,
      showDeletionDialog: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.updateOrganization().then();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      this.updateOrganization().then();
    }
  }

  public async updateOrganization(): Promise<void> {
    await this.setState({ loading: true, devices: undefined });

    try {
      //Temporary fix to add devices to an organization, until the backend API gets updated
      //Dual setState is to fix an issue with the old list getting stuck being visible while backend is fetching the group
      const [deviceGroup, devices] = await Promise.all([
        BackendFactory.getBackend().findDeviceGroupByOrganization(this.props.organization.getId()),
        BackendFactory.getBackend().getOrganizationDevices(this.props.organization.getId()),
      ]);
      await this.setState({});
      devices.sort((a: Device, b: Device) => getDisplayName(a).localeCompare(getDisplayName(b)));
      this.setState({ deviceGroup, devices });
    } catch (error) {
      this.showError(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  private showError(error: unknown): void {
    const errorMsg = error instanceof Error ? error.message : translations.admin.notificationErrors.unknownError();
    if (errorMsg.startsWith("GraphQL")) {
      this.setState({ errorMsg: translations.admin.texts.addDeviceError() });
    } else {
      this.setState({ errorMsg });
    }
  }

  private addDeviceToOrganization = async (id: string): Promise<void> => {
    if (!this.state.deviceGroup) return;
    this.setState({ loading: true });
    try {
      // TODO: rewrite after API update
      await this.state.deviceGroup.addDevice(id);
      const addedDevice = await BackendFactory.getBackend().getDevice(id);
      const { devices } = this.state;
      if (addedDevice && devices) {
        this.setState({ devices: [...devices, addedDevice] });
      }
    } catch (error) {
      this.showError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleDeviceDeletionRequest = (device: Device): void => {
    this.setState({ showDeletionDialog: true, deviceToDelete: device });
  };

  private async deleteDevice(device: Device): Promise<void> {
    const success = await device.delete();
    const { devices } = this.state;
    if (!success) {
      throw new Error(translations.admin.texts.failedToRemoveDevice());
    } else if (devices) {
      // deleted device from the backend, delete it from state
      const index = devices.findIndex((dev) => dev.getId() === device.getId());
      if (index >= 0) {
        devices.splice(index, 1);
        this.setState({ devices: [...devices] });
      }
    }
  }

  private deleteDeviceFromList = async (): Promise<void> => {
    const { deviceToDelete } = this.state;
    if (!deviceToDelete) return;

    this.setState({ showDeletionDialog: false, loading: true });
    try {
      await this.deleteDevice(deviceToDelete);
      await this.setState({ deviceToDelete: undefined });
    } catch (error) {
      this.showError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private cancelDeletionDialog = (): void => {
    this.setState({ showDeletionDialog: true, deviceToDelete: undefined });
  };

  private renderDeviceList(): ReactNode | ReactNode[] {
    if (this.state.devices) {
      return this.state.devices?.map((device: Device) => {
        return <DeviceListItem key={device.getId()} device={device} onButtonClick={this.handleDeviceDeletionRequest} />;
      });
    }
  }

  private renderDeviceTable(): ReactNode {
    return (
      <Table className="org-groups-table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{translations.common.data.displayName()}</TableCell>
            <TableCell align="center">{translations.common.data.deviceId()}</TableCell>
            <TableCell align="center">{translations.common.data.type()}</TableCell>
            <TableCell align="center">{translations.deviceSettings.texts.firmwareVersion()}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this.renderDeviceList()}</TableBody>
      </Table>
    );
  }

  private renderDeletionPopup(): ReactNode {
    if (this.state.showDeletionDialog && this.state.deviceToDelete) {
      return (
        <ConfirmationDialog
          title={translations.admin.texts.deleteDevice()}
          message={translations.admin.texts.deleteDeviceConfirmation({ device: this.state.deviceToDelete.getId() })}
          onConfirm={this.deleteDeviceFromList}
          onCancel={this.cancelDeletionDialog}
        />
      );
    }
  }

  private renderErrorPopup(): ReactNode {
    if (!this.state.errorMsg) return;
    return <ErrorDialog errorMsg={this.state.errorMsg} onClose={(): void => this.setState({ errorMsg: undefined })} />;
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <NewDeviceForm addDeviceHandler={this.addDeviceToOrganization} />
        {this.state.loading ? <Loader /> : this.renderDeviceTable()}
        {this.renderDeletionPopup()}
        {this.renderErrorPopup()}
      </Fragment>
    );
  }
}
