/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { BaseObservable } from "../observer/BaseObservable";
import { prefixlessId } from "./Utils";
export class Organization extends BaseObservable {
    constructor(params) {
        super();
        this.id = params.id;
        this.name = params.name;
        this.parentId = params.parentOrganizationId;
    }
    static isParentOrEqualOrganizationId(organizationId, maybeParentOrganizationId) {
        const a = this.toComparableFormat(organizationId);
        const b = this.toComparableFormat(maybeParentOrganizationId);
        return a.startsWith(b);
    }
    static toComparableFormat(id) {
        return prefixlessId(id).endsWith(":") ? id : `${id}:`;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getParentOrganizationId() {
        return this.parentId;
    }
}
