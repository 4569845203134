/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { GoogleChartWrapper } from "react-google-charts/dist/types";
import { Data, Nullable } from "@sade/data-access";
import Loader from "../../ui/loader";
import {
  ChartAnimation,
  ChartArea,
  ChartDataRow,
  ChartExplorer,
  ChartLegend,
  ChartSeries,
} from "../../../types/chartprops";
import { translations } from "../../../generated/translationHelper";
import getSensorName from "../../../utils/GetSensorName";
import { DateTimeFormatTarget, getDateTimeFormat } from "../../../utils/TimeUtils";

interface Props {
  chartTitle?: string;
  onPointSelect: (timestamp: number) => void;
  selectedSensor: string[];
  selectedSensor2: string[];
  data: Data[];
}

interface State {
  sensorId: string[];
  chartData?: ChartDataRow[];
}

const CHART_AREA: ChartArea = {
  bottom: 60,
  top: 50,
  height: "70%",
  width: "80%",
};

const EXPLORER: ChartExplorer = {
  actions: ["dragToZoom", "rightClickToReset"],
  axis: "horizontal",
  keepInBounds: true,
  maxZoomIn: 100.0,
};

const LEGEND: ChartLegend = {
  display: true,
  position: "bottom",
};

const ANIMATION: ChartAnimation = {
  startup: true,
  easing: "out",
  duration: 1500,
};

export default class IoTChart extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      sensorId: [],
    };
  }

  public componentDidMount(): void {
    this.setChartData();
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return (
      this.props.data !== nextProps.data ||
      this.props.selectedSensor !== nextProps.selectedSensor ||
      this.props.selectedSensor2 !== nextProps.selectedSensor2 ||
      this.state.chartData !== nextState.chartData
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.data !== this.props.data ||
      prevProps.selectedSensor !== this.props.selectedSensor ||
      prevProps.selectedSensor2 !== this.props.selectedSensor2
    ) {
      this.setChartData();
    }
  }

  private onSelect(timestamp: number): void {
    this.props.onPointSelect(timestamp);
  }

  private setChartData(): void {
    if (this.props.data != null && this.props.data.length > 1) {
      const chartData: ChartDataRow[] = [["timestamp"]];
      const sensorId: string[] = [];

      const addSensor = (sensor: string): void => {
        if (sensor != null) {
          chartData[0].push(getSensorName(sensor));
          sensorId.push(sensor);
        }
      };

      // first headers
      this.props.selectedSensor.forEach(addSensor);
      this.props.selectedSensor2.forEach(addSensor);

      // then data
      const addSensorDataToChart = (column: ChartDataRow, item: Data, sensor: string): void => {
        if (sensor != null) {
          let data = item[sensor];

          if (typeof data === "boolean") {
            data = Number(data);
          }
          column.push(data);
        }
      };
      this.props.data.forEach((item: Data) => {
        const chartDataColumns: ChartDataRow = [new Date(Number(item.timestamp))];

        const sensorHandler = (sensor: string): void => addSensorDataToChart(chartDataColumns, item, sensor);

        this.props.selectedSensor.forEach(sensorHandler);
        this.props.selectedSensor2.forEach(sensorHandler);

        chartData.push(chartDataColumns);
      });
      this.setState({
        chartData,
        sensorId,
      });
    } else {
      this.setState({ chartData: [] });
    }
  }

  private getSeries(): ChartSeries {
    const series: ChartSeries = {};

    this.state.sensorId.forEach((sensorId: string, index: number) => {
      if (sensorId !== "timestamp" && this.props.selectedSensor.includes(sensorId)) {
        this.props.selectedSensor.forEach((sensor: string) => {
          if (sensor != null) {
            series[index] = {
              axis: this.state.chartData![0][index + 1] as string, // timestamp is first column
              targetAxisIndex: 0,
            };
          }
        });
      }

      if (sensorId !== "timestamp" && this.props.selectedSensor2.includes(sensorId)) {
        this.props.selectedSensor2.forEach((sensor2: string) => {
          if (sensor2 != null) {
            series[index] = {
              axis: this.state.chartData![0][index + 1] as string, // timestamp is first column
              targetAxisIndex: 1,
            };
          }
        });
      }
    });
    return series;
  }

  public render(): Nullable<JSX.Element> {
    if (this.props.data && this.props.data.length === 1) {
      return <span>{translations.history.texts.notEnoughDataForVisualisation()}</span>;
    }

    if (!this.state.chartData || this.state.chartData.length <= 1 || this.state.sensorId.length === 0) {
      return null;
    }

    return (
      <Chart
        chartType="LineChart"
        loader={<Loader />}
        data={this.state.chartData}
        options={{
          chartArea: CHART_AREA,
          title: this.props.chartTitle,
          explorer: EXPLORER,
          series: this.getSeries(),
          hAxis: {
            format: getDateTimeFormat(DateTimeFormatTarget.ChartTimeAxis),
          },
          legend: LEGEND,
          animation: ANIMATION,
          enableInteractivity: true,
          interpolateNulls: true,
        }}
        style={{
          width: "100%",
          height: "100%",
        }}
        formatters={[
          {
            type: "DateFormat",
            column: 0,
            options: {
              pattern: getDateTimeFormat(DateTimeFormatTarget.ChartTooltip),
            },
          },
        ]}
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }: { chartWrapper: GoogleChartWrapper }): void => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();

              if (selection.length === 1) {
                const [selectedItem] = selection;
                const { row } = selectedItem;

                if (row) {
                  this.onSelect(new Date(this.state.chartData![row][0]).getTime());
                }
              }
            },
          },
        ]}
      />
    );
  }
}
