/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import ConfirmationDialog from "../../../ui/confirmation-dialog";
import Loader from "../../../ui/loader";
import AddNotificationSubscriptionPopup from "./add-notification-subscription-popup";
import { translations } from "../../../../generated/translationHelper";
import {
  BackendFactory,
  EventDefinition,
  EventsRepository,
  NotificationState,
  NotificationSubscription,
  NotificationType,
  Organization,
  OrganizationObserver,
  User,
} from "@sade/data-access";
import WarningIcon from "../../../../assets/warning-24px.svg";

type EventSubscriptionsTableCell = string | number | RemoveCell | TypeCell | JSX.Element;
type EventSubscriptionsTableRow = EventSubscriptionsTableCell[];

interface Props {
  organization: Organization;
}

interface State {
  loading: boolean;
  users: User[];
  eventDefinitions: EventDefinition[];
  tableData: EventSubscriptionsTableRow[];
  removeSubscriptionAction?: RemoveCell;
  errorMsg?: string;
  showAddNotificationSubscriptionPopup: boolean;
}

interface RemoveCell {
  subscription: NotificationSubscription;
}

interface TypeCell {
  type: NotificationType;
  state: NotificationState[];
}

export default class EventManagement extends Component<Props, State> implements OrganizationObserver {
  private readonly errorMessages: Record<NotificationType, string> = {
    [NotificationType.Email]: translations.admin.notificationErrors.missingEmailAddress(),
    [NotificationType.Sms]: translations.admin.notificationErrors.missingPhoneNumber(),
    [NotificationType.Push]: translations.admin.notificationErrors.missingPushToken(),
  };
  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "event",
      label: translations.admin.texts.event(),
      options: {
        sort: true,
      },
    },
    {
      name: "userId",
      label: translations.admin.texts.user(),
      options: {
        sort: true,
      },
    },
    {
      name: "type",
      label: translations.admin.texts.type(),
      options: {
        sort: true,
        customBodyRender: (typeCell: TypeCell): JSX.Element => {
          return <React.Fragment>{this.getTypeAndStateCell(typeCell)}</React.Fragment>;
        },
        sortCompare: (order: "desc" | "asc") => {
          return ({ data: cell1 }, { data: cell2 }): number => {
            return order === "asc" ? cell1?.type?.localeCompare(cell2?.type) : cell2?.type?.localeCompare(cell1?.type);
          };
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (remove: RemoveCell): JSX.Element => {
          return (
            <div
              onClick={async (): Promise<void> => {
                this.setState({ removeSubscriptionAction: { subscription: remove.subscription } });
              }}
            >
              <IconButton
                title={translations.common.buttons.remove()}
                style={{ backgroundColor: "#e6e6e6" }}
                size="small"
              >
                <RemoveIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  private readonly tableOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    // TODO: Fix any type
    customToolbarSelect: (
      _selectedRows: unknown,
      _displayData: Array<{ data: unknown[]; dataIndex: number }>,
      _setSelectedRows: unknown
    ): React.ReactNode => {
      // TODO: This is not supported yet.
      return null;
    },
    onRowsDelete: (_rowsDeleted: unknown): void => {
      // TODO: This is not supported yet.
    },
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: false,
    search: false,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectToolbarPlacement: "none",
    textLabels: {
      body: {
        noMatch: translations.admin.texts.noNotificationsAvailable(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
    },
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      eventDefinitions: [],
      tableData: [],
      showAddNotificationSubscriptionPopup: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.props.organization.addObserver(this);
    await this.performLoad(this.getEvents(), this.updateUsers());
  }

  public async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      prevProps.organization.removeObserver(this);
      this.props.organization.addObserver(this);
      await this.performLoad(this.updateUsers());
    }

    if (prevState.users !== this.state.users) {
      await this.performLoad(this.updateTableData());
    }
  }

  public componentWillUnmount(): void {
    this.props.organization.removeObserver(this);
  }

  public onUsersChange(users: User[]): void {
    this.setState({ users });
  }

  private getTypeAndStateCell(cell: TypeCell): JSX.Element | undefined {
    const state = cell?.state as NotificationState[];
    if (state?.length >= 1) {
      if (state[0] === NotificationState.Ok) {
        return <div>{cell?.type}</div>;
      } else if (state.includes(NotificationState.MissingEndpoint)) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {cell?.type}&nbsp;
            <Tooltip title={this.errorMessages[cell?.type] ?? translations.admin.notificationErrors.unknownError()}>
              <img className="status-icon" width={16} src={WarningIcon} />
            </Tooltip>
          </div>
        );
      }
    }
  }

  private async performLoad(...promises: Promise<void>[]): Promise<void> {
    this.setState({ loading: true });

    try {
      await Promise.all(promises);
    } catch (err) {
      console.error("performLoad", err);
    }
    this.setState({ loading: false });
  }

  private async getNotificationSubscriptionsForUsers(users: User[]): Promise<NotificationSubscription[]> {
    const promises = users
      .map((user) => ({ userId: user.getId(), owningOrganizationId: this.props.organization.getId() }))
      .map((params) => BackendFactory.getNotificationBackend().getUserNotificationSubscriptions(params));
    return (await Promise.all(promises)).flat();
  }

  private async getEvents(): Promise<void> {
    try {
      this.setState({
        eventDefinitions: EventsRepository.instance.getAllEventDefinitions().sort((a, b) => {
          return a?.description && b?.description ? a.description.localeCompare(b.description) : 0;
        }),
      });
    } catch (err) {
      console.error("getEventRules", err);
    }
  }

  private getEventNames(eventIds: string[]): string {
    return this.state.eventDefinitions
      .filter((eventDefinition) => eventIds.includes(eventDefinition.eventId))
      .map((eventDefinition) => eventDefinition.name ?? eventDefinition.description)
      .join(", ");
  }

  private async updateTableData(): Promise<void> {
    const users = [...this.state.users];
    const notificationSubscriptions: NotificationSubscription[] = await this.getNotificationSubscriptionsForUsers(
      users
    );

    const tableData: EventSubscriptionsTableRow[] = notificationSubscriptions.map((subscription) => {
      return [
        this.getEventNames(subscription.eventIds),
        users.find((user) => subscription.userId === user.getUsername())?.getEmail() ??
          translations.common.texts.notAvailable(),
        { type: subscription.type, state: subscription.state },
        { subscription },
      ];
    });

    this.setState({ tableData });
  }

  private async updateUsers(): Promise<void> {
    try {
      this.setState({
        users: await this.props.organization.getUsers(),
      });
    } catch (err) {
      console.error("updateUsers", err);
      this.setState({
        users: [],
      });
    }
  }

  private handleConfirmRemoveSubscription = async (): Promise<void> => {
    if (!this.state.removeSubscriptionAction) {
      return;
    }

    this.setState({ loading: true, removeSubscriptionAction: undefined });

    try {
      const ret = await BackendFactory.getNotificationBackend().removeNotificationSubscription({
        userId: this.state.removeSubscriptionAction.subscription.userId,
        subscriptionId: this.state.removeSubscriptionAction.subscription.id,
      });

      if (ret) {
        await this.updateTableData();
      }
    } catch (error) {
      console.error("handleSubmitAction", error);
      this.setState({
        errorMsg: translations.admin.texts.failedToDeleteSubscription(),
      });
    }
    this.setState({ loading: false });
  };

  private handleAddIconClick = async (): Promise<void> => {
    this.setState({ showAddNotificationSubscriptionPopup: true });
  };

  private handleClosePopup = (): void => {
    this.setState({
      showAddNotificationSubscriptionPopup: false,
      errorMsg: undefined,
    });
  };

  private handleCancelRemoveSubscription = (): void => {
    this.setState({ removeSubscriptionAction: undefined });
  };

  private renderEventSubscriptionsTable(): ReactNode {
    const titleElement = (
      <div>
        <NotificationsIcon className="notification-organization-icon" />
        <Typography variant="h6">{translations.admin.texts.notificationManagement()}</Typography>
      </div>
    );

    return (
      <Fragment>
        <MUIDataTable
          title={titleElement}
          data={this.state.tableData}
          columns={this.tableColumns}
          options={this.tableOptions}
        />
        <Loader show={this.state.loading} />
      </Fragment>
    );
  }

  private renderButtonsRow(): ReactNode {
    if (this.state.loading) return;
    return (
      <div className="notification-buttons-container">
        <IconButton
          title={translations.common.buttons.add()}
          onClick={this.handleAddIconClick}
          style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginRight: "2rem", marginTop: "1rem" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </div>
    );
  }

  private renderRemoveSubscriptionPopup(): ReactNode {
    if (!this.state.removeSubscriptionAction) {
      return;
    }
    return (
      <ConfirmationDialog
        title={translations.admin.texts.removeSubscription()}
        message={translations.admin.texts.notificationFromUser({
          subscription: this.getEventNames(this.state.removeSubscriptionAction?.subscription.eventIds),
          type: this.state.removeSubscriptionAction.subscription.type,
          user:
            this.state.users
              .find((user) => this.state.removeSubscriptionAction?.subscription.userId === user.getUsername())
              ?.getEmail() ?? translations.common.texts.notAvailable(),
        })}
        onConfirm={this.handleConfirmRemoveSubscription}
        onCancel={this.handleCancelRemoveSubscription}
      />
    );
  }

  private renderAddNotificationSubscriptionPopup(): ReactNode {
    return (
      <AddNotificationSubscriptionPopup
        organization={this.props.organization}
        users={this.state.users}
        events={this.state.eventDefinitions}
        open={this.state.showAddNotificationSubscriptionPopup}
        onClose={async (success): Promise<void> => {
          this.handleClosePopup();

          if (success) {
            return this.updateTableData();
          }
        }}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderEventSubscriptionsTable()}
        {this.renderButtonsRow()}
        {this.renderAddNotificationSubscriptionPopup()}
        {this.renderRemoveSubscriptionPopup()}
      </Fragment>
    );
  }
}
