/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __rest } from "tslib";
import { Service } from "../backend/AppSyncClientProvider";
import { DeviceGroupsOtaUpdatesCancelDocument, DeviceGroupsOtaUpdatesStartDocument, OtaUpdatesBatchListDocument, OtaUpdatesBatchStartDocument, OtaUpdatesBatchStopDocument, DevicesOtaUpdatesCancelDocument, DevicesOtaUpdatesStartDocument, OtaUpdatesListDocument, OtaUpdatesStatesListDocument, OtaUpdatesStatesUpdateFeedDocument, OtaUpdatesBatchFeedDocument, } from "../../generated/gqlDevice";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { ReceiverManager } from "../receivers/ReceiverManager";
import { BaseObservable } from "../observer";
import { isDefined } from "../../common";
export class OtaManager extends BaseObservable {
    constructor() {
        super(...arguments);
        this.subscriptions = [];
    }
    static getInstance() {
        if (!OtaManager.instance) {
            OtaManager.instance = new OtaManager();
        }
        return this.instance;
    }
    static parseStateFragment(fragment) {
        const { timestamp } = fragment, rest = __rest(fragment, ["timestamp"]);
        return Object.assign({ timestamp: typeof timestamp === "string" ? Number.parseInt(timestamp) : timestamp }, rest);
    }
    async init(organizationId) {
        ReceiverManager.instance.addObserver(this);
        this.organizationId = organizationId;
        if (organizationId) {
            ReceiverManager.instance.addReceivers(organizationId);
        }
    }
    uninit() {
        if (this.organizationId) {
            ReceiverManager.instance.removeReceivers(this.organizationId);
        }
        ReceiverManager.instance.removeObserver(this);
        this.unsubscribe();
    }
    async getOtaUpdates(deviceType, forceRefresh) {
        if (!this.otaUpdates || forceRefresh) {
            return await this.fetchOtaUpdates(deviceType);
        }
        else {
            return this.otaUpdates;
        }
    }
    async getOtaUpdateStates(forceRefresh) {
        if (!this.otaUpdateStates || forceRefresh) {
            return await this.fetchOtaUpdateStates();
        }
        else {
            return this.otaUpdateStates;
        }
    }
    async getOtaBatchExecutions(forceRefresh) {
        if (!this.otaExecutions || forceRefresh) {
            return await this.fetchOtaBatchExecutions();
        }
        else {
            return this.otaExecutions;
        }
    }
    async stopOtaBatchExecution(id) {
        var _a, _b;
        console.log(`stopOtaBatchExecution ${id}`);
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.mutate(OtaUpdatesBatchStopDocument, { id });
            if (response.errors) {
                console.error("Failed to stop batch execution", response.errors);
                return false;
            }
            return (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.otaUpdatesBatchStop) !== null && _b !== void 0 ? _b : false;
        }
        catch (error) {
            console.error("Error", error);
            return false;
        }
    }
    async startOtaBatchExecution(query, otaId, maxCount, dailyMaxCount) {
        var _a;
        console.log(`startOtaBatchExecution ${query}, ${otaId}, ${maxCount}, ${dailyMaxCount}`);
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.mutate(OtaUpdatesBatchStartDocument, { query, otaId, maxCount, dailyMaxCount });
            if (response.errors) {
                console.error("Failed to start batch execution", response.errors);
                return;
            }
            return (_a = response.data) === null || _a === void 0 ? void 0 : _a.otaUpdatesBatchStart;
        }
        catch (error) {
            console.error("Error", error);
            return undefined;
        }
    }
    async triggerDeviceOtaUpdate(deviceId, otaId) {
        console.log(`triggerDeviceOtaUpdate ${deviceId}, ${otaId}`);
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.mutate(DevicesOtaUpdatesStartDocument, { deviceId, otaId });
            if (response.errors) {
                console.error("Failed to start ota update", response.errors);
            }
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    async triggerGroupOtaUpdate(groupId, otaId) {
        console.log(`triggerGroupOtaUpdate ${groupId}, ${otaId}`);
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.mutate(DeviceGroupsOtaUpdatesStartDocument, { groupId, otaId });
            if (response.errors) {
                console.error("Failed to start group ota update", response.errors);
            }
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    async cancelDeviceOtaUpdate(deviceId) {
        console.log(`cancelDeviceOtaUpdate ${deviceId}`);
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.mutate(DevicesOtaUpdatesCancelDocument, { deviceId });
            if (response.errors) {
                console.error("Failed to cancel ota update", response.errors);
            }
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    async cancelGroupOtaUpdate(groupId) {
        console.log(`cancelGroupOtaUpdate ${groupId}`);
        try {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = await client.mutate(DeviceGroupsOtaUpdatesCancelDocument, { groupId });
            if (response.errors) {
                console.error("Failed to cancel group ota update", response.errors);
            }
        }
        catch (error) {
            console.error("Error", error);
        }
    }
    onReceiversChanged(receivers) {
        this.unsubscribe();
        this.subscriptions.push(...receivers.filter(isDefined).map((receiver) => this.subscribeWithIdentity(receiver)));
        this.subscriptions.push(...receivers.filter(isDefined).map((receiver) => this.subscribeBatchUpdatesWithIdentity(receiver)));
    }
    async fetchOtaUpdates(deviceType) {
        var _a, _b, _c;
        let nextToken;
        let otaUpdates = [];
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        try {
            do {
                const response = await client.query(OtaUpdatesListDocument, { nextToken, deviceType });
                nextToken = (_a = response.data.otaUpdatesList) === null || _a === void 0 ? void 0 : _a.nextToken;
                otaUpdates = otaUpdates.concat((_c = (_b = response.data.otaUpdatesList) === null || _b === void 0 ? void 0 : _b.otaUpdates) !== null && _c !== void 0 ? _c : []);
                if (response.errors) {
                    console.error("Failed to query ota updates list", response.errors);
                }
            } while (nextToken);
            this.otaUpdates = otaUpdates;
            return otaUpdates;
        }
        catch (error) {
            console.error("Error", error);
            return [];
        }
    }
    parseBatchExecutions(item) {
        const otaExecution = {
            organizationId: item.organizationId,
            id: item.id,
            executionStatus: item.executionStatus,
            currentCount: item.currentCount,
            stopDate: item.stopDate,
            startDate: item.startDate,
            maxCount: item.maxCount,
            dailyMaxCount: item.dailyMaxCount,
            searchQuery: item.searchQuery,
            userId: item.userId,
        };
        return otaExecution;
    }
    async fetchOtaUpdateStates() {
        var _a, _b;
        let nextToken;
        let otaUpdateStates = [];
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        try {
            do {
                const response = await client.query(OtaUpdatesStatesListDocument, { nextToken });
                if (response.errors) {
                    console.error("Failed to query ota update states list", response.errors);
                }
                nextToken = (_a = response.data.otaUpdatesStatesList) === null || _a === void 0 ? void 0 : _a.nextToken;
                const convertedStates = (_b = response.data.otaUpdatesStatesList) === null || _b === void 0 ? void 0 : _b.otaUpdateStates.map((fragment) => OtaManager.parseStateFragment(fragment));
                otaUpdateStates = otaUpdateStates.concat(convertedStates !== null && convertedStates !== void 0 ? convertedStates : []);
            } while (nextToken);
            this.otaUpdateStates = otaUpdateStates;
            console.log(`fetchOtaUpdateStates ${JSON.stringify(this.otaUpdateStates)}`);
            return otaUpdateStates;
        }
        catch (error) {
            console.error("Error", error);
            return [];
        }
    }
    async fetchOtaBatchExecutions() {
        var _a, _b, _c;
        let nextToken;
        let otaExecutions = [];
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        try {
            do {
                const response = await client.query(OtaUpdatesBatchListDocument, { nextToken }, {
                    fetchPolicy: "network-only",
                });
                if (response.errors) {
                    console.error("Failed to query batch executions list", response.errors);
                }
                nextToken = (_a = response.data.otaUpdatesBatchList) === null || _a === void 0 ? void 0 : _a.nextToken;
                otaExecutions = otaExecutions.concat((_c = (_b = response.data.otaUpdatesBatchList) === null || _b === void 0 ? void 0 : _b.otaBatchExecutions.map((execution) => this.parseBatchExecutions(execution))) !== null && _c !== void 0 ? _c : []);
            } while (nextToken);
        }
        catch (error) {
            console.error("Error", error);
        }
        this.otaExecutions = otaExecutions;
        return otaExecutions;
    }
    unsubscribe() {
        console.log("OtaManager unsubscribe");
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
        this.subscriptions = [];
    }
    parseBatchUpdateState(item) {
        const otaState = {
            organizationId: item.organizationId,
            id: item.id,
            executionStatus: item.executionStatus,
            currentCount: item.currentCount,
            stopDate: item.stopDate,
        };
        return otaState;
    }
    subscribeWithIdentity(identity) {
        console.log("OtaManager subscribeWithIdentity: ", identity);
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        return appSyncClient.subscribe(OtaUpdatesStatesUpdateFeedDocument, { receiver: identity }).subscribe({
            // TODO: Fix any type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (error) => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscribeWithIdentity(identity);
                }
                console.error(error);
            },
            next: (update) => {
                var _a, _b;
                if ((_b = (_a = update.data) === null || _a === void 0 ? void 0 : _a.otaUpdatesStatesUpdateFeed) === null || _b === void 0 ? void 0 : _b.item) {
                    const state = OtaManager.parseStateFragment(update.data.otaUpdatesStatesUpdateFeed.item);
                    console.log("OTA state: " + JSON.stringify(state));
                    this.notifyAction((observer) => { var _a; return (_a = observer.onOtaUpdateState) === null || _a === void 0 ? void 0 : _a.call(observer, state); });
                }
            },
        });
    }
    subscribeBatchUpdatesWithIdentity(identity) {
        console.log("OtaManager subscribeBatchUpdatesWithIdentity: ", identity);
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        return appSyncClient.subscribe(OtaUpdatesBatchFeedDocument, { receiver: identity }).subscribe({
            // TODO: Fix any type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (error) => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscribeWithIdentity(identity);
                }
                console.error(error);
            },
            next: (update) => {
                var _a, _b;
                if ((_b = (_a = update.data) === null || _a === void 0 ? void 0 : _a.otaUpdatesBatchFeed) === null || _b === void 0 ? void 0 : _b.item) {
                    const state = update.data.otaUpdatesBatchFeed.item;
                    console.log("OTA batch update state: " + JSON.stringify(state));
                    this.notifyAction((observer) => { var _a; return (_a = observer.onOtaBatchUpdateState) === null || _a === void 0 ? void 0 : _a.call(observer, this.parseBatchUpdateState(state)); });
                }
            },
        });
    }
}
